import { ChangeDetectionStrategy, Component, HostBinding, Inject, Injector, isDevMode } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseDialogComponent } from '../base-dialog';

import type { ActionConfirmDialogData } from './action-confirm-dialog-data';
import { ACTION_CONFIRM_DIALOG_DESCRIPTION_COMPONENT_CONTEXT } from './action-confirm-dialog-data';

@Component({
	selector: 'bp-action-confirm-dialog',
	templateUrl: './action-confirm-dialog.component.html',
	styleUrls: [ './action-confirm-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionConfirmDialogComponent<T extends ActionConfirmDialogData> extends BaseDialogComponent<T> {

	typedName = '';

	@HostBinding('class.no-user-select')
	isNotDevMode = !isDevMode();

	descriptionComponentInjector = this._createDescriptionComponentInjectorWithContext();

	constructor(
		public override dialogRef: MatDialogRef<any>,
		@Inject(MAT_DIALOG_DATA) public override data: T,
		private readonly _injector: Injector,
	) {
		super(dialogRef, data);

		this.data = this._mergeDefaultOptions(this.data);
	}

	canCloseWithTrueOnEnter(): boolean {
		return this.data.requireTypedConfirm
			? this.typedName === this.data.name
			: true;
	}

	protected _mergeDefaultOptions(data: T): T {
		return data;
	}

	private _createDescriptionComponentInjectorWithContext(): Injector {
		return Injector.create({
			providers: [{
				provide: ACTION_CONFIRM_DIALOG_DESCRIPTION_COMPONENT_CONTEXT,
				useValue: this.data.descriptionContext,
			}],
			parent: this._injector,
		});
	}
}
