<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<button type="submit" class="hidden"></button>

	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<fieldset *ngFor="let section of sections; let i = index" class="mb-0">
		<hr *ngIf="i !== 0" class="mt-2 mb-4" />

		<h3>{{ section.title }}</h3>

		<div *ngIf="section.id === 'service'; else defaultSectionTpl" class="row">
			<bp-autocomplete
				class="w-100"
				name="psp"
				label="Payment Provider"
				formControlName="paymentProvider"
				[items]="bridgerPspsSharedFacade.allInternalNames$ | async"
			>
			</bp-autocomplete>

			<div></div>
		</div>

		<ng-template #defaultSectionTpl>
			<bp-property-metadata-controls-section [form]="form" [metadata]="metadata" [sectionScheme]="section.scheme">
			</bp-property-metadata-controls-section>
		</ng-template>
	</fieldset>
</form>
