import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';

@Component({
	selector: 'bp-nav-group',
	templateUrl: './nav-group.component.html',
	styleUrls: [ './nav-group.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class NavGroupComponent {

	@Input() label!: string;

}
