import type { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { createSelector } from '@ngrx/store';

import type { RecordsPage } from '@bp/shared/models/common';
import type { Entity, FirebaseEntity } from '@bp/shared/models/metadata';

import type { EntitiesInMemoryPagedListState, EntitiesListState } from './compose-entities-list-reducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function composeEntitiesListSelectors<
	TEntity extends Entity | FirebaseEntity,
	TState extends EntitiesListState<TEntity>
>(
	featureStateSelector: MemoizedSelector<Object, TState, DefaultProjectorFn<TState>>,
) {
	return {
		recordsPage: createSelector(
			featureStateSelector,
			state => state.recordsPage,
		),

		page: createSelector(
			featureStateSelector,
			state => state.page,
		),

		pending: createSelector(
			featureStateSelector,
			state => state.pending,
		),

		error: createSelector(
			featureStateSelector,
			state => state.error,
		),
	};
}

export type EntitiesListSelectors<TEntity extends Entity | FirebaseEntity> =
	Omit<ReturnType<typeof composeEntitiesListSelectors>, 'recordsPage'>
	& {
		recordsPage: MemoizedSelector<Object, RecordsPage<TEntity> | null, DefaultProjectorFn<RecordsPage<TEntity> | null>>;
	};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function composeEntitiesInMemoryPagedListSelectors<
	TEntity extends Entity | FirebaseEntity,
	TState extends EntitiesInMemoryPagedListState<TEntity>
>(
	featureStateSelector: MemoizedSelector<Object, TState, DefaultProjectorFn<TState>>,
) {
	return {
		...composeEntitiesListSelectors<TEntity, TState>(featureStateSelector),

		all: createSelector(
			featureStateSelector,
			state => state.all,
		),

		filteredInMemory: createSelector(
			featureStateSelector,
			state => state.filteredInMemory,
		),
	};
}

export type EntitiesInMemoryPagedListSelectors<TEntity extends Entity | FirebaseEntity> =
	EntitiesListSelectors<TEntity>
	& Omit<ReturnType<typeof composeEntitiesInMemoryPagedListSelectors>, 'all' | 'filteredInMemory' | 'recordsPage'>
	& {
		all: MemoizedSelector<Object, TEntity[] | null, DefaultProjectorFn<TEntity[] | null>>;
		filteredInMemory: MemoizedSelector<Object, TEntity[] | null, DefaultProjectorFn<TEntity[] | null>>;
	};
