/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEmpty } from 'lodash-es';
import * as Sentry from '@sentry/angular';

import type { ActionReducer, MetaReducer } from '@ngrx/store';

import type { Dictionary, IEnvironment } from '@bp/shared/typings';
import { OptionalBehaviorSubject, ZoneService } from '@bp/shared/rxjs';
import { isPresent } from '@bp/shared/utilities';

import { SentryReporter } from './sentry.reporter';
import type { IReporter } from './reporter.interface';
import { LogRocketReporter } from './logrocket.reporter';

export class TelemetryReporter implements IReporter {

	private readonly _reporters: IReporter[] = [];

	readonly logMetaReducer: MetaReducer | null;

	private readonly _userSessionRecordingURL$ = new OptionalBehaviorSubject<string>();

	userSessionRecordingUrl$ = this._userSessionRecordingURL$.asObservable();

	constructor(
		private readonly _environment: IEnvironment,
	) {
		this._initReporters();

		this.logMetaReducer = ZoneService.runOutsideAngular(
			() => this._tryCombineReportersMetadataReducers(),
		);
	}

	identifyUser(
		userId: string,
		userTraits?: Dictionary<boolean | number | string | null | undefined> | { email?: string } | undefined,
	): void {
		ZoneService.runOutsideAngular(
			() => void this._reporters.forEach(
				reporter => void reporter.identifyUser(userId, userTraits),
			),
		);
	}

	captureError(error: Error | ErrorEvent | PromiseRejectionEvent, source: string): void {
		ZoneService.runOutsideAngular(
			() => void this._reporters.forEach(
				reporter => void reporter.captureError(error, source),
			),
		);
	}

	captureMessage(message: string): void {
		ZoneService.runOutsideAngular(
			() => void this._reporters.forEach(
				reporter => void reporter.captureMessage(message),
			),
		);
	}

	warn(message: string, ...payload: any[]): void {
		ZoneService.runOutsideAngular(
			() => void this._reporters.forEach(
				reporter => void reporter.warn(message, ...payload),
			),
		);
	}

	log(message: string, ...payload: any[]): void {
		ZoneService.runOutsideAngular(
			() => void this._reporters.forEach(
				reporter => void reporter.log(message, ...payload),
			),
		);
	}

	track(key: string, payload: any): void {
		ZoneService.runOutsideAngular(
			() => void this._reporters.forEach(
				reporter => void reporter.track(key, payload),
			),
		);
	}

	private _initReporters(): void {
		ZoneService.runOutsideAngular(() => void [
			this._tryInitSentry(),

			this._tryInitLogrocket(),
		]
			.filter(isPresent)
			.forEach(reporter => void this._reporters.push(reporter)));
	}

	private _tryInitSentry(): IReporter | null {
		if (!this._environment.sentry)
			return null;

		return new SentryReporter({
			appId: this._environment.sentry,
			environment: this._environment.name,
			release: this._environment.appVersion.releaseAndShortSHA,
		});
	}

	private _tryInitLogrocket(): IReporter | null {
		if (!this._environment.logrocket)
			return null;

		return new LogRocketReporter({
			appId: this._environment.logrocket,
			release: this._environment.appVersion.releaseAndShortSHA,
			onSessionURLChange: (url: string) => {
				this._userSessionRecordingURL$.next(url);

				void Sentry.configureScope(
					scope => scope.setExtra('userSessionRecording', url),
				);
			},
		});
	}

	private _tryCombineReportersMetadataReducers(): MetaReducer | null {
		const reportsReducers = this._reporters.map(reporter => reporter.logMetaReducer!);

		if (isEmpty(reportsReducers))
			return null;

		return (reducer: ActionReducer<any>): ActionReducer<any> => reportsReducers
			.reduce((accumulator, logMetaReducer) => logMetaReducer(accumulator), reducer);
	}

}
