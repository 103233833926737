<span
	*ngIf="isEditLike || (drawer.formDirty$ | async)"
	@fade
	[bpTooltip]="
		(drawer.formInvalid$ | async)
			? 'Some inputs are invalid'
			: isEdit && (drawer.formPristine$ | async)
			? 'Nothing to save'
			: '(Control + Enter)'
	"
>
	<button
		type="submit"
		mat-flat-button
		color="primary"
		[disabled]="(isAdd ? (drawer.canCreate$ | async) : (drawer.canSave$ | async)) === false"
	>
		{{ isAdd ? (useAddLabelInsteadCreate ? 'Add' : 'Create') : 'Save' }}
	</button>
</span>

<ng-content></ng-content>

<button
	*ngIf="hasMeatballMenu && !isAdd"
	@fadeIn
	class="ml-2"
	type="button"
	mat-icon-button
	[matMenuTriggerFor]="sideMenu"
>
	<mat-icon>more_vert</mat-icon>

	<mat-menu #sideMenu="matMenu">
		<ng-content select=".ng-content-menu-items"></ng-content>

		<span
			*ngIf="hasDisableBtn"
			[bpTooltip]="enabled ? (actionIsNotAllowedTooltip$ | async) : (enableActionIsNotAllowedTooltip$ | async)"
		>
			<button
				type="button"
				mat-menu-item
				color="primary"
				[disabled]="enabled ? (actionIsNotAllowed$ | async) : (enableActionIsNotAllowed$ | async)"
				(click)="disable.emit(!enabled)"
			>
				{{ enabled ? 'Disable' : 'Enable' }}
			</button>
		</span>

		<span *ngIf="hasDeleteBtn" [bpTooltip]="actionIsNotAllowedTooltip$ | async">
			<button
				@fadeIn
				type="button"
				color="primary"
				mat-menu-item
				[disabled]="actionIsNotAllowed$ | async"
				(click)="delete.emit()"
			>
				{{ deleteLabel }}
			</button>
		</span>
	</mat-menu>
</button>
