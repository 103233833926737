<h2 mat-dialog-title>Are you absolutely sure?</h2>

<mat-dialog-content>
	<p *ngIf="data.descriptionComponent">
		<ng-container
			*ngComponentOutlet="data.descriptionComponent; injector: descriptionComponentInjector"
		></ng-container>
	</p>

	<p *ngIf="data.descriptionText" [innerHtml]="data.descriptionText"></p>

	<ng-container *ngIf="data.requireTypedConfirm">
		<p class="mb-0">
			Please type <b>{{ data.name }}</b> to confirm.
		</p>

		<bp-input
			class="w-100 mt-3"
			appearance="outline"
			label="{{ data.type | titleCase }} Name"
			[color]="data.color"
			[required]="true"
			[nativeAutocomplete]="false"
			[(ngModel)]="typedName"
		>
		</bp-input>
	</ng-container>
</mat-dialog-content>

<mat-dialog-actions>
	<button class="cancel mr-auto bp-round-button" mat-stroked-button [mat-dialog-close]="false">Cancel</button>

	<button
		class="bp-round-button"
		mat-stroked-button
		[color]="data.color"
		[disabled]="!canCloseWithTrueOnEnter()"
		[mat-dialog-close]="true"
	>
		{{ data.confirmButtonText }}
	</button>
</mat-dialog-actions>
