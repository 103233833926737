import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload } from '@bp/shared/models/common';
import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';

import {
	DrawerEntityActionPayload, EntityAsyncActionFailurePayload, EntityAsyncActionSuccessPayload, SaveEntityAsyncActionSuccessPayload
} from '../../models';

import { EntityDrawerApiActions } from './entity-api.actions';

export function composeEntityDrawerApiActions<TEntity extends Entity | FirebaseEntity>(scope: string): EntityDrawerApiActions<TEntity> {
	return {
		loadSuccess: createAction(
			`${ scope } Load Success`,
			props<DrawerEntityActionPayload<TEntity>>(),
		),

		loadFailure: createAction(
			`${ scope } Load Failure`,
			props<ErrorActionPayload>(),
		),

		saveSuccess: createAction(
			`${ scope } Save Success`,
			props<SaveEntityAsyncActionSuccessPayload<TEntity, TEntity>>(),
		),

		saveFailure: createAction(
			`${ scope } Save Failure`,
			props<EntityAsyncActionFailurePayload<TEntity>>(),
		),

		deleteSuccess: createAction(
			`${ scope } Delete Success`,
			props<EntityAsyncActionSuccessPayload<TEntity>>(),
		),

		deleteFailure: createAction(
			`${ scope } Delete Failure`,
			props<EntityAsyncActionFailurePayload<TEntity>>(),
		),
	};
}
