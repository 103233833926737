import { combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import type { OnChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OptionalBehaviorSubject } from '@bp/shared/rxjs';

import { CreditCardsFacade } from '@bp/backoffice/cashier-demostand/credit-cards';

@Component({
	selector: 'bp-credit-card-name',
	templateUrl: './credit-card-name.component.html',
	styleUrls: [ './credit-card-name.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardNameComponent implements OnChanges {

	@Input() id!: string;

	@Input() compact!: boolean;

	id$ = new OptionalBehaviorSubject<string>();

	creditCard$ = combineLatest([
		this._creditCards.allRecords$,
		this.id$,
	])
		.pipe(
			map(([ all, id ]) => all.find(cc => cc.id === id)),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);

	constructor(private readonly _creditCards: CreditCardsFacade) { }

	ngOnChanges(): void {
		this.id$.next(this.id);
	}
}
