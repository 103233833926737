import { combineReducers } from '@ngrx/store';

import * as fromEntity from './entity/preset.reducer';
import * as fromList from './list/presets.reducer';

const reducers = {
	entity: fromEntity.reducer,
	list: fromList.reducer,
};

export const FEATURE_KEY = '[section]:presets';

export interface IState {
	entity: fromEntity.IState;
	list: fromList.IState;
}

const presetFeatureReducer = combineReducers(reducers);

export function reducer(state: any, action: any): IState {
	return presetFeatureReducer(state, action);
}
