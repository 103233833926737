import { startWith } from 'rxjs';

import { Directive, Self, inject, InjectFlags } from '@angular/core';

import { ViewportOverflownObserverDirective } from '@bp/shared/directives';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import { TooltipShownOnViewportOverflowDirective } from './tooltip-shown-on-viewport-overflow.directive';

@Directive({
	selector: '[bpShowTooltipOnViewportOverflow]',
})
export class ShowTooltipOnViewportOverflowDirective extends Destroyable {

	tooltip = inject(TooltipShownOnViewportOverflowDirective, InjectFlags.Host)!.tooltip;

	constructor(@Self() private readonly _viewportOverflownObserver: ViewportOverflownObserverDirective) {
		super();

		this._viewportOverflownObserver
			.hasHostElementOverflownViewport
			.pipe(
				startWith(false),
				takeUntilDestroyed(this),
			)
			.subscribe(
				hasOverflown => hasOverflown ? void this.tooltip.enable() : void this.tooltip.disable(),
			);
	}

}
