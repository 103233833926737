import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import type { ViewsSectionScheme } from '@bp/shared/components/metadata';
import { ViewEntityBaseComponent } from '@bp/shared/components/metadata';

import { Preset } from '../../models';

@Component({
	selector: 'bp-preset-testing-details-view',
	templateUrl: './preset-testing-details-view.component.html',
	styleUrls: [ './preset-testing-details-view.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_LIST_STAGGERED ],
})
export class PresetTestingDetailsViewComponent extends ViewEntityBaseComponent<Preset> {

	override metadata = Preset.getClassMetadata();

	sectionScheme: ViewsSectionScheme<Preset> = [
		[ 'creditCardId', 'paymentProvider' ],
		[ 'environment' ],
		[ 'isShared' ],
		[ '$$useLocalBackend' ],
	];

}
