import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { PickBy } from '@bp/shared/typings';

import { IdentityFacade } from '../state';
import { IIdentity, Permission } from '../models';

@Injectable({
	providedIn: 'root',
})
export class FeaturePermissionsService {

	private get _userPermissions(): PickBy<IIdentity, 'featurePermissions'> | undefined {
		return this._identityFacade.user?.featurePermissions;
	}

	private get _user$(): Observable<IIdentity | null> {
		return this._identityFacade.user$;
	}

	constructor(private readonly _identityFacade: IdentityFacade<IIdentity>) {}

	hasAccess(permission: Permission): boolean {
		return !!this._userPermissions?.has(permission) || this._identityFacade.organizationPermissions.has(permission);
	}

	hasAccess$(permission: Permission): Observable<boolean> {
		return combineLatest([
			this._user$,
			this._identityFacade.organizationPermissions$,
		]).pipe(map(([ user ]) => (user ? this.hasAccess(permission) : false)));
	}
}
