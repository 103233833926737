import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import type { ViewsSectionScheme } from '@bp/shared/components/metadata';
import { ViewEntityBaseComponent } from '@bp/shared/components/metadata';
import { EnvironmentService } from '@bp/shared/services';

import { Preset, TITLES } from '../../models';

@Component({
	selector: 'bp-preset-view',
	templateUrl: './preset-view.component.html',
	styleUrls: [ './preset-view.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_LIST_STAGGERED ],
	host: {
		'[class.cashier-s2s-session]': '!!entity?.cashierSessionId',
	},
})
export class PresetViewComponent extends ViewEntityBaseComponent<Preset> {

	override metadata = Preset.getClassMetadata();

	cashierS2SSessionScheme: ViewsSectionScheme<Preset> = [
		[ 'cashierKey' ],
		[ 'cashierSessionId' ],
	];

	sections: {
		title: string;
		scheme: ViewsSectionScheme<Preset>;
	}[] = [
			{
				title: TITLES.cashierSetupDetails,
				scheme: [
					[ 'cashierKey' ],
					[ 'country', 'state' ],
					[ 'amount', 'amountLock' ],
					[ 'currency', 'currencyLock' ],
					[ 'buttonMode', 'buttonText' ],
					[ 'language', 'hideLanguagesDropdown' ],
					[ 'directPaymentMethod', 'depositButtonText' ],
					[ 'singlePaymentMethod', 'singlePaymentProvider' ],
					[ 'hideHeader', 'showFooter' ],
					[ 'disableProcessingMessages', 'enableFilterByCardBrand' ],
					[ 'validateInputsOnFocusOut', 'dontSkipSinglePaymentBox' ],
					[ 'showRedirectMessage' ], // long label
					[ 'saveCreditCardFlagCheckedByDefault' ], // long label
					[ 'payMode' ],
				],
			},
			{
				title: TITLES.userDetails,
				scheme: [
					[ 'email', 'phone' ],
					[ 'firstName', 'lastName' ],
					[ 'city', 'address' ],
					[ 'zipCode', 'cardHolderName' ],
				],
			},
			{
				title: TITLES.userDetailsShownWhenTheBackendAsks,
				scheme: [
					[ 'birthDate', 'gender' ],
					[ 'personalId', 'bankAccountName' ],
				],
			},
			{
				title: TITLES.serviceDetailsNotShowOnTheScreens,
				scheme: [
					[ 'affiliateId', 'trackingId' ],
					[ 'ip', 'platformId' ],
				],
			},
			{
				title: TITLES.theming,
				scheme: [
					[ 'theme' ],
					[ 'splashScreenImageUrl', 'splashScreenBackgroundColor' ],
					[ 'languagesDropdownOnLeftSide' ],
					...(<ViewsSectionScheme<Preset>> [
						this._environment.isLocalServer
							? [ 'darkThemeBackgroundColor' ]
							: [],
					]),
				],
			},
		];

	constructor(private readonly _environment: EnvironmentService) {
		super();
	}

}
