<mat-progress-bar
	*ngIf="uploadProgress$ | async as progress"
	@slide
	color="primary"
	mode="determinate"
	[value]="progress"
>
</mat-progress-bar>

<img *ngIf="value" [src]="value" />

<input #filePicker type="file" [accept]="accept" [disabled]="!canEdit || (canUpload$ | async) === false" />
