import { Params } from '@angular/router';

import { createAction, props } from '@ngrx/store';

import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';

import {
	DrawerEntityActionPayload, EntityActionPayload, EntityAsyncActionPayload, LoadEntityActionPayload, SaveEntityAsyncActionPayload
} from '../../models';

import { EntityDrawerActions } from './entity.actions';

export function composeEntityDrawerActions<TEntity extends Entity | FirebaseEntity>(
	scope: string,
): Omit<EntityDrawerActions<TEntity>, 'api'> {
	return {
		changeDrawerRouteParams: createAction(
			`${ scope } Change Drawer Type`,
			props<DrawerEntityActionPayload<TEntity>>(),
		),

		drawerRouteOutletChanged: createAction(
			`${ scope } Drawer Route Outlet Changed`,
			props<{ outlet: string | null }>(),
		),

		activeRouteParamsChanged: createAction(
			`${ scope } Active Route Params Changed`,
			props<{ params: Params }>(),
		),

		create: createAction(
			`${ scope } Create`,
			props<EntityActionPayload<TEntity>>(),
		),

		clone: createAction(
			`${ scope } Clone`,
			props<EntityActionPayload<TEntity>>(),
		),

		load: createAction(
			`${ scope } Load`,
			props<LoadEntityActionPayload>(),
		),

		save: createAction(
			`${ scope } Save`,
			props<SaveEntityAsyncActionPayload<TEntity>>(),
		),

		refresh: createAction(
			`${ scope } Refresh`,
		),

		resetState: createAction(
			`${ scope } Reset State`,
		),

		markAsPending: createAction(
			`${ scope } Pending`,
		),

		updateLocalEntity: createAction(
			`${ scope } Update Local Entity`,
			props<EntityActionPayload<TEntity>>(),
		),

		toggleActivityStatus: createAction(
			`${ scope } Toggle Activity Status`,
			props<EntityAsyncActionPayload<TEntity>>(),
		),

		disableConfirm: createAction(
			`${ scope } Confirm Disabling`,
			props<EntityAsyncActionPayload<TEntity>>(),
		),

		disableConfirmDismiss: createAction(
			`${ scope } Disabling Dismissed`,
		),

		disable: createAction(
			`${ scope } Disable`,
			props<EntityAsyncActionPayload<TEntity>>(),
		),

		deleteConfirm: createAction(
			`${ scope } Confirm Deletion`,
			props<EntityActionPayload<TEntity>>(),
		),

		deleteConfirmDismiss: createAction(
			`${ scope } Deletion Dismissed`,
		),

		delete: createAction(
			`${ scope } Delete`,
			props<EntityActionPayload<TEntity>>(),
		),
	};
}
