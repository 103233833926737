import { ToastrService } from 'ngx-toastr';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import type { ControlsSectionScheme } from '@bp/shared/components/metadata';
import { FormMetadataEntityBaseComponent, ROW_EMPTY_CELL } from '@bp/shared/components/metadata';

import { FirebasePspsSharedFacade } from '@bp/admins-shared/domains/firebase-psps';
import { RightDrawerEntityChildFormBaseComponent } from '@bp/admins-shared/features/entity';

import { Preset } from '../../models';

@Component({
	selector: 'bp-preset-testing-details-edit-form',
	templateUrl: './preset-testing-details-edit-form.component.html',
	styleUrls: [ './preset-testing-details-edit-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetTestingDetailsEditFormComponent extends RightDrawerEntityChildFormBaseComponent<Preset> {

	override metadata = Preset.getClassMetadata();

	uiScheme: ControlsSectionScheme<Preset> = [
		[ 'creditCardId', 'paymentProvider' ],
		[ 'environment', ROW_EMPTY_CELL ],
		[ 'isShared' ],
		[ '$$useLocalBackend' ],
	];

	constructor(
		public bridgerPspsSharedFacade: FirebasePspsSharedFacade,
		parentFormEntity: FormMetadataEntityBaseComponent<Preset>,
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(parentFormEntity, fb, cdr, toaster);

		this.setFormScheme({
			creditCardId: null,
			environment: null,
			paymentProvider: null,
			$$useLocalBackend: null,
			isShared: null,
		});
	}

}
