import { ToastrService } from 'ngx-toastr';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions } from '@ngrx/effects';

import { RouterService, StorageService } from '@bp/shared/services';
import type { ActionConfirmDialogData } from '@bp/shared/components/dialogs';

import { EntityEffects } from '@bp/admins-shared/features/entity';

import type { Preset } from '../../models';
import { PresetPageComponent } from '../../pages/preset-page';
import { PresetsApiService } from '../../services';

import { PresetFacade } from './preset.facade';

@Injectable()
export class PresetEffects extends EntityEffects<Preset> {

	readonly routeComponentType = PresetPageComponent;

	constructor(
		presetFacade: PresetFacade,
		actions$: Actions,
		routerService: RouterService,
		presetsApiService: PresetsApiService,
		storageService: StorageService,
		dialog: MatDialog,
		toaster: ToastrService,
	) {
		super(actions$, routerService, presetFacade, storageService, dialog, toaster, presetsApiService);
	}

	protected override _buildActionConfirmDialogData = (entity: Preset): ActionConfirmDialogData => ({
		name: entity.name,
		type: 'preset',
	});
}
