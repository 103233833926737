import type { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { createSelector } from '@ngrx/store';

import type { Entity, FirebaseEntity } from '@bp/shared/models/metadata';

import type { EntityDrawerState } from './compose-entity-drawer-reducer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function composeEntityDrawerSelectors<
	TEntity extends Entity | FirebaseEntity,
	TState extends EntityDrawerState<TEntity>>(
	featureStateSelector: MemoizedSelector<Object, TState, DefaultProjectorFn<TState>>,
) {
	return {
		entity: createSelector(
			featureStateSelector,
			state => state.entity,
		),

		entityId: createSelector(
			featureStateSelector,
			state => state.entityId,
		),

		drawerType: createSelector(
			featureStateSelector,
			state => state.drawerType,
		),

		pending: createSelector(
			featureStateSelector,
			state => state.pending,
		),

		loading: createSelector(
			featureStateSelector,
			state => state.loading,
		),

		error: createSelector(
			featureStateSelector,
			state => state.error,
		),

		formId: createSelector(
			featureStateSelector,
			state => state.formId,
		),

		activatedRouteOutlet: createSelector(
			featureStateSelector,
			state => state.activatedRouteOutlet,
		),

		asyncActionsOverEntity: createSelector(
			featureStateSelector,
			state => state.asyncActionsOverEntity,
		),
	};
}

export type EntityDrawerSelectors<TEntity extends Entity | FirebaseEntity> =
	Omit<ReturnType<typeof composeEntityDrawerSelectors>, 'entity'>
	& {
		entity: MemoizedSelector<Object, TEntity | null, DefaultProjectorFn<TEntity | null>>;
	};
