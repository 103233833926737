import * as firestore from 'firebase/firestore';
import type { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';

import { Injectable } from '@angular/core';

import type { DTO, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { FirebaseService } from '@bp/shared/services';
import type { RecordsPage } from '@bp/shared/models/common';
import { assertIsQueryParamsBased } from '@bp/shared/models/common';

import type { UsersQueryParams } from '../models';
import { User } from '../models';

@Injectable({
	providedIn: 'root',
})
export class UsersApiService implements IEntitiesFirebaseApiService<User, UsersQueryParams> {

	readonly collectionPath = 'users';

	constructor(private readonly _firebaseService: FirebaseService) {
	}

	readonly factory = (dto: DTO<User>): User => new User(dto);

	listenToQueriedRecordsPageChanges(query?: UsersQueryParams): Observable<RecordsPage<User>> {
		assertIsQueryParamsBased(query);

		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
			firestoreQuery => {
				if (query.fired !== undefined) {
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where('isFired', '==', query.fired),
					);
				}

				return firestoreQuery;
			},
		);
	}

	listenToUserChanges(id: string): Observable<User | null> {
		return this._firebaseService.listenToDocumentChanges(`${ this.collectionPath }/${ id }`, this.factory);
	}

	get(id: string): Observable<User | null> {
		return this._firebaseService.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	listenToUserChangesByEmail(email: string): Observable<User | null> {
		return this._firebaseService.listenToQueriedDocumentChanges(
			this.collectionPath,
			this.factory,
			firestoreQuery => firestore.query(firestoreQuery, firestore.where('email', '==', email)),
		);
	}

	save(user: User): Observable<User> {
		return this._firebaseService.save(
			this.collectionPath,
			user,
			this.factory,
		);
	}

	async markVisit(user: User): Promise<void> {
		if (!user.id)
			return;

		await firstValueFrom(this._firebaseService.set(
			`${ this.collectionPath }/${ user.id }`,
			this.factory({
				...user,
				visitedAt: moment(),
			}),
		));
	}

	delete({ id }: User): Observable<void> {
		return this._firebaseService.delete(`${ this.collectionPath }/${ id }`);
	}

}
