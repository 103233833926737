<a
	class="link"
	[class.pulsing-blur]="!(creditCard$ | async)?.name"
	[outletLinkRelativeToRoot]="{
		'[right-drawer][2]': ['credit-card', { id: id, drawerType: 'view' }]
	}"
	(click)="$event.stopPropagation()"
>
	<span *ngIf="creditCard$ | async as cc; else pendingTpl" [class.flex-justify-content-between]="compact">
		<div class="text-ellipsis" [style.max-width.px]="150">{{ cc!.name }}</div>

		<div class="flex-justify-content-between" [class.mat-caption]="!compact">
			<div class="text-ellipsis mr-1">{{ cc!.paymentProvider }}</div>
			<div>{{ cc!.number.toString() | slice: 0:4 }} {{ cc!.number.toString() | slice: 4:6 }}</div>
		</div>
	</span>

	<ng-template #pendingTpl> pending credit name </ng-template>
</a>
