import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE, FADE_IN } from '@bp/shared/animations';

import { RightDrawerEntityHeaderButtonsBaseComponent } from '../right-drawer-entity-header-buttons-base.component';

@Component({
	selector: 'bp-right-drawer-entity-header-buttons',
	templateUrl: './right-drawer-entity-header-buttons.component.html',
	styleUrls: [ './right-drawer-entity-header-buttons.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, FADE_IN ],
})
export class RightDrawerEntityHeaderButtonsComponent
	extends RightDrawerEntityHeaderButtonsBaseComponent<any> {

}
