import { createReducer } from '@ngrx/store';

import type { EntitiesListState } from '@bp/admins-shared/features/entity';
import { composeEntitiesListReducer, ENTITIES_LIST_INITIAL_STATE } from '@bp/admins-shared/features/entity';

import type { Preset } from '../../models';

import { PRESETS_LIST_ACTIONS } from './presets.actions';

export interface IState extends EntitiesListState<Preset> {

}

export const initialState: IState = {

	...ENTITIES_LIST_INITIAL_STATE,

};

export const reducer = createReducer(

	initialState,

	...composeEntitiesListReducer(initialState, PRESETS_LIST_ACTIONS),

);
