import { get, isNil } from 'lodash-es';

import type { AfterContentInit, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';

import { FADE_IN_SEMI_SLOW } from '@bp/shared/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import type { PropertyMetadata } from '@bp/shared/models/metadata';
import { ClassMetadata } from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import { attrBoolValue, observeQueryListChanges } from '@bp/shared/utilities';

import { ROW_RENDER_STAGGER } from '../constants';
import { PropertyMetadataCustomValueViewDirective } from '../property-metadata-view';

export type ViewsSectionScheme<T> = ([
	firstRowCellDef: NonFunctionPropertyNames<T>,
	secondRowCellDef?: NonFunctionPropertyNames<T>,
] | undefined)[];

export function ensureViewsSectionScheme<T>(scheme: ViewsSectionScheme<T>): ViewsSectionScheme<T> {
	return scheme;
}

@Component({
	selector: 'bp-property-metadata-views-section',
	templateUrl: './property-metadata-views-section.component.html',
	styleUrls: [ './property-metadata-views-section.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_SEMI_SLOW ],
})
export class PropertyMetadataViewsSectionComponent<TEntity> extends Destroyable implements OnInit, AfterContentInit {

	get = get;

	@Input() entity!: TEntity | null;

	@Input() metadata?: ClassMetadata<TEntity>;

	@Input() propertiesMetadata?: PropertyMetadata[];

	@Input() sectionScheme?: ViewsSectionScheme<TEntity>;

	@Input() heading?: string | null;

	@Input() hasSeparator: boolean | '' = false;

	@Input() staggerRendering = true;

	@ContentChildren(PropertyMetadataCustomValueViewDirective)
	private readonly _customValueViewsQueryList!: QueryList<PropertyMetadataCustomValueViewDirective<TEntity>>;

	customValueViewPerProperty = new Map<NonFunctionPropertyNames<TEntity>, PropertyMetadataCustomValueViewDirective<TEntity>>();

	get renderStagger(): number {
		return this.staggerRendering ? ROW_RENDER_STAGGER : 0;
	}

	ngOnInit(): void {
		this.hasSeparator = attrBoolValue(this.hasSeparator);
	}

	ngAfterContentInit(): void {
		this._listenToAndSetCustomValueViewPerProperty();
	}

	getPropertyMetadata(property: NonFunctionPropertyNames<TEntity>): PropertyMetadata {
		if (isNil(property))
			throw new Error('The property name must be provided');

		const md = this.metadata!.get(property);

		this._assertPropertyMetadata(md, property);

		return md!;
	}

	private _listenToAndSetCustomValueViewPerProperty(): void {
		observeQueryListChanges(this._customValueViewsQueryList)
			.pipe(takeUntilDestroyed(this))
			.subscribe(query => void query.forEach(
				customValueViewDirective => this.customValueViewPerProperty.set(
					this._assertCustomValueViewPropertyName(customValueViewDirective.propertyName),
					customValueViewDirective,
				),
			));
	}

	private _assertCustomValueViewPropertyName(name: NonFunctionPropertyNames<TEntity>): NonFunctionPropertyNames<TEntity> {
		if (this.sectionScheme?.some(properties => properties?.some(property => property === name)))
			this._assertPropertyMetadata(this.metadata!.get(name), name);

		return name;
	}

	private _assertPropertyMetadata(
		md: PropertyMetadata | null | undefined,
		name: NonFunctionPropertyNames<TEntity>,
	): void {
		if (!md)
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			throw new Error(`${ String(name) } doesn't have metadata on ${ (<any> this.entity)?.constructor.name ?? '' }`);
	}
}
