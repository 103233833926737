import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { LayoutFacade } from '@bp/admins-shared/features/layout';

import { IdentityFacade } from '@bp/backoffice/domains/identity';

@Component({
	selector: 'bp-backoffice-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: [ './toolbar.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackofficeToolbarComponent {
	@HostBinding('class.bp-toolbar') toolbar = true;

	constructor(
		public layoutFacade: LayoutFacade,
		public identityFacade: IdentityFacade,
	) { }
}
