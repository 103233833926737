import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE_IN, SLIDE_UP } from '@bp/shared/animations';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { TitleService } from '@bp/shared/services';

import { RightDrawerEntityHostPageComponent } from '@bp/admins-shared/features/entity';

import { IdentityFacade } from '@bp/backoffice/domains/identity';

import { CreditCardFacade } from '../../state';
import type { CreditCard } from '../../models';

@Component({
	selector: 'bp-credit-card-page',
	templateUrl: './credit-card-page.component.html',
	styleUrls: [ './credit-card-page.component.scss' ],
	host: {
		class: 'right-drawer-content',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_UP, FADE_IN ],
})
export class CreditCardPageComponent extends RightDrawerEntityHostPageComponent<CreditCard> {

	constructor(
		public creditCardFacade: CreditCardFacade,
		public identityFacade: IdentityFacade,
		title: TitleService,
	) {
		super();

		this.creditCardFacade.entity$
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => void title.setMaskValue({ creditCard: v?.name ?? '' }));
	}
}
