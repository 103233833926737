<bp-autocomplete
	name="cc"
	panelClass="credit-cards-panel"
	[debounce]="debounce"
	[appearance]="appearance"
	[placeholder]="placeholder"
	[floatLabel]="floatLabel"
	[label]="label"
	[items]="creditCardsFacade.allRecords$ | async"
	[value]="creditCard$ | async"
	[filterListFn]="creditCardsFacade.autocompleteFilterFn"
	(valueChange)="setValue($event?.id)"
>
	<ng-template let-cc>
		<div class="text-ellipsis">
			{{ cc.name }}
		</div>
		<div class="mat-caption flex-justify-content-between">
			<div class="text-ellipsis mr-1">{{ cc.paymentProvider }}</div>
			<div>{{ cc.number.toString() | slice: 0:4 }} {{ cc.number.toString() | slice: 4:6 }}</div>
		</div>
	</ng-template>
</bp-autocomplete>
