import { combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, first, map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import { AutocompleteComponent } from '@bp/shared/components/controls';

import { CreditCardsFacade } from '@bp/backoffice/cashier-demostand/credit-cards';

@Component({
	selector: 'bp-credit-cards-autocomplete',
	templateUrl: './credit-cards-autocomplete.component.html',
	styleUrls: [ './credit-cards-autocomplete.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusin)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CreditCardsAutocompleteComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: CreditCardsAutocompleteComponent,
			multi: true,
		},
	],
})
export class CreditCardsAutocompleteComponent extends FormFieldControlComponent<string | null> {

	@ViewChild(AutocompleteComponent, { static: true })
	private readonly _autocompleteComponent!: AutocompleteComponent;

	creditCard$ = combineLatest([
		merge(
			this._onWriteValue$.pipe(map(() => this.value), first()),
			this._internalControl.valueChanges,
		)
			.pipe(
				filter((v: string | null) => !!v),
				distinctUntilChanged(),
			),
		this.creditCardsFacade.allRecords$,
	])
		.pipe(
			map(([ creditCardId, cards ]) => cards.find(v => v.id === creditCardId)),
		);

	constructor(public creditCardsFacade: CreditCardsFacade) {
		super();
	}

	focus(): void {
		this._autocompleteComponent.focus();
	}

}
