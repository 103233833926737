<bp-input
	appearance="standard"
	[formControl]="nameControl"
	[placeholder]="namePlaceholder"
	[required]="true"
	[hideErrorText]="true"
>
</bp-input>

<ng-content></ng-content>
