import type { Action } from '@ngrx/store';
import { createReducer } from '@ngrx/store';

import type { IdentityState } from '@bp/shared-domains-identity';
import { composeIdentityReducer, IDENTITY_INITIAL_STATE } from '@bp/shared-domains-identity';

import type { Identity } from '../models';

import { IDENTITY_ACTIONS } from './identity.actions';

export const FEATURE_STATE_KEY = '[feature]:identity';

export interface IState extends IdentityState<Identity> {}

export const initialState: IState = {
	...IDENTITY_INITIAL_STATE,
};

const authReducer = createReducer(
	initialState,

	...composeIdentityReducer(initialState, IDENTITY_ACTIONS),
);

export function reducer(state: IState | undefined, action: Action): IState {
	return authReducer(state, action);
}
