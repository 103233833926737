import { assign } from 'lodash-es';

import type { IValidatorFunc } from '@bp/shared/features/validation';
import { Validators } from '@bp/shared/features/validation';

import { FieldControlType } from './enums';

export class PropertyMetadataControl<TControlOptions> {

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	readonly type: FieldControlType<any> = <any>FieldControlType.input;

	readonly list: any[] = [];

	readonly required: boolean;

	/**
	 * A special name invoke browsers autocomplete feature
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
	 */
	readonly nativeAutocomplete: boolean | string;

	readonly validator?: IValidatorFunc<any> | null;

	readonly typeControlOptions?: TControlOptions;

	readonly placeholder: string = '';

	readonly controlValueTransformer?: ((propertyValue: any) => any) | null = null;

	readonly isSecret: boolean = false;

	constructor(data?: Partial<PropertyMetadataControl<TControlOptions>>) {
		assign(this, data);

		this.required ??= false;

		this.nativeAutocomplete ??= true;

		if (this.required)
			this.validator = Validators.compose([ this.validator, Validators.required ]);

		if (this.type === <any>FieldControlType.email)
			this.validator = Validators.compose([ this.validator, Validators.email ]);

		this._setDefaultMetadataBasedOnControlType();
	}

	private _setDefaultMetadataBasedOnControlType(): void {
		// eslint-disable-next-line default-case
		switch (this.type) {
			case FieldControlType.brandChips:
				// @ts-expect-error we need to set default state of readonly prop
				this.placeholder = 'Add brand...';
				break;

			case FieldControlType.countryChips:
				// @ts-expect-error we need to set default state of readonly prop
				this.placeholder = 'Add country...';
				break;

			case FieldControlType.chip:
				// @ts-expect-error we need to set default state of readonly prop
				this.placeholder = 'Add item...';
				break;

			case FieldControlType.email:
				// @ts-expect-error we need to set default state of readonly prop
				this.validator = Validators.compose([ this.validator, Validators.email ]);
				break;
		}
	}
}
