import { createReducer, on } from '@ngrx/store';

import type { EntitiesListState } from '@bp/admins-shared/features/entity';
import { composeEntitiesListReducer, ENTITIES_LIST_INITIAL_STATE } from '@bp/admins-shared/features/entity';

import type { CreditCard } from '../../models';

import { firebaseCollectionUpdate, listeningToFirestoreCollectionUpdatesFailure } from './credit-cards-api.actions';
import { CREDIT_CARDS_LIST_ACTIONS } from './credit-cards.actions';

export interface IState extends EntitiesListState<CreditCard> {

	all: CreditCard[];

}

export const initialState: IState = {

	...ENTITIES_LIST_INITIAL_STATE,

	all: [],

};

export const reducer = createReducer(

	initialState,

	...composeEntitiesListReducer(initialState, CREDIT_CARDS_LIST_ACTIONS),

	on(firebaseCollectionUpdate, (state, { result }): IState => ({
		...state,
		all: result,
	})),

	on(listeningToFirestoreCollectionUpdatesFailure, (state, { error }): IState => ({
		...state,
		error,
	})),

);
