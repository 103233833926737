<header class="page-header">
	<div>
		<h1 class="flex-align-items-center m-0">
			{{ isPrivate ? 'My' : '' }} Presets

			<a
				class="ml-2 bp-icon-button-lg"
				mat-icon-button
				bpTooltip="Add a new preset"
				color="primary"
				[outletLinkRelativeToParent]="{
					'[right-drawer][1]': ['preset', { drawerType: 'new' }]
				}"
			>
				<mat-icon class="material-icons-round">add_circle_outlined</mat-icon>
			</a>
		</h1>
	</div>

	<div class="controls">
		<bp-filter>
			<bp-input
				class="ml-4"
				bpFilterControl="search"
				name="presetNameSearch"
				placeholder="Search"
				[hasSearchIcon]="true"
			>
			</bp-input>

			<bp-country-selector bpFilterControl="country" placeholder="Country Or ISO Code"> </bp-country-selector>

			<mat-slide-toggle *ngIf="isPrivate" bpFilterControl="isShared" color="primary">
				Is Shared
			</mat-slide-toggle>

			<div *ngIf="!isPrivate" class="user-selector-container">
				<bp-user-selector bpFilterControl="authorUid" [items]="appFacade.users$ | async"> </bp-user-selector>
			</div>
		</bp-filter>
	</div>
</header>

<bp-presets-table [private]="isPrivate" class="slide-up-on-route-hit"></bp-presets-table>
