import { createAction, props } from '@ngrx/store';

import type { Preset } from '../../presets/models';

export const mount = createAction(
	'[Page][Demostand] Mount Preset',
	props<{ preset: Preset | null }>(),
);

export const resetState = createAction(
	'[Page][Demostand] Reset State',
);
