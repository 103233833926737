import { Country } from '@bp/shared/models/countries';

import { CashierLanguage, CashierLanguages } from './cashier-languages';

export function cashierLanguageMapper(value: CashierLanguage | Country | string): CashierLanguage | null {
	if (value instanceof CashierLanguage)
		return value;

	const foundLang = value instanceof Country
		? CashierLanguages.list.find(lang => lang.country === value)
		: CashierLanguages.findByIso(value);

	return foundLang ?? null;
}
