import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared';

import { LoginRoutingModule } from './login-routing.module';
import { LoginPageComponent } from './login-page';

@NgModule({
	imports: [
		CommonModule,
		LoginRoutingModule,
		SharedModule,
	],
	declarations: [ LoginPageComponent ],
})
export class LoginModule { }
