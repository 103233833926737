import { transform } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import type { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';

import { FADE, FADE_IN, FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import type { ControlsSectionScheme } from '@bp/shared/components/metadata';
import { FormMetadataEntityBaseComponent, ROW_EMPTY_CELL } from '@bp/shared/components/metadata';
import type { FormScheme } from '@bp/shared/models/metadata';
import { EnvironmentService } from '@bp/shared/services';

import { RightDrawerEntityChildFormBaseComponent } from '@bp/admins-shared/features/entity';

import { Preset, PresetKeys, TITLES } from '../../models';

@Component({
	selector: 'bp-preset-edit-form',
	templateUrl: './preset-edit-form.component.html',
	styleUrls: [ './preset-edit-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, FADE_IN, FADE_IN_LIST_STAGGERED ],
})
export class PresetEditFormComponent
	extends RightDrawerEntityChildFormBaseComponent<Preset>
	implements OnInit {

	override metadata = Preset.getClassMetadata();

	sections: {
		id?: string;
		title: string;
		scheme: ControlsSectionScheme<Preset>;
	}[] = [
			{
				id: 'setup',
				title: TITLES.cashierSetupDetails,
				scheme: [
					[ 'cashierKey' ],
					[ 'country', 'state' ],
					[ 'amount', 'amountLock' ],
					[ 'currency', 'currencyLock' ],
					[ 'buttonMode' ],
					[ 'buttonText' ],
					[ 'language', 'hideLanguagesDropdown' ],
					[ 'directPaymentMethod', 'depositButtonText' ],
					[ 'singlePaymentMethod', 'singlePaymentProvider' ],
					[ 'hideHeader', 'showFooter' ],
					[ 'disableProcessingMessages', 'enableFilterByCardBrand' ],
					[ 'validateInputsOnFocusOut', 'dontSkipSinglePaymentBox' ],
					[ 'showRedirectMessage' ], // long label
					[ 'saveCreditCardFlagCheckedByDefault' ], // long label
					[ 'payMode' ],
				],
			},
			{
				title: TITLES.userDetails,
				scheme: [
					[ 'email', 'phone' ],
					[ 'firstName', 'lastName' ],
					[ 'city', 'address' ],
					[ 'zipCode', 'cardHolderName' ],
				],
			},
			{
				title: TITLES.userDetailsShownWhenTheBackendAsks,
				scheme: [
					[ 'birthDate', 'gender' ],
					[ 'personalId', 'bankAccountName' ],
				],
			},
			{
				title: TITLES.serviceDetailsNotShowOnTheScreens,
				scheme: [
					[ 'affiliateId', 'trackingId' ],
					[ 'ip', 'platformId' ],
				],
			},
			{
				title: TITLES.theming,
				scheme: [
					[ 'theme' ],
					[ 'splashScreenImageUrl', 'splashScreenBackgroundColor' ],
					[ 'languagesDropdownOnLeftSide' ],
					...(<ControlsSectionScheme<Preset>> [
						this._environment.isLocalServer
							? [ 'darkThemeBackgroundColor' ]
							: [],
					]),
				],
			},
		];

	pageFormScheme = transform(
		<PresetKeys[]>[
			'cashierKey',
			'country',
			'state',
			...this.sections
				.flatMap(section => section.scheme.flatMap(sectionScheme => sectionScheme!.flat()))
				.filter((propertyName): propertyName is PresetKeys => !!propertyName && propertyName !== ROW_EMPTY_CELL),
		],
		(accumulator, key) => (accumulator[key] = null),
		<FormScheme<Preset>> {},
	);

	pageForm?: UntypedFormGroup;

	serverFormScheme = transform(
		<PresetKeys[]>[
			'cashierKey',
			'cashierSessionId',
		],
		(accumulator, key) => (accumulator[key] = null),
		<FormScheme<Preset>> {},
	);

	serverForm?: UntypedFormGroup;

	hasTokenOnInit = false;

	constructor(
		private readonly _environment: EnvironmentService,
		parentFormEntity: FormMetadataEntityBaseComponent<Preset>,
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(parentFormEntity, fb, cdr, toaster);

		this.setFormScheme(this.pageFormScheme);
	}

	override ngOnInit(): void {
		this.hasTokenOnInit = !!this.entitySetExternally?.cashierSessionId;

		this.setFormScheme(this.hasTokenOnInit ? this.serverFormScheme : this.pageFormScheme);
	}

	onTabSelectedIndexChange(tabIndex: number): void {
		this.setFormScheme(tabIndex === 0 ? this.pageFormScheme : this.serverFormScheme);

		this.form = tabIndex === 0 ? this.pageForm! : this.serverForm!;

		this.form.markAsDirty();

		this.form.updateValueAndValidity();
	}

	protected override _setupForm(): void {
		if (this.entitySetExternally && this.pageForm && this.serverForm) {
			this._repopulateFormBasedOnScheme(this.pageForm, this.pageFormScheme, this.entity);

			this._repopulateFormBasedOnScheme(this.serverForm, this.serverFormScheme, this.entity);
		} else {
			this.pageForm = this._buildFormGroupBasedOnFormScheme(this.pageFormScheme, this.entity ?? this.factory());

			this.serverForm = this._buildFormGroupBasedOnFormScheme(this.serverFormScheme, this.entity ?? this.factory());
		}

		this.form = this.entitySetExternally?.cashierSessionId ? this.serverForm : this.pageForm;
	}
}
