import { Observable } from 'rxjs';

import type { Writeable } from '@bp/shared/typings';

export function fromViewportIntersection(
	$target: HTMLElement,
	options: IntersectionObserverInit & { ignoreHorizontalScrolling?: boolean },
): Observable<IntersectionObserverEntry> {
	const ignoreHorizontalScrolling = options.ignoreHorizontalScrolling ?? true;

	return new Observable(observer => {
		const intersectionObserver = new IntersectionObserver(
			([ entry ]) => {
				const correctedEntry = cloneEntry(entry);

				if (ignoreHorizontalScrolling)
					correctedEntry.intersectionRatio = calcVerticalIntersectionRatio(entry);

				observer.next(correctedEntry);
			},
			options,
		);

		intersectionObserver.observe($target);

		return () => void intersectionObserver.disconnect();
	});

}

function calcVerticalIntersectionRatio(entry: IntersectionObserverEntry): number {
	return entry.intersectionRect.height / entry.boundingClientRect.height || 0;
}

function cloneEntry(entry: IntersectionObserverEntry): Writeable<IntersectionObserverEntry> {
	return {
		boundingClientRect: entry.boundingClientRect,
		intersectionRatio: entry.intersectionRatio,
		intersectionRect: entry.intersectionRect,
		isIntersecting: entry.isIntersecting,
		rootBounds: entry.rootBounds,
		target: entry.target,
		time: entry.time,
	};
}
