<mat-card>
	<bp-svg-icon name="logo-short-text"> </bp-svg-icon>

	<h1>Sign In</h1>

	<mat-card-content>
		<bp-alert [errors]="(identityFacade.error$ | async)?.messages" type="danger" [showType]="false"> </bp-alert>

		<div class="providers">
			<button mat-icon-button class="google" (click)="identityFacade.login()">
				<bp-svg-icon name="round-google"></bp-svg-icon>
			</button>
		</div>

		<mat-progress-bar *ngIf="identityFacade.pending$ | async" @slideUp color="primary" mode="indeterminate">
		</mat-progress-bar>
	</mat-card-content>
</mat-card>
