/* eslint-disable max-classes-per-file */
import { isEmpty } from 'lodash-es';

import { ChangeDetectionStrategy, Component, ContentChild, Directive, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import type { InputTextMaskConfig } from '@bp/shared/features/text-mask';
import { NumberMaskConfig, TextMaskConfig, TextMaskDirective } from '@bp/shared/features/text-mask';
import { MASK_SOURCE_CHAR, MASK_SUBSTITUTION_CHAR } from '@bp/shared/models/core';

/**
 * Allows the user to customize the label.
 */
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'bp-input-label',
})
export class InputLabelDirective { }

/**
 * Allows the user to customize the hint.
 */
@Directive({
	selector: 'bp-input-hint, [bpInputHint]',
})
export class InputHintDirective { }

/**
 * Allows the user to add prefix.
 */
@Directive({
	selector: 'bp-input-prefix, [bpInputPrefix]',
})
export class InputPrefixDirective { }

@Directive({
	selector: 'bp-input-suffix, [bpInputSuffix]',
})
export class InputSuffixDirective { }

@Component({
	selector: 'bp-input',
	templateUrl: './input.component.html',
	styleUrls: [ './input.component.scss' ],
	host: {
		'(focusin)': 'onTouched(); ',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: InputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: InputComponent,
			multi: true,
		},
	],
})
export class InputComponent extends FormFieldControlComponent<number | string> implements OnInit {

	static numberMask = new NumberMaskConfig({
		placeholderChar: TextMaskConfig.whitespace,
		allowDecimal: true,
		decimalLimit: 2,
		guide: false,
		maskOnFocus: true,
	});

	@Input() textarea?: boolean;

	@Input() number?: boolean;

	@Input() type?: string;

	/**
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-inputmode
	 */
	@Input() inputmode = 'text';

	@Input() mask?: InputTextMaskConfig | null;

	@Input() autocomplete?: MatAutocomplete;

	@Input() hasSearchIcon?: boolean;

	@Input() maxLength?: number;

	@Input() isSecret = false;

	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger?: MatAutocompleteTrigger;

	@ViewChild(MatInput) matInput!: MatInput;

	@ViewChild(MatInput, { read: ElementRef }) inputRef!: ElementRef<HTMLInputElement>;

	@ViewChild(TextMaskDirective) maskDirective?: TextMaskDirective;

	/** User-supplied override of the label element. */
	@ContentChild(InputLabelDirective) customLabel?: InputLabelDirective;

	@ContentChild(InputHintDirective) customHint?: InputHintDirective;

	@ContentChild(InputPrefixDirective) prefix?: InputPrefixDirective;

	@ContentChild(InputSuffixDirective) suffix?: InputSuffixDirective;

	get $$mask(): InputTextMaskConfig | null | undefined {
		return this.number ? (this.mask ?? InputComponent.numberMask) : this.mask;
	}

	get $$inputType(): string {
		return this.nativeAutocomplete
			? this.type ?? 'text'
			: 'search';
	}

	get $input(): HTMLInputElement {
		return this.inputRef.nativeElement;
	}

	get shouldAddSecretAttribute(): true | null {
		return this.isSecret ? true : null;
	}

	get useIncomingSecretValueAsPlaceholder(): boolean {
		return this.isSecret && !!this.$$incomingSecretValue;
	}

	$$incomingSecretValue: string | null = null;

	override ngOnInit(): void {
		super.ngOnInit();

		setTimeout(() => void this._setValueFromBrowserAutofill());
	}

	override writeValue(value: number | string | null): void {
		if (this.isSecret && (value !== null))
			this.$$incomingSecretValue = this._beautifyMaskSymbols(value);
		else
			super.writeValue(value);
	}

	focus(): void {
		this.matInput.focus();

		// at this point the mask may not be applied yet
		setTimeout(() => this.maskDirective?.onFocus());
	}

	/**
	 * If the autocomplete is present, the value of the internal control could
	 * be as string as an item of the autocomplete list which is any
	 */
	protected override _onInternalControlValueChange(value: any | string): void {
		this.setValue(this.maskDirective?.config instanceof NumberMaskConfig
			&& !this.maskDirective.config.allowLeadingZeroes
			&& !isEmpty(value)
			? Number(value)
			: value);
	}

	private _beautifyMaskSymbols(value: number | string | null): string | null {
		return value === null ? null : value.toString().replaceAll(MASK_SOURCE_CHAR, MASK_SUBSTITUTION_CHAR);
	}

	onInputBlur(): void {
		this._resetSecretLogicIfControlPristine();
	}

	private _resetSecretLogicIfControlPristine(): void {
		if (this.isSecret && this._internalControl.pristine)
			this._internalControl.markAsUntouched();
	}

	// Check if browser has inserted some value automatically
	// (e.g. on page restoration from history) and apply this value.
	private _setValueFromBrowserAutofill(): void {
		if (!this.$input.value || this._internalControl.value === this.$input.value)
			return;

		this.$input.dispatchEvent(new Event('input'));
	}
}
