<div
	class="text-ellipsis"
	[class.pulsing-blur]="(issue$ | async) === null"
	[bpTooltip]="hideTooltip ? '' : (issue$ | async)?.summary"
>
	<ng-container *ngIf="issue$ | async as issue; else pendingTpl">
		<a
			class="link"
			bpTargetBlank
			href="https://bridgerpay.atlassian.net/browse/{{ issue.key }}"
			[class.done]="issue.statusCategory === 'done'"
		>
			{{ issue.key }}
		</a>
		{{ issue.summary }}
	</ng-container>

	<ng-template #pendingTpl>
		<a class="link"> BDEV-000 </a>
		Some pending text
	</ng-template>
</div>
