import { Injectable } from '@angular/core';

import { FirebaseEntitiesListEffects } from '@bp/admins-shared/features/entity';

import type { Preset, PresetsQueryParams } from '../../models';
import { PresetsPageComponent } from '../../pages';
import { PresetsApiService } from '../../services';

import { PresetsFacade } from './presets.facade';

@Injectable()
export class PresetsEffects extends FirebaseEntitiesListEffects<Preset, PresetsQueryParams, PresetsFacade> {

	readonly routeComponentType = PresetsPageComponent;

	constructor(
		api: PresetsApiService,
		presets: PresetsFacade,
	) {
		super(api, presets);
	}

}
