import { Subject } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';
import type { FirebaseEntity } from '@bp/shared/models/metadata';

import { IdentityFacade } from '@bp/backoffice/domains/identity';

@Component({
	selector: 'bp-right-drawer-header-buttons',
	templateUrl: './right-drawer-header-buttons.component.html',
	styleUrls: [ './right-drawer-header-buttons.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class RightDrawerHeaderButtonsComponent {

	@Input() entity!: FirebaseEntity | null;

	@Input() canSave!: boolean | null;

	@Input() canMount!: boolean | null;

	@Output('clone') readonly clone$ = new Subject<void>();

	@Output('mount') readonly mount$ = new Subject<void>();

	@Output('delete') readonly delete$ = new Subject<void>();

	constructor(public identityFacade: IdentityFacade) { }

}
