import { createAction, props } from '@ngrx/store';

import type { DTO } from '@bp/shared/models/metadata';

import type { IdentityActions } from '@bp/shared-domains-identity';
import { composeIdentityActions, IDENTITY_STATE_SCOPE as SCOPE } from '@bp/shared-domains-identity';

import type { User } from '@bp/backoffice/domains/users';

import type { Identity } from '../models';

import { IDENTITY_API_ACTIONS } from './identity-api.actions';

export const IDENTITY_ACTIONS: IdentityActions<Identity> = {
	...composeIdentityActions(),

	api: IDENTITY_API_ACTIONS,
};

export const updateIdentityIfUserDocumentChanged = createAction(
	`${ SCOPE } Update Identity If User Document Changed`,
	props<{ user: User }>(),
);

export const createIdentityUserDocument = createAction(`${ SCOPE } Create Identity User Document`);

export const syncIdentityWithRespectiveUserDocument = createAction(
	`${ SCOPE } Sync Identity With Respective User Document`,
	props<{ identity: Identity }>(),
);

export const firebaseUserStateChanged = createAction(
	`${ SCOPE } Firebase User State Changed`,
	props<{ firebaseUser: DTO<Identity> | null }>(),
);

export const identityCreatedOrUpdatedInitially = createAction(`${ SCOPE } Identity Created Or Updated Initially`);
