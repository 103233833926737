<mat-tab-group
	[dynamicHeight]="true"
	[selectedIndex]="hasTokenOnInit ? 1 : 0"
	(selectedIndexChange)="onTabSelectedIndexChange($event)"
>
	<mat-tab label="Embed Cashier Session Parameters" class="test">
		<form *ngIf="pageForm" class="embed-cashier-session-parameters" [formGroup]="pageForm" (ngSubmit)="submit()">
			<button type="submit" class="hidden"></button>

			<bp-alert [errors]="errors" type="danger"> </bp-alert>

			<bp-property-metadata-controls-section
				*ngFor="let section of sections; let i = index"
				[heading]="section.title"
				[hasSeparator]="i !== 0"
				[form]="pageForm"
				[metadata]="metadata"
				[sectionScheme]="section.scheme"
			>
				<ng-container *bpCustomControl="'state'; inferFrom: metadata; let propertyMetadata">
					<bp-autocomplete
						*ngIf="
							(pageForm.controls['country']?.valueChanges | async)?.states ||
								pageForm.controls['country']?.value?.states as states;
							else stubTpl
						"
						@fade
						class="w-100"
						formControlName="state"
						name="state"
						label="State"
						[items]="states"
					>
					</bp-autocomplete>
					<ng-template #stubTpl>
						<div></div>
					</ng-template>
				</ng-container>
			</bp-property-metadata-controls-section>
		</form>
	</mat-tab>

	<mat-tab label="Server To Server Cashier Session Parameters">
		<form
			*ngIf="serverForm"
			class="server-to-server-cashier-session-parameters"
			[formGroup]="serverForm"
			(ngSubmit)="submit()"
		>
			<button type="submit" class="hidden"></button>

			<bp-alert [errors]="errors" type="danger"> </bp-alert>

			<fieldset>
				<legend class="mat-h2">Setup Details</legend>

				<ng-container *ngFor="let field of ['cashierKey', 'cashierSessionId']">
					<bp-property-metadata-control
						*ngIf="metadata.get($any(field)) as md"
						@fadeIn
						class="w-100"
						[metadata]="md"
						[control]="$any(serverForm.controls[field])"
					>
					</bp-property-metadata-control>
				</ng-container>
			</fieldset>
		</form>
	</mat-tab>
</mat-tab-group>
