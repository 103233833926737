<header class="page-header">
	<div>
		<h1 class="flex-align-items-center">
			Test Credit Cards

			<a
				class="ml-2 bp-icon-button-lg"
				mat-icon-button
				bpTooltip="Add a new test credit card"
				color="primary"
				[outletLinkRelativeToParent]="{
					'[right-drawer][1]': ['credit-card', { drawerType: 'new' }]
				}"
			>
				<mat-icon class="material-icons-round">add_circle_outlined</mat-icon>
			</a>
		</h1>
	</div>

	<div class="controls">
		<bp-filter>
			<bp-user-selector bpFilterControl="authorUid" [items]="appFacade.users$ | async"> </bp-user-selector>
		</bp-filter>
	</div>
</header>

<bp-credit-cards-table class="slide-up-on-route-hit"></bp-credit-cards-table>
