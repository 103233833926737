import { on, ActionCreator, ReducerTypes } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';
import { RecordsPage } from '@bp/shared/models/common';
import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';
import { cloneCollectionAndTryUpdateItemById, cloneCollectionAndUpdateOrInsertItemById } from '@bp/shared/utilities';

import { EntitiesListActions } from './entities.actions';

export type EntitiesListState<TEntity extends Entity | FirebaseEntity> = {

	recordsPage: RecordsPage<TEntity> | null;

	page: string | null | undefined;

	pending: boolean;

	error: BpError | null;

};

export type EntitiesInMemoryPagedListState<T extends Entity | FirebaseEntity> = EntitiesListState<T> & {

	all: T[] | null;

	filteredInMemory: T[] | null;

};

export const ENTITIES_LIST_INITIAL_STATE: EntitiesListState<any> = {

	recordsPage: null,

	page: null,

	pending: false,

	error: null,

};

export const ENTITIES_IN_MEMORY_PAGED_LIST_INITIAL_STATE: EntitiesInMemoryPagedListState<any> = {

	...ENTITIES_LIST_INITIAL_STATE,

	all: null,

	filteredInMemory: null,

};

function composeEntitiesBaseListReducer<
	TEntity extends Entity | FirebaseEntity,
	TLoadQueryParams,
	TState extends EntitiesListState<TEntity>
>(
	initialState: TState,
	actions: EntitiesListActions<TEntity, TLoadQueryParams>,
): ReducerTypes<TState, ActionCreator[]>[] {
	return [
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		on(actions.resetState, () => <any> initialState),

		on(
			actions.load,
			actions.loadAll,
			actions.refresh,
			state => ({
				...state,
				pending: true,
			}),
		),

		on(
			actions.api.loadSuccess,
			actions.api.loadFailure,
			actions.api.loadAllSuccess,
			actions.api.loadAllFailure,
			state => ({
				...state,
				pending: false,
			}),
		),

		on(actions.changePage, (state, { page }) => ({
			...state,
			page,
		})),

		on(actions.api.loadSuccess, (state, { result }) => ({
			...state,
			recordsPage: result,
		})),

		on(actions.load, actions.api.loadSuccess, actions.api.loadAllSuccess, state => ({
			...state,
			error: null,
		})),

		on(actions.api.loadFailure, actions.api.loadAllFailure, (state, { error }) => ({
			...state,
			error,
		})),
	];
}

export function composeEntitiesListReducer<
	TEntity extends Entity | FirebaseEntity,
	TLoadQueryParams,
	TState extends EntitiesListState<TEntity>>(
	initialState: TState,
	actions: EntitiesListActions<TEntity, TLoadQueryParams>,
): ReducerTypes<TState, ActionCreator[]>[] {
	return [
		...composeEntitiesBaseListReducer(initialState, actions),

		on(actions.updateLocalEntity, (state, { entity }) => ({
			...state,
			recordsPage: {
				...state.recordsPage!,
				records: cloneCollectionAndTryUpdateItemById(state.recordsPage!.records, entity),
			},
		})),
	];
}

export function composeEntitiesInMemoryPagedListReducer<
	TEntity extends Entity | FirebaseEntity,
	TLoadQueryParams,
	TState extends EntitiesInMemoryPagedListState<TEntity>
>(
	initialState: TState,
	actions: EntitiesListActions<TEntity, TLoadQueryParams>,
): ReducerTypes<TState, ActionCreator[]>[] {
	return [
		...composeEntitiesBaseListReducer(initialState, actions),

		on(actions.updateLocalEntity, (state, { entity }) => ({
			...state,
			all: cloneCollectionAndUpdateOrInsertItemById(state.all!, entity),
		})),

		on(actions.filteredInMemory, (state, { filtered }) => ({
			...state,
			filteredInMemory: filtered,
		})),

		on(actions.filteredRecordsPage, (state, { recordsPage }) => ({
			...state,
			recordsPage,
		})),

		on(actions.api.loadAllSuccess, (state, { result }) => ({
			...state,
			all: result.records,
		})),
	];
}
