import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { FloatLabelType } from '@angular/material/form-field';

import type { InputTextMaskConfig } from '@bp/shared/features/text-mask';
import type { OnChanges, SimpleChange, SimpleChanges } from '@bp/shared/models/core';
import type { InputBasedControlOptions, PropertyMetadataControl } from '@bp/shared/models/metadata';
import { CountryControlOptions, FieldControlType, PropertyMetadata } from '@bp/shared/models/metadata';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { FormFieldAppearance } from '@bp/shared/components/core';

@Component({
	selector: 'bp-property-metadata-control',
	templateUrl: './property-metadata-control.component.html',
	styleUrls: [ './property-metadata-control.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyMetadataControlComponent extends Destroyable implements OnChanges {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	FieldControlType = FieldControlType;

	@Input() metadata!: PropertyMetadata;

	@Input() control!: UntypedFormControl;

	@Input() appearance?: FormFieldAppearance;

	@Input() floatLabel?: FloatLabelType;

	@Input() label?: string | null;

	@Input() color?: ThemePalette;

	@Input() pending?: boolean;

	@Input() disabled?: boolean;

	@Input() items?: any[] | null;

	@Input() textMask?: InputTextMaskConfig | null;

	private _initialControlValidator: ValidatorFn | null = null;

	get controlMetadata(): PropertyMetadataControl<unknown> {
		return this.metadata.control;
	}

	get $$items(): any[] {
		return this.items ?? this.controlMetadata.list;
	}

	get $$textMask(): InputTextMaskConfig | null | undefined {
		return this.textMask ?? this.$$inputControlOptions.textMask;
	}

	get $$inputControlOptions(): InputBasedControlOptions {
		return this.getControlOptions(FieldControlType.input);
	}

	get $$countryControlOptions(): CountryControlOptions {
		return this.getControlOptions(FieldControlType.country);
	}

	get controlLabel(): string | null | undefined {
		return this.label === null
			? ''
			: (this.label ?? this.metadata.label);
	}

	isControlTypeInputLike = false;

	isControlTypeInputChipLike = false;

	isControlTypeChipLike = false;

	private _pristineAndDirtyCheckSubscription: Subscription | null = null;

	private _controlValueTransformerSubscription: Subscription | null = null;

	constructor(
		private readonly _renderer: Renderer2,
		private readonly _host: ElementRef,
	) {
		super();
	}

	ngOnChanges({ metadata, control, disabled }: SimpleChanges<this>): void {
		metadata && this._setHostClass(metadata);

		if (control)
			this._initialControlValidator = this.control.validator;

		if ((metadata || control) && (this.metadata.control.validator || this._initialControlValidator))
			this._addValidatorsOrPlanUpdatingOnPristineOrDirty(metadata);

		if (metadata || control)
			this._onValueChangeApplyTransformers();

		if (metadata) {
			this.isControlTypeInputLike = FieldControlType.inputLike.includes(<any> this.controlMetadata.type);

			this.isControlTypeInputChipLike = FieldControlType.inputChipLike.includes(this.controlMetadata.type);

			this.isControlTypeChipLike = FieldControlType.chipLike.includes(this.controlMetadata.type);
		}

		if (disabled)
			this.disabled ? this.control.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
	}

	getControlOptions<TControlOptions>(_type: FieldControlType<TControlOptions>): TControlOptions {
		return <TControlOptions>(this.controlMetadata.typeControlOptions ?? {});
	}

	private _setHostClass({ previousValue, currentValue }: SimpleChange<this[ 'metadata' ]>): void {
		previousValue && this._renderer.removeClass(this._host.nativeElement, this._getHostClass(previousValue));

		currentValue && this._renderer.addClass(this._host.nativeElement, this._getHostClass(currentValue));
	}

	private _getHostClass(md: PropertyMetadata): string {
		return `control-type-${ md.control.type.cssClass }`;
	}

	private _addValidatorsOrPlanUpdatingOnPristineOrDirty(metadataChange?: SimpleChange<PropertyMetadata>): void {
		if (metadataChange
			&& metadataChange.currentValue !== metadataChange.previousValue
			&& metadataChange.previousValue?.control.validator)
			this.control.removeValidators(metadataChange.previousValue.control.validator);

		if (this.controlMetadata.isSecret)
			this._onSecretControlPristineOrDirtyUpdateValidators();
		else if (this.metadata.control.validator)
			this.control.addValidators(this.metadata.control.validator);
	}

	private _onSecretControlPristineOrDirtyUpdateValidators(): void {
		this._pristineAndDirtyCheckSubscription?.unsubscribe();

		this._pristineAndDirtyCheckSubscription = this.control.valueChanges
			.pipe(
				startWith(null),
				map(() => this.control.pristine),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(isPristine => {
				if (isPristine)
					this.control.clearValidators();
				else {
					this.control.setValidators([
						...(this._initialControlValidator ? [ this._initialControlValidator ] : []),
						...(this.metadata.control.validator ? [ this.metadata.control.validator ] : []),
					]);
				}

				this.control.updateValueAndValidity();
			});
	}

	private _onValueChangeApplyTransformers(): void {
		this._controlValueTransformerSubscription?.unsubscribe();

		this._controlValueTransformerSubscription = this.control.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe((value: any) => {
				if (!this.controlMetadata.controlValueTransformer)
					return;

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const transformedValue = this.controlMetadata.controlValueTransformer(value);

				if (transformedValue !== value)
					this.control.setValue(transformedValue, { emitEvent: false });
			});
	}

}
