import { isNil, isPlainObject, get } from 'lodash-es';
import type { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, fromEvent } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import type { OnChanges, SimpleChanges } from '@angular/core';
import {
	ChangeDetectionStrategy, Component, ElementRef, Input, Output,
	ViewChild, EventEmitter
} from '@angular/core';

import { FADE } from '@bp/shared/animations';
import type { CashierCreditCard } from '@bp/shared/models/cashier';
import type { CashierDepositResult, ICashierConfiguration } from '@bp/shared/models/cashier-core';
import { CashierEnvironment, CashierEvent, mapPrivateEmbeddedParamNameToDatasetPropertyName } from '@bp/shared/models/cashier-core';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import { OptionalBehaviorSubject } from '@bp/shared/rxjs';
import { EnvironmentService } from '@bp/shared/services';
import { toPlainObject, uuid } from '@bp/shared/utilities';

@Component({
	selector: 'bp-cashier',
	templateUrl: './cashier.component.html',
	styleUrls: [ './cashier.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class CashierComponent extends Destroyable implements OnChanges {

	@Input() config!: ICashierConfiguration | Partial<ICashierConfiguration> | null;

	@Input() creditCard?: CashierCreditCard | null;

	@Input('environment') cashierEnvironment?: CashierEnvironment;

	@Input() showSplashScreen = false;

	@Input() orderPrefix!: string;

	private readonly _mounting$ = new BehaviorSubject(false);

	@Output('mounting') readonly mounting$ = this._mounting$.asObservable();

	@Output() readonly deposited = new EventEmitter<CashierDepositResult>();

	@Output() readonly logrocketSessionUrl = new EventEmitter<string>();

	@ViewChild('cashierOutlet', { static: true }) cashierOutletRef!: ElementRef<HTMLElement>;

	get $cashierOutlet(): HTMLElement {
		return this.cashierOutletRef.nativeElement;
	}

	$host = this._host.nativeElement;

	private readonly _cashierKey$ = new OptionalBehaviorSubject<string | null>();

	constructor(
		private readonly _environment: EnvironmentService,
		private readonly _host: ElementRef<HTMLElement>,
	) {
		super();

		this._mountingStopOnCashierContentRender();

		this._emitDepositedOnCashierDeposit();

		this._emitLogrocketSessionUrlOnCashierMessage();

		this._remountCashierOnNewOrderIdRequest();
	}

	ngOnChanges({ config }: Partial<SimpleChanges>): void {
		if (config) {
			this._cashierKey$.next(this.config ? this.config.cashierKey! : null);

			// this.showSplashScreen = !this.config?.splashScreenImageUrl;

			this._tryRemountCashier();
		}
	}

	private _tryRemountCashier(): void {
		this._removeCashier();

		this.config && this._mountCashier();
	}

	remount(): void {
		this._removeCashier();

		this._mountCashier();
	}

	private _mountCashier(): void {
		this._mounting$.next(true);

		const $script = this._buildCashierLoaderScript();

		this.$cashierOutlet.append($script);
	}

	private _buildCashierLoaderScript(): HTMLScriptElement {
		const $script = document.createElement('script');

		$script.src = this.cashierEnvironment?.embedScriptSource
			?? this._environment.cashierLoaderUrl;

		this._populateCashierLoaderScriptDataset($script);

		return $script;
	}

	private _populateCashierLoaderScriptDataset($script: HTMLScriptElement): void {
		const config = this._getCashierLoaderEmbeddedCashierConfiguration();

		Object
			.keys(config)
			.map(propertyName => <const>[
				mapPrivateEmbeddedParamNameToDatasetPropertyName(propertyName),
				get(config, propertyName),
			])
			.filter(([ , propertyValue ]) => !isNil(propertyValue))
			.forEach(([ propertyName, propertyValue ]) => ($script.dataset[propertyName] = isPlainObject(propertyValue)
				? JSON.stringify(propertyValue)
				: (<string>propertyValue).toString()
			));
	}

	private _getCashierLoaderEmbeddedCashierConfiguration(): Partial<ICashierConfiguration> {
		// Parse for passing to the script's dataset
		const config = toPlainObject<Partial<ICashierConfiguration>>(this.config!);

		if (isNil(this.config!.orderId)) {
			config.orderId = uuid({
				prefix: this.orderPrefix,
				short: true,
			});
		}

		if (this.creditCard)
			config.$$creditCard = toPlainObject(this.creditCard);

		return config;
	}

	private _removeCashier(): void {
		// eslint-disable-next-line unicorn/prefer-spread
		Array.from(this.$cashierOutlet.children)
			.forEach(it => void it.remove());
	}

	private _mountingStopOnCashierContentRender(): void {
		this._onCashierEvent$(CashierEvent.contentRendered)
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._mounting$.next(false));
	}

	private _emitDepositedOnCashierDeposit(): void {
		this._onCashierEvent$(CashierEvent.deposit)
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => void this.deposited.emit(v.type));
	}

	private _emitLogrocketSessionUrlOnCashierMessage(): void {
		this._onCashierEvent$(CashierEvent.logrocketSessionUrl)
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => void this.logrocketSessionUrl.emit(v.url));
	}

	private _remountCashierOnNewOrderIdRequest(): void {
		this._onCashierEvent$(CashierEvent.requestNewOrderId)
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.remount());
	}

	private _onCashierEvent$<T>(cashierEvent: CashierEvent<T>): Observable<T> {
		return this._cashierKey$
			.pipe(
				switchMap(cashierKey => isNil(cashierKey)
					? EMPTY
					: fromEvent<CustomEvent<T>>(window, `[bp][cashier:${ cashierKey }]:${ cashierEvent }`)),
				map(event => event.detail),
			);
	}
}
