import { NgModule } from '@angular/core';

import { AdminsSharedFeaturesNotificationsHubModule } from '@bp/admins-shared/features/notifications-hub';

import { SharedModule } from '../shared';

import { BackofficeToolbarComponent, MenuComponent, RootComponent, SvgIconsDefinitionsComponent } from './components';

export const COMPONENTS = [
	RootComponent,
	MenuComponent,
	BackofficeToolbarComponent,
	SvgIconsDefinitionsComponent,
];

@NgModule({
	imports: [
		AdminsSharedFeaturesNotificationsHubModule,
		SharedModule,
	],
	declarations: COMPONENTS,
	exports: COMPONENTS,
})
export class CoreModule { }
