import { ɵɵLinkDirective } from 'ngx-quicklink';

import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

import { RouterLinkInput } from './router-link-input-type';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[routerLink], [routerLinkNoOutlets]',
})
export class BpQuicklinkDirective extends ɵɵLinkDirective implements OnChanges {

	@Input() routerLinkNoOutlets: RouterLinkInput;

	override ngOnChanges(changes: Partial<SimpleChanges>): void {
		if (changes['routerLinkNoOutlets'])
			changes['routerLink'] = changes['routerLinkNoOutlets'];

		super.ngOnChanges(<SimpleChanges>changes);
	}
}
