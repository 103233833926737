<bp-right-drawer-entity
	#drawer
	[facade]="presetFacade"
	[entity]="presetFacade.entity$ | async"
	[pending]="presetFacade.pending$ | async"
	[firstLoading]="presetFacade.isFirstLoading$ | async"
	[factory]="presetFacade.factory"
	[drawerType]="presetFacade.drawerType$ | async"
	[error]="presetFacade.error$ | async"
>
	<bp-right-drawer-entity-header
		namePlaceholder="Preset Title Or Jira Issue Key"
		[name]="(presetFacade.entity$ | async)?.name"
		[jiraIssueKey]="(presetFacade.entity$ | async)?.jiraIssueKey"
		(nameChange)="drawer.updateDrawerEntityAndSaveDraftToStorage({ name: $event })"
		(jiraIssueKeyChange)="drawer.updateDrawerEntityAndSaveDraftToStorage({ jiraIssueKey: $event })"
	>
		<bp-right-drawer-header-buttons
			[entity]="presetFacade.entity$ | async"
			[canSave]="drawer.formDirtyAndValid$ | async"
			[canMount]="drawer.formValid$ | async"
			(delete)="presetFacade.delete(presetFacade.entity!)"
			(clone)="drawer.changeDrawerRouteParams(DrawerType.clone)"
			(mount)="demostandFacade.mount(presetFacade.entity!)"
		>
		</bp-right-drawer-header-buttons>
	</bp-right-drawer-entity-header>

	<bp-preset-testing-details-view
		*ngIf="showTestingDetailsView$ | async"
		class="card card-view mb-4"
		[class.can-edit]="canEdit$ | async"
		[entity]="presetFacade.entity$ | async"
		(click)="canEdit && drawer.setDrawerRouteParamsToEdit(testingDetailsFormId)"
	>
	</bp-preset-testing-details-view>

	<bp-preset-testing-details-edit-form
		*ngIf="showTestingDetailsForm$ | async"
		class="card card-edit mb-4"
		[entity]="presetFacade.entity$ | async"
		[error]="presetFacade.error$ | async"
		[pending]="presetFacade.pending$ | async"
		(entityChange)="drawer.updateDrawerEntityAndSaveDraftToStorage($event)"
		(submitted)="drawer.submit()"
	>
	</bp-preset-testing-details-edit-form>

	<bp-preset-view
		*ngIf="showCashierDetailsView$ | async"
		class="card card-view"
		[class.can-edit]="canEdit$ | async"
		[entity]="presetFacade.entity$ | async"
		(click)="canEdit && drawer.setDrawerRouteParamsToEdit(cashierDetailsFormId)"
	>
	</bp-preset-view>

	<bp-preset-edit-form
		*ngIf="showCashierDetailsForm$ | async"
		class="card card-edit"
		[entity]="presetFacade.entity$ | async"
		[error]="presetFacade.error$ | async"
		[pending]="presetFacade.pending$ | async"
		(entityChange)="drawer.updateDrawerEntityAndSaveDraftToStorage($event)"
		(submitted)="drawer.submit()"
	>
	</bp-preset-edit-form>
</bp-right-drawer-entity>
