/* eslint-disable @typescript-eslint/promise-function-async */
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { TelemetryService } from '@bp/shared/services';

import { identityHasAccessGuardConfig, IdentityHasAccessGuard } from '@bp/shared-domains-identity';

import { BackofficeFeature } from '@bp/backoffice/domains/users';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'cashier-demostand/presets',
	},

	{
		path: 'bridger-psps',
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		data: {
			...identityHasAccessGuardConfig(BackofficeFeature.bridgerPsps),
		},
		loadChildren: () => import('@bp/backoffice/sections/firebase-psps').then(m => m.FirebasePspsModule),
	},

	{
		path: 'newsroom',
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		data: {
			preload: false,
			...identityHasAccessGuardConfig(BackofficeFeature.newsroom),
		},
		loadChildren: () => import('@bp/backoffice/sections/newsroom').then(m => m.NewsroomModule),
	},

	{
		path: 'careers',
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		data: {
			...identityHasAccessGuardConfig(BackofficeFeature.careers),
		},
		loadChildren: () => import('@bp/backoffice/sections/careers').then(m => m.CareersModule),
	},

	{
		path: 'bridger-events',
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		data: {
			...identityHasAccessGuardConfig(BackofficeFeature.bridgerEvents),
		},
		loadChildren: () => import('@bp/backoffice/sections/bridger-events').then(m => m.BridgerEventsModule),
	},

	{
		path: 'users',
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		data: {
			...identityHasAccessGuardConfig(BackofficeFeature.users),
		},
		// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
		loadChildren: () => import('@bp/backoffice/sections/users').then(m => m.UsersModule),
	},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload',
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			scrollOffset: [ 0, 150 ],
			canceledNavigationResolution: 'replace',
			preloadingStrategy: QuicklinkStrategy,
			errorHandler: TelemetryService.routerErrorHandler,
		}),
	],
	exports: [ RouterModule, QuicklinkModule ],
})
export class AppRoutingModule {}
