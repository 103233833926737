import { isArray, isEmpty, isNil, isNumber, isRegExp, isString, keys, mapKeys, merge } from 'lodash-es';
import type { Moment } from 'moment';
import moment from 'moment';
import type { Observable } from 'rxjs';

import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators as NgValidators } from '@angular/forms';

import type { IValidationErrors } from './models';

export type IValidatorInput<T = unknown> = { value: T };

export type IValidatorFunc<T = unknown> = (input: IValidatorInput<T>) => IValidationErrors | null;

export type IAsyncValidatorFunc<T = unknown> = (input: IValidatorInput<T>) => Observable<IValidationErrors | null> | Promise<IValidationErrors | null>;

export const OUT_OF_AGE_DATE = moment().subtract(18, 'years');

/**
 * Notes:
 * - maximum length of each domain part (label) is 63 symbols
 * - last domain label must start with letter
 * - \p{L} regexp class matches any Unicode letter (to allow unicode domains)
 *     - fully supported https://caniuse.com/mdn-javascript_builtins_regexp_property_escapes
 *
 * Regexp and some examples: https://regex101.com/r/0H3wpz/5
 */
const URL_REGEXP = '(?:www\\.)?(?:[\\p{L}\\d\\-]{1,63}\\.)+\\p{L}[\\d\\p{L}]{0,62}(?:[\\/?][\\w#%&()+.\\/:=?@~-]*)?';

/**
 * Provides a set of custom validators.
 *
 * A validator is a function that processes a {@link FormControl} or collection of
 * controls and returns a map of errors. A null map means that validation has passed.
 *
 * ### Example
 *
 * ```typescript
 * var loginControl = new FormControl("", Validators.required)
 * ```
 */
export class Validators {

	static email(this: void, input: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(input.value))
			return null; // Don't validate empty values to allow optional controls

		const result = NgValidators.email(new UntypedFormControl(input.value));

		if (result !== null)
			return result;

		const [ , domain ] = (<string>input.value).split('@');

		// Check domain is non-root
		return domain.includes('.')
			? null
			: { email: true };
	}

	/**
	 * Validator that requires controls to have a non-empty and without whitespaces value.
	 */
	static required(this: void, { value }: IValidatorInput): IValidationErrors | null {
		return Validators.isEmptyValue(value) || (isString(value) && !value.trim()) || (value === false)
			? { required: true }
			: null;
	}

	static requiredArray(this: void, { value }: IValidatorInput): IValidationErrors | null {
		return isEmpty(value)
			? { required: true }
			: null;
	}

	static pascalCase(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`pascalCase` validator expects string to be validated');

		return (/\s|^[a-z]/ug).test(value)
			? { pascalCase: true }
			: null;
	}

	static afterDate(this: void, maxMoment: Moment): IValidatorFunc<Moment> {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!moment.isMoment(value))
				throw new Error('`afterDate` validator expects moment to be validated');

			return value.isAfter(maxMoment)
				? { afterDate: true }
				: null;
		};
	}

	/**
	 * Validator that requires controls to have a non-empty and without whitespaces value.
	 * @param name name of the custom required message key
	 */
	static customRequired(this: void, name: string): IValidatorFunc {
		return (validatable): IValidationErrors | null => Validators.required(validatable)
			? { [`required.${ name }`]: true }
			: null;
	}

	static noZero(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isNumber(value))
			throw new Error('`noZero` validator expects number to be validated');

		return value === 0
			? { noZero: true }
			: null;
	}

	static hasUpperCaseAndLowerCaseCharacter(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const letters = [ ...<string>value ];
		const hasUpperCaseLetter = letters.some(v => v === v.toUpperCase() && v !== v.toLowerCase());
		const hasLowerCaseLetter = letters.some(v => v === v.toLowerCase() && v !== v.toUpperCase());

		return hasUpperCaseLetter && hasLowerCaseLetter
			? null
			: { hasUpperCaseAndLowerCaseCharacter: true };
	}

	static hasSpecialCharacter(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return (/[!"#$%&'()*+,./:;<=>?@[\\\]^_{|}-]/u).test(value)
			? null
			: { hasSpecialCharacter: true };
	}

	static hasLetterCharacter(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const hasLetterCharacter = (/\p{L}/u).test(value);

		return hasLetterCharacter ? null : { hasLetterCharacter: true };
	}

	static onlyASCIICharacters(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		// eslint-disable-next-line no-control-regex
		const hasNonASCIICharacters = (/[^\u{0}-\u{7F}]/u).test(value);

		return hasNonASCIICharacters ? { onlyASCIICharacters: true } : null;
	}

	static noLeadingOrTrailingSpace(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const hasLeadingOrTrailingSpace = (/^\s|\s$/u).test(value);

		return hasLeadingOrTrailingSpace ? { noLeadingOrTrailingSpace: true } : null;
	}

	static hasDigitCharacter(this: void, { value }: IValidatorInput<string>): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		const hasDigit = (/\d/u).test(value);

		return hasDigit ? null : { hasDigitCharacter: true };
	}

	static password(this: void): IValidatorFunc<string> {
		const passwordValidator = Validators.compose([
			Validators.noLeadingOrTrailingSpace,
			Validators.onlyASCIICharacters,
			Validators.hasLetterCharacter,
			Validators.hasDigitCharacter,
			Validators.minLength(7),
			Validators.maxLength(32),
		])!;

		return (validatable): IValidationErrors | null => {
			if (Validators.isEmptyValue(validatable.value))
				return null; // Don't validate empty values to allow optional controls

			const result = passwordValidator(validatable);

			return result
				? mapKeys(result, (value, key) => `password.${ key }`)
				: null;
		};
	}

	static confirmPassword(this: void, propertyName: string = 'password'): ValidatorFn {
		return (control): IValidationErrors | null => {
			if (Validators.isEmptyValue(control.value))
				return null; // Don't validate empty values to allow optional controls

			if (!(control.parent instanceof UntypedFormGroup))
				throw new Error('The confirm Password validator expects the control\'s parent to be a FormGroup');

			return control.parent.controls[propertyName].value === control.value
				? null
				: { passwordConfirm: true };
		};
	}

	static digits(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		return Number.isNaN(Number(value))
			? { digits: true }
			: null;
	}

	static onlyLetters(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`onlyLetters` validator expects string to be validated');

		return (/[\d!#$%&'()*+,.:;<=>?@[\]^{|}-]/ug).test(value)
			? { noSpecialCharactersOrDigits: true }
			: null;
	}

	/** Allows some specific symbols which could be in names */
	static noSpecialCharactersOrDigits(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`noSpecialCharactersOrDigits` validator expects string to be validated');

		return (/[~$&+:;=?@#|<>^*()%![\]{}\d]/ug).test(value)
			? { noSpecialCharactersOrDigits: null }
			: null;
	}

	static alphanumeric(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`alphanumeric` validator expects string to be validated');

		return ((/\W/ug).test(value))
			? { alphanumeric: null }
			: null;
	}

	/**
	 * Validator that requires controls to have a value of a minimum possible value.
	 */
	static minimum(this: void, required: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isNumber(value))
				throw new Error('`minimum` validator expects number to be validated');

			return value < required
				? { minimum: { required, actual: value } }
				: null;
		};
	}

	static greaterThan(this: void, required: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isNumber(value))
				throw new Error('`greaterThan` validator expects number to be validated');

			return value <= required
				? { greaterThan: { required, actual: value } }
				: null;
		};
	}

	/**
	 * Validator that requires controls to have a value of a maximum possible value.
	 */
	static maximum(this: void, required: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isNumber(value))
				throw new Error('`maximum` validator expects number to be validated');

			return value > required
				? { maximum: { required, actual: value } }
				: null;
		};
	}

	/**
	 * Validator that requires controls to have a value of a minimum length.
	 */
	static minLength(this: void, requiredLength: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isString(value))
				throw new Error('`minLength` validator expects string to be validated');

			const actual = value.length;

			return actual < requiredLength
				? { minlength: { requiredLength, actual } }
				: null;
		};
	}

	/**
	 * Validator that requires controls to have a value of a maximum length.
	 */
	static maxLength(this: void, requiredLength: number): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isString(value))
				throw new Error('`maxLength` validator expects string to be validated');

			const actual = value.length;

			return actual > requiredLength
				? { maxlength: { requiredLength, actual } }
				: null;
		};
	}

	static excessSafeNumber(this: void, enabled: boolean): IValidatorFunc {
		const maxSafeNumberValue = 999_999_999_999.99;

		return ({ value }): IValidationErrors | null => {
			if (!isNumber(value))
				throw new Error('`excessSafeNumber` validator expects number to be validated');

			return enabled && value > maxSafeNumberValue
				? { excessSafeNumber: true }
				: null;
		};
	}

	static ip(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`ip` validator expects string to be validated');

		// eslint-disable-next-line prefer-named-capture-group
		return (/^((\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])$/gu).test(value)
			? null
			: { ip: true };
	}

	static url(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`url` validator expects string to be validated');

		return (new RegExp(`^https?:\\/\\/${ URL_REGEXP }$`, 'ug')).test(value)
			? null
			: { url: true };
	}

	static urlWithOptionalProtocol(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`urlWithOptionalProtocol` validator expects string to be validated');

		return (new RegExp(`^(https?:\\/\\/)?${ URL_REGEXP }$`, 'ug')).test(value)
			? null
			: { urlWithOptionalProtocol: true };
	}

	static urlWithoutProtocol(this: void, { value }: IValidatorInput): IValidationErrors | null {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values to allow optional controls

		if (!isString(value))
			throw new Error('`urlWithoutProtocol` validator expects string to be validated');

		return (new RegExp(`^${ URL_REGEXP }$`, 'ug')).test(value)
			? null
			: { urlWithoutProtocol: true };
	}

	/**
	 * Validator that requires a control to match a regex to its value.
	 */
	static pattern(this: void, pattern: RegExp | string, message?: string): IValidatorFunc {
		const regex = isRegExp(pattern) ? pattern : new RegExp(pattern, 'u');

		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null; // Don't validate empty values to allow optional controls

			if (!isString(value))
				throw new Error('`pattern` validator expects string to be validated');

			return regex.test(value)
				? null
				: {
					pattern: message ?? { required: regex.source, actual: value },
				};
		};
	}

	/**
	 * No-op validator.
	 */
	static nullValidator(this: void): IValidatorFunc {
		return (): IValidationErrors | null => null;
	}

	/**
	 *
	 * @returns true if the number is of provided length, otherwise false
	 */
	static fixedLength(this: void, requiredLength: number, name?: string): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (Validators.isEmptyValue(value))
				return null;

			if (!isNumber(value) && !isString(value))
				throw new Error('`fixedLength` validator expects string  or number to be validated');

			const actual = (value).toString().length;

			return actual === requiredLength
				? null
				: { [name ? `fixedLength.${ name }` : 'fixedLength']: { requiredLength, actual } };
		};
	}

	/**
	 * Validator that requires each item of the array to match provided rules
	 * @param validators - array of rules which will be applied to each element of the array
	 * @returns
	 */
	static runOverEachArrayItem<TControlValue extends [] = []>(this: void, validators: IValidatorFunc<TControlValue>[]): IValidatorFunc {
		return ({ value }): IValidationErrors | null => {
			if (isEmpty(value) || Validators.isEmptyValue(value))
				return null;

			if (!isArray(value))
				throw new Error('`runOverEachArrayItem` validator expects array to be validated');

			const errors = <IValidationErrors[]>
				value.flatMap((arrayItem: TControlValue) => validators.map(validator => validator({ value: arrayItem })));

			return Validators._mergeErrors(errors);
		};
	}

	/**
	 * Compose multiple validators into a single function that returns the union
	 * of the individual error maps.
	 */
	static compose<T = unknown>(this: void, validators: (IValidatorFunc<T> | null | undefined)[]): IValidatorFunc<T> | null {
		if (isEmpty(validators))
			return null;

		const presentValidators = <IValidatorFunc[]>validators.filter(validator => !isNil(validator));

		if (isEmpty(presentValidators))
			return null;

		return validatable => Validators._mergeErrors(
			Validators._executeValidators(validatable, presentValidators),
		);
	}

	static composeAsync(this: void, validators: (IAsyncValidatorFunc<any> | null | undefined)[]): IAsyncValidatorFunc<any> | null {
		if (isEmpty(validators))
			return null;

		const presentValidators = <IAsyncValidatorFunc<any>[]>validators.filter(validator => !isNil(validator));

		if (isEmpty(presentValidators))
			return null;

		return async validatable => {
			const promises = Validators
				._executeAsyncValidators(validatable, presentValidators)
				// eslint-disable-next-line @typescript-eslint/promise-function-async
				.map(v => Validators._convertToPromise(v));

			const errors = await Promise
				.all(promises);

			return Validators._mergeErrors(errors);
		};
	}

	static isEmptyValue(this: void, value: unknown): value is '' | null {
		return isNil(value) || value === '';
	}

	// eslint-disable-next-line @typescript-eslint/require-await, @typescript-eslint/promise-function-async
	private static _convertToPromise(this: void, object: any): Promise<any> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		return !!object && typeof object.then === 'function' ? object : object(object);
	}

	private static _executeValidators(this: void, validatable: IValidatorInput, validators: IValidatorFunc[]): any[] {
		return validators.map(v => v(validatable));
	}

	private static _executeAsyncValidators(
		this: void,
		control: IValidatorInput,
		validators: IAsyncValidatorFunc[],
	): any[] {
		return validators.map(v => v(control));
	}

	private static _mergeErrors(arrayOfErrors: (IValidationErrors | null)[]): IValidationErrors | null {
		// eslint-disable-next-line unicorn/prefer-object-from-entries
		const result = arrayOfErrors.reduce(
			(accumulator: IValidationErrors | null, errors: IValidationErrors | null) => errors === null ? accumulator : merge(accumulator, errors),
			{},
		);

		return keys(result).length === 0 ? null : result;
	}
}
