import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { pending } from '@bp/shared/rxjs';
import { FirebaseService } from '@bp/shared/services';

import { JiraIssue } from '@bp/firebase-functions';

@Injectable({
	providedIn: 'root',
})
export class JiraService {

	pending$ = new BehaviorSubject(false);

	private readonly _collection = 'demostand-jira-issues';

	private readonly _baseQuery = {
		limit: 15,
	};

	constructor(private readonly _firebaseService: FirebaseService) { }

	getLastTenUpdated() {
		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this._collection,
			this._baseQuery,
			this._factory,
		)
			.pipe(
				pending(this.pending$),
				map(v => v.records),
			);
	}

	search(search: string) {
		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this._collection,
			{
				search,
				...this._baseQuery,
			},
			this._factory,
		)
			.pipe(
				pending(this.pending$),
				map(v => v.records),
			);
	}

	getIssue(issueKey: string) {
		return this._firebaseService.listenToDocumentChanges(
			`${ this._collection }/${ issueKey }`,
			this._factory,
		);
	}

	/*
	 * ConvertJiraApiToJiraIssue() {
	 * 	return (<IJiraApiIssue[]>require('./jira.json'))
	 * 		.reverse()
	 * 		.map(v => JiraIssue.fromJiraApiIssue(v));
	 * }
	 */

	/*
	 * ImportJiraIssuesToFirestore() {
	 * 	this.convertJiraApiToJiraIssue().forEach(v => this.fb.set(`demostand-jira-issues/${v.key}`, v));
	 * }
	 */

	private readonly _factory = (v: Partial<JiraIssue>) => new JiraIssue(v);
}
