import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';

import { AvatarComponent, UserSelectorComponent } from './components';

const EXPOSED = [
	AvatarComponent,
	UserSelectorComponent,
];

@NgModule({
	imports: [
		CommonModule,
		MatIconModule,

		SharedFeaturesTooltipModule,
	],
	exports: [
		EXPOSED,
	],
	declarations: [
		EXPOSED,
	],
})
export class BackofficeDomainsUsersModule { }
