import { fromEvent } from 'rxjs';

import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { SLIDE } from '@bp/shared/animations';
import { takeUntilDestroyed } from '@bp/shared/models/common';

import { FirestoreUploadControlComponent } from '../firestore-upload-control';

@Component({
	selector: 'bp-img-upload-image-btn',
	templateUrl: './img-upload-image-btn.component.html',
	styleUrls: [ './img-upload-image-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ImgUploadImageBtnComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: ImgUploadImageBtnComponent,
			multi: true,
		},
	],
})
export class ImgUploadImageBtnComponent extends FirestoreUploadControlComponent implements OnInit {

	@Input() canEdit = true;

	@Input() accept = '.png,.jpg,.svg';

	@ViewChild('filePicker', { static: true }) filePickerRef!: ElementRef<HTMLInputElement>;

	get filePicker(): HTMLInputElement {
		return this.filePickerRef.nativeElement;
	}

	get file(): File | null {
		return this.filePicker.files?.[0] ?? null;
	}

	isExceededAllowedSize = false;

	ngOnInit(): void {
		fromEvent(this.filePicker, 'change')
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => {
				this.isExceededAllowedSize = !!(this.file && (this.file.size > (15 * 1024 * 1024)));

				if (!this.isExceededAllowedSize && this.file) {
					this.isPerformUploading$.next(true);

					void this._firebase.upload(this.file, this.bucketPath);
				}

				this.onTouched();

				this.validatorOnChange();

				this._cdr.markForCheck();
			});
	}

	override validate(): Record<string, string> | null {
		return this.isExceededAllowedSize ? { exceededAllowedSize: 'The maximum allowed image size is 15Mb' } : null;
	}

	override writeValue(value: string): void {
		this.isExceededAllowedSize = false;

		this.filePicker.value = '';

		super.writeValue(value);
	}

	@HostListener('click', [ '$event' ])
	onClick(event: MouseEvent): void {
		event.stopPropagation();

		this.filePicker.click();
	}

}
