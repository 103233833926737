import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';

import type { User } from '@bp/backoffice/domains/users';

export const usersUpdateSuccess = createAction(
	'[API][Users] Listening To Users Update Success',
	props<ResultActionPayload<User[]>>(),
);

export const usersUpdateFailure = createAction(
	'[API][Users] Listening To Users Update Failure',
	props<ErrorActionPayload>(),
);
