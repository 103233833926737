<a
	mat-flat-button
	cdkFocusInitial
	color="accent"
	[disabled]="!entity || !canMount"
	[outletLinkRelativeToRootFirstChild]="{ primary: ['demostand', entity?.id ? { preset: entity!.id } : {}] }"
	(click)="mount$.next()"
>
	launch <bp-svg-icon name="rocket" class="ml-1 bp-icon-sm inline-flex"></bp-svg-icon>
</a>

<button
	*ngIf="(entity && !entity!.id) || identityFacade.user!.canEdit(entity?.authorUid)"
	@fadeIn
	type="submit"
	mat-flat-button
	color="primary"
	[disabled]="!canSave"
>
	save
</button>

<button *ngIf="!!entity?.id" @fadeIn type="button" mat-icon-button [matMenuTriggerFor]="sideMenu">
	<mat-icon>more_vert</mat-icon>

	<mat-menu #sideMenu="matMenu">
		<button *ngIf="!!entity?.id" type="button" mat-menu-item (click)="clone$.next()">Clone</button>

		<button
			*ngIf="identityFacade.user!.canEdit(entity?.authorUid)"
			type="button"
			mat-menu-item
			(click)="delete$.next()"
		>
			Delete
		</button>
	</mat-menu>
</button>
