import { Subject } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';

import { FADE_IN } from '@bp/shared/animations';
import type { FirebaseEntity } from '@bp/shared/models/metadata';

import type { Identity } from '@bp/backoffice/domains/identity';
import { IdentityFacade } from '@bp/backoffice/domains/identity';

@Component({
	selector: 'bp-right-drawer-header-buttons',
	templateUrl: './right-drawer-header-buttons.component.html',
	styleUrls: [ './right-drawer-header-buttons.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class RightDrawerHeaderButtonsComponent {

	@Input() entity!: FirebaseEntity | null;

	@Input() canSave!: boolean | null;

	@Output('delete') readonly delete$ = new Subject<void>();

	get identity(): Identity {
		return this.identityFacade.user!;
	}

	constructor(public identityFacade: IdentityFacade) { }

}
