export const PRIVATE_EMBEDDED_PARAM_NAME_PREFIX = '$$';

export const PRIVATE_EMBEDDED_PARAM_DATASET_PROPERTY_NAME_PREFIX = '__';

export function mapPrivateEmbeddedParamNameToDatasetPropertyName(key: string): string {
	return key.replace(
		PRIVATE_EMBEDDED_PARAM_NAME_PREFIX,
		PRIVATE_EMBEDDED_PARAM_DATASET_PROPERTY_NAME_PREFIX,
	);
}

export function mapDatasetPropertyNameToPrivateEmbeddedParamName(key: string): string {
	return key.replace(
		PRIVATE_EMBEDDED_PARAM_DATASET_PROPERTY_NAME_PREFIX,
		`${ PRIVATE_EMBEDDED_PARAM_NAME_PREFIX }${ PRIVATE_EMBEDDED_PARAM_NAME_PREFIX }`, // $ in a replace value denotes special replacement patterns, so $$$$ => $$
	);
}
