<mat-form-field
	[color]="color"
	[appearance]="_matAppearance"
	[hideRequiredMarker]="_defaultOptions?.hideRequiredMarker"
	[floatLabel]="_floatLabel"
>
	<mat-label>{{ label | bpTranslate | async }}</mat-label>
	<input
		matInput
		[attr.name]="name"
		[matDatepicker]="picker"
		[formControl]="_internalControl"
		[placeholder]="$any(placeholder | bpTranslate | async)"
		[required]="!!required"
		[min]="min"
		[max]="max"
		(click)="picker.open()"
		(dateChange)="set($event.value)"
	/>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText"> </mat-error>

	<mat-hint *ngIf="hint">
		{{ hint | bpTranslate | async }}
	</mat-hint>

	<div class="clear-button-wrapper">
		<div [class.show]="!!_internalControl.value">
			<button
				type="button"
				mat-icon-button
				[disabled]="_internalControl.disabled"
				(click)="_eraseInternalControlValue()"
			>
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
</mat-form-field>

<mat-datepicker
	#picker
	[calendarHeaderComponent]="DatepickerCalendarHeaderComponent"
	[startAt]="startAt"
	[startView]="startView ?? 'month'"
>
</mat-datepicker>
