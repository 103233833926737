/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { get } from 'lodash-es';
import { Observable } from 'rxjs';

import type { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';

import type { MetaReducer } from '@ngrx/store';

import type { Dictionary, IEnvironment } from '@bp/shared/typings';

import { ConsoleLoggingState, LocalBackendState } from '../state-keepers';

import type { IReporter } from './reporter.interface';
import { TelemetryReporter } from './telemetry.reporter';

@Injectable({
	providedIn: 'root',
})
export class TelemetryService implements IReporter {

	static logToReporterMetaReducer: MetaReducer | null;

	static isRenderingHeadlessly = get(window, 'ScullyIO') === 'running';

	private static _instance?: TelemetryService;

	private static _reporter?: IReporter;

	static init(environment: IEnvironment): void {
		if (this.isRenderingHeadlessly || LocalBackendState.isActive)
			return;

		this._reporter = new TelemetryReporter(environment);

		this.logToReporterMetaReducer = this._reporter.logMetaReducer ?? null;
	}

	static captureError(error: Error | any, source: string, payload?: any): void {
		if (ConsoleLoggingState.isActive)
			console.error(error, source, payload);
		else {
			payload && TelemetryService.warn('error-payload', payload);

			TelemetryService._reporter?.captureError(error, source);
		}
	}

	static captureMessage(message: string, payload?: any): void {
		message = `[log]: ${ message }`;

		if (ConsoleLoggingState.isActive)
			console.warn(message, payload);
		else {
			payload && TelemetryService.log(message, payload);

			TelemetryService._reporter?.captureMessage(message);
		}
	}

	static routerErrorHandler(this: void, error: any): void {
		TelemetryService.captureError(error, 'router');
	}

	static appErrorHandler(this: void, error: any): void {
		TelemetryService.captureError(error, 'app');
	}

	static log(message?: any, ...optionalParams: any[]): void {
		if (ConsoleLoggingState.isActive)
			console.warn(message, ...optionalParams);
		else
			TelemetryService._reporter?.log(message, ...optionalParams);
	}

	static warn(message?: any, ...optionalParams: any[]): void {
		if (ConsoleLoggingState.isActive)
			console.warn(message, ...optionalParams);
		else
			TelemetryService._reporter?.warn(message, ...optionalParams);
	}

	static track(key: string, value: any): void {
		if (ConsoleLoggingState.isActive)
			return;

		TelemetryService._reporter?.track(key, value);
	}

	private readonly _reporter = TelemetryService._reporter;

	logMetaReducer = this._reporter?.logMetaReducer ?? null;

	userSessionRecordingUrl$?: Observable<string> | undefined = this._reporter?.userSessionRecordingUrl$;

	constructor() {
		if (TelemetryService._instance)
			return TelemetryService._instance;

		return (TelemetryService._instance = this);
	}

	identifyUser(userId: string, userTraits?: Dictionary<boolean | number | string | null | undefined>): void {
		this._reporter?.identifyUser(userId, userTraits);
	}

	captureError(error: any, payload?: any): void {
		TelemetryService.captureError(error, 'manual', payload);
	}

	captureMessage(message: string, payload?: any): void {
		TelemetryService.captureMessage(message, payload);
	}

	log(message?: any, ...optionalParams: any[]): void {
		TelemetryService.log(message, ...optionalParams);
	}

	warn(message?: any, ...optionalParams: any[]): void {
		TelemetryService.warn(message, ...optionalParams);
	}

	track(key: string, value: any): void {
		TelemetryService.track(key, value);
	}

}

export class AppErrorHandler implements ErrorHandler {
	handleError(error: any): void {
		TelemetryService.appErrorHandler(error);
	}
}
