/* eslint-disable @typescript-eslint/promise-function-async */
import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { identityHasAccessGuardConfig, IdentityHasAccessGuard } from '@bp/shared-domains-identity';

import { BackofficeFeature } from '@bp/backoffice/domains/users';

const routes: Routes = [
	{
		path: 'cashier-demostand',
		canActivate: [ IdentityHasAccessGuard ],
		runGuardsAndResolvers: 'always',
		data: {
			...identityHasAccessGuardConfig(BackofficeFeature.cashierDemostand),
		},
		children: [
			{
				path: 'demostand',
				loadChildren: () => import('./sections/demostand').then(m => m.DemostandModule),
			},
			{
				path: 'presets',
				loadChildren: () => import('./sections/presets').then(m => m.PresetsModule),
			},
			{
				path: 'credit-cards',
				loadChildren: () => import('./sections/credit-cards').then(m => m.CreditCardsModule),
			},
		],
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class CashierDemostandRoutingModule {}
