import { CommonFeatureActions, EditAndAccessFeatureActions, Feature } from '@bp/shared-domains-identity';

export class BackofficeFeature<T extends CommonFeatureActions = CommonFeatureActions> extends Feature<T> {
	static cashierDemostand = new BackofficeFeature(CommonFeatureActions);

	static careers = new BackofficeFeature(EditAndAccessFeatureActions);

	static bridgerEvents = new BackofficeFeature(EditAndAccessFeatureActions);

	static newsroom = new BackofficeFeature(EditAndAccessFeatureActions);

	static users = new BackofficeFeature(EditAndAccessFeatureActions);

	// Used for MA v1
	static bridgerPsps = new BackofficeFeature(EditAndAccessFeatureActions);

	static bridgerPspSystemDetails = new BackofficeFeature(EditAndAccessFeatureActions);
}
