import { map, switchMap, takeUntil } from 'rxjs/operators';
import type { Observable } from 'rxjs';

import { createEffect, ofType } from '@ngrx/effects';

import type { IPageQueryParams, RecordsPage } from '@bp/shared/models/common';
import { apiResult } from '@bp/shared/models/common';
import type { FirebaseEntity, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { debounceTimeAfterFirst } from '@bp/shared/rxjs';

import { EntitiesListBaseEffects } from './entities-list-base.effects';
import type { EntitiesListFacade } from './entities-list.facade';

export abstract class FirebaseEntitiesListEffects<
	TEntity extends FirebaseEntity,
	TLoadQueryParams extends IPageQueryParams,
	TEntitiesFacade extends EntitiesListFacade<TEntity, TLoadQueryParams>
>
	extends EntitiesListBaseEffects<TEntity, TLoadQueryParams, TEntitiesFacade, IEntitiesFirebaseApiService<TEntity, TLoadQueryParams>> {

	loadOnLoadQueryParamsChange$ = createEffect(() => this.apiQueryParamsWithPage$.pipe(
		map(query => this.actions.load({ query })),
	));

	listenToQueriedRecordsPageChanges$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.load),
		debounceTimeAfterFirst(this.debounceDueTime),
		switchMap(({ query }) => this._listenToQueriedRecordsPageChanges$(query)
			.pipe(
				apiResult(this.actions.api.loadSuccess, this.actions.api.loadFailure),
				takeUntil(this.routeComponentDeactivation$),
			)),
	));

	protected _listenToQueriedRecordsPageChanges$(query?: TLoadQueryParams): Observable<RecordsPage<TEntity>> {
		return this._apiService.listenToQueriedRecordsPageChanges(query);
	}

}
