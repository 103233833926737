import { without } from 'lodash-es';
import type { CountryCode as PhoneFormatterCountryCode } from 'libphonenumber-js';
import { AsYouType as PhoneFormatter } from 'libphonenumber-js';

import type { OnInit } from '@angular/core';
import {
	Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, EventEmitter, Output
} from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import type { MatOptionSelectionChange } from '@angular/material/core';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import type { CountryCode } from '@bp/shared/models/countries';
import { Country, Countries } from '@bp/shared/models/countries';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import type { TextMask } from '@bp/shared/features/text-mask';
import { TextMaskConfig } from '@bp/shared/features/text-mask';
import { isEmpty, uuid } from '@bp/shared/utilities';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

import { InputComponent } from '../input';

@Component({
	selector: 'bp-phone-input',
	templateUrl: './phone-input.component.html',
	styleUrls: [ './phone-input.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_LIST_STAGGERED ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: PhoneInputComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: PhoneInputComponent,
			multi: true,
		},
	],
})
export class PhoneInputComponent extends FormFieldControlComponent<string | null> implements OnChanges, OnInit {

	@Input() initialDialCodeCountry!: Country;

	@Output() readonly dialCodeChange = new EventEmitter<string>();

	@ViewChild(InputComponent, { static: true }) input!: InputComponent;

	@ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger!: MatAutocompleteTrigger;

	@ViewChild('dialCodeCountryInput', { static: true }) dialCodeCountryInputRef!: ElementRef<HTMLInputElement>;

	dialCodeCountry!: Country;

	countries = without(Countries.list, Countries.worldwide);

	filteredCountries: Country[] = this.countries;

	dialCodeCountrySearchControl = new UntypedFormControl();

	phoneNumberMask!: TextMaskConfig;

	valueToDisableNativeAutocomplete = `value-to-disable-native-autocomplete-${ uuid() }`;

	private _phoneFormatter!: PhoneFormatter;

	constructor() {
		super();

		this._onCountrySearchTryFindCountryAndFilterCountries();
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		const { initialDialCodeCountry } = changes;

		if (initialDialCodeCountry?.currentValue)
			this._setCountryAndPhoneFormatting(this.initialDialCodeCountry);
	}

	override ngOnInit(): void {
		super.ngOnInit();

		if (!(this.initialDialCodeCountry instanceof Country))
		 	throw new Error('Initial dial code country must be set for the phone input component');
	}

	focus(): void {
		this.input.focus();
	}

	onSelectionChange(event: MatOptionSelectionChange, country: Country): void {
		if (!event.isUserInput)
			return;

		this._setCountryAndPhoneFormatting(country);

		this._internalControl.updateValueAndValidity();

		setTimeout(() => void this.focus());
	}

	private _setCountryAndPhoneFormatting(country: Country): void {
		this.dialCodeCountry = country;

		this.dialCodeChange.emit(country.internationalDialCodePrefix);

		this.phoneNumberMask = new TextMaskConfig({
			placeholderChar: TextMaskConfig.whitespace,
			placeholderFromMask: true,
			guide: false,
			prefix: this.dialCodeCountry.internationalDialCodePrefix,
			mask: (rawValue: string) => this._buildPhoneMask(rawValue),
		});

		this._phoneFormatter = new PhoneFormatter(<PhoneFormatterCountryCode> this.dialCodeCountry.code);
	}

	protected override _onInternalControlValueChange(value: string | null): void {
		if (isEmpty(value) || value === this.dialCodeCountry.internationalDialCodePrefix) {
			this.setValue(null);

			return;
		}

		// on autofill the dialCode will be present on the value
		const phoneNumberWithDialCode = value.startsWith(this.dialCodeCountry.internationalDialCodePrefix)
			? value
			: `${ this.dialCodeCountry.internationalDialCodePrefix }${ value }`;

		this.setValue(phoneNumberWithDialCode);
	}

	private _buildPhoneMask(rawValue: string): TextMask {
		if (isEmpty(rawValue))
			return [ null ];

		this._phoneFormatter.reset();

		this._phoneFormatter.input(rawValue);

		const guessedCountryCode = this._phoneFormatter.getCountry();

		if (guessedCountryCode && guessedCountryCode !== this.dialCodeCountry.code) {
			 this._onDifferentCountryDuringMaskBuildingUpdateCurrentCountry(
				Countries.get(<CountryCode>guessedCountryCode),
				rawValue,
			);

			return [ null ];
		}

		return this._buildPhoneMaskBasedOnPhoneNumberLibTemplate(rawValue);
	}

	private _buildPhoneMaskBasedOnPhoneNumberLibTemplate(rawValue: string): TextMask {
		const phoneMask = [ ...this._getPhoneTemplateWithoutDialCodeTemplate(rawValue) ]
			.map(char => char === 'x' ? /\d/u : char);

		if (phoneMask[0] !== ' ')
			phoneMask.unshift(' ');

		return phoneMask;
	}

	private _getPhoneTemplateWithoutDialCodeTemplate(rawValue: string): string {
		let phoneTemplate = this._phoneFormatter.getTemplate();

		if (rawValue.startsWith(this.dialCodeCountry.internationalDialCodePrefix))
			phoneTemplate = phoneTemplate.slice(this.dialCodeCountry.internationalDialCodePrefix.length);

		return phoneTemplate;
	}

	private _onDifferentCountryDuringMaskBuildingUpdateCurrentCountry(
		country: Country,
		rawValue: string,
	): void {
		this._setCountryAndPhoneFormatting(country);

		setTimeout(() => void this._internalControl.setValue(rawValue), 5);
	}

	private _onCountrySearchTryFindCountryAndFilterCountries(): void {
		this.dialCodeCountrySearchControl.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(countrySearchInput => void this._filterCountries(countrySearchInput));
	}

	private _filterCountries(countrySearchInput?: string | null): void {
		const loweredSearch = countrySearchInput?.toLowerCase();

		this.filteredCountries = loweredSearch
			? this.countries.filter(
				it => !!it.lowerCaseName?.includes(loweredSearch) || it.lowerCaseCode === loweredSearch,
			)
			: this.countries;
	}
}
