import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SLIDE_UP } from '@bp/shared/animations';

import { IdentityFacade } from '@bp/backoffice/domains/identity';

@Component({
	selector: 'bp-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: [ './login-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_UP ],
	host: {
		class: 'intro-shell-page',
	},
})
export class LoginPageComponent {

	constructor(public identityFacade: IdentityFacade) { }

}
