<bp-entities-table-card [facade]="presetsFacade">
	<table
		*bpDelayedRender
		class="page-table"
		mat-table
		[dataSource]="(presetsFacade.records$ | async)!"
		[trackBy]="trackById"
	>
		<ng-container matColumnDef="menu">
			<td *matCellDef="let row" mat-cell>
				<button mat-icon-button [matMenuTriggerFor]="rowMenu" (click)="$event.stopPropagation()">
					<mat-icon>more_vert</mat-icon>

					<mat-menu #rowMenu="matMenu" class="row-menu">
						<a
							mat-menu-item
							[outletLinkRelativeToRootFirstChild]="{ primary: ['demostand', { preset: row.id }] }"
						>
							Launch
						</a>

						<a
							*ngIf="identityFacade.user?.canEdit(row.authorUid)"
							mat-menu-item
							[outletLinkRelativeToParent]="{
								'[right-drawer][1]': [
									'preset',
									{
										id: row.id,
										drawerType: 'edit',
										formId: 'cashier-details-form'
									}
								]
							}"
						>
							Edit
						</a>

						<a
							mat-menu-item
							[outletLinkRelativeToParent]="{
								'[right-drawer][1]': ['preset', { id: row.id, drawerType: 'clone' }]
							}"
						>
							Clone
						</a>

						<button
							*ngIf="!row.jiraIssueKey && identityFacade.user?.canEdit(row.authorUid)"
							type="button"
							mat-menu-item
							[bpTooltip]="metadata.properties.isShared.hint"
							(click)="row.isShared ? presetFacade.unshare(row) : presetFacade.share(row)"
						>
							{{ row.isShared ? 'Unshare' : 'Share' }}
						</button>

						<button
							*ngIf="identityFacade.user?.canEdit(row.authorUid)"
							type="button"
							mat-menu-item
							(click)="presetFacade.delete(row)"
						>
							Delete
						</button>
					</mat-menu>
				</button>
			</td>
		</ng-container>

		<ng-container matColumnDef="mountBtn">
			<th *matHeaderCellDef mat-header-cell colspan="2"></th>
			<td *matCellDef="let row" mat-cell>
				<a
					mat-icon-button
					bpTooltip="Launch the cashier"
					[outletLinkRelativeToRootFirstChild]="{ primary: ['demostand', { preset: row.id }] }"
				>
					<bp-svg-icon name="rocket" class="bp-icon-md"></bp-svg-icon>
				</a>
			</td>
		</ng-container>

		<ng-container *ngFor="let md of columnsMetadata$ | async as list; let i = index" [matColumnDef]="md.property">
			<ng-container *ngIf="!md.table!.headless">
				<th *matHeaderCellDef class="nowrap text-center" mat-header-cell>
					{{ md.label }}
				</th>
			</ng-container>

			<td
				*matCellDef="let row"
				mat-cell
				[outletLinkRelativeToParent]="{
					'[right-drawer][1]': ['preset', { id: row.id, drawerType: 'view' }]
				}"
			>
				<ng-container [ngSwitch]="md.property">
					<bp-avatar *ngSwitchCase="'authorUid'" [id]="row[md.property]"> </bp-avatar>

					<ng-container *ngSwitchCase="'name'">
						<bp-jira-issue-name *ngIf="row.jiraIssueKey as key; else nameTpl" [key]="key">
						</bp-jira-issue-name>

						<ng-template #nameTpl>
							{{ row.name }}
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="'creditCardId'">
						<bp-credit-card-name *ngIf="row[md.property] as id; else emptyTpl" [id]="id">
						</bp-credit-card-name>

						<ng-template #emptyTpl> — </ng-template>
					</ng-container>

					<span *ngSwitchCase="'amount'" class="flex">
						{{ row.currency?.symbol }}
						<bp-property-metadata-view [metadata]="md" [table]="md.table" [value]="row[md.property]">
						</bp-property-metadata-view>
					</span>

					<bp-img
						*ngSwitchCase="'environment'"
						thumbnail
						[url]="row.environment.imageUrl"
						[bpTooltip]="row.environment.displayName"
					>
					</bp-img>

					<bp-property-metadata-view
						*ngSwitchDefault
						[metadata]="md"
						[table]="md.table"
						[value]="row[md.property]"
					>
					</bp-property-metadata-view>
				</ng-container>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="(headerColumnsIds$ | async)!" mat-header-row @instantHideOnVoid></tr>

		<tr
			*matRowDef="let row; columns: (displayedColumnsIds$ | async)!!"
			mat-row
			@instantHideOnVoid
			class="clickable"
			[class.active]="(presetFacade.isOpenedDrawer$ | async) && row.id === (presetFacade.entity$ | async)?.id"
		></tr>
	</table>
</bp-entities-table-card>
