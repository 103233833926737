import { ChangeDetectionStrategy, Component } from '@angular/core';

import type { ActionConfirmDialogData } from './action-confirm-dialog-data';
import { ActionConfirmDialogComponent } from './action-confirm-dialog.component';

@Component({
	selector: 'bp-disable-confirm-dialog',
	templateUrl: './action-confirm-dialog.component.html',
	styleUrls: [ './action-confirm-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisableConfirmDialogComponent extends ActionConfirmDialogComponent<ActionConfirmDialogData> {

	protected override _mergeDefaultOptions(data: ActionConfirmDialogData): ActionConfirmDialogData {
		return {
			color: 'primary',
			descriptionText: `This action will disable the <b>${ data.name }</b> ${ data.type }.`,
			confirmButtonText: 'Disable',
			...data,
		};
	}
}
