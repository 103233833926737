import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AppFacade } from '@bp/backoffice/app-state';

@Component({
	selector: 'bp-credit-cards-page',
	templateUrl: './credit-cards-page.component.html',
	styleUrls: [ './credit-cards-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardsPageComponent {

	constructor(public appFacade: AppFacade) { }

}
