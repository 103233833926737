<svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<symbol id="round-google" viewBox="0 0 512 512">
			<path
				d="M113.47 309.408L95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
				fill="#fbbb00"
			/>
			<path
				d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
				fill="#518ef8"
			/>
			<path
				d="M416.253 455.624l.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
				fill="#28b446"
			/>
			<path
				d="M419.404 58.936l-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
				fill="#f14336"
			/>
		</symbol>

		<symbol id="round-github" viewBox="0 0 438.5 438.5">
			<path
				d="M409.1 114.6c-19.6-33.6-46.2-60.2-79.8-79.8-33.6-19.6-70.2-29.4-110-29.4s-76.5 9.8-110.1 29.4C75.6 54.4 49 81 29.4 114.6 9.8 148.2 0 184.9 0 224.6c0 47.8 13.9 90.7 41.8 128.9s63.9 64.6 108.1 79.2c5.1 1 8.9.3 11.4-2s3.7-5.1 3.7-8.6c0-.6 0-5.7-.1-15.4-.1-8.5-.1-16.9-.1-25.4l-6.6 1.1c-4.2.8-9.5 1.1-15.8 1-6.4-.1-13-.8-19.8-2-6.9-1.2-13.2-4.1-19.1-8.6-5.9-4.5-10.1-10.3-12.6-17.6l-2.9-6.6c-1.9-4.4-4.9-9.2-9-14.6-4.1-5.3-8.2-8.9-12.4-10.8l-2-1.4c-1.3-1-2.6-2.1-3.7-3.4-1.1-1.3-2-2.7-2.6-4-.6-1.3-.1-2.4 1.4-3.3S64 310 68 310l5.7.9c3.8.8 8.5 3 14.1 6.9 5.6 3.8 10.2 8.8 13.8 14.8 4.4 7.8 9.7 13.8 15.8 17.8 6.2 4.1 12.4 6.1 18.7 6.1s11.7-.5 16.3-1.4c4.6-1 8.8-2.4 12.8-4.3 1.7-12.8 6.4-22.6 14-29.4-10.8-1.1-20.6-2.9-29.3-5.1-8.7-2.3-17.6-6-26.8-11.1s-16.9-11.5-23-19.1c-6.1-7.6-11.1-17.6-15-30-3.9-12.4-5.9-26.6-5.9-42.8 0-23 7.5-42.6 22.6-58.8-7-17.3-6.4-36.7 2-58.2 5.5-1.7 13.7-.4 24.6 3.9 10.9 4.3 18.8 8 23.8 11s9.1 5.6 12.1 7.7c17.7-4.9 36-7.4 54.8-7.4s37.1 2.5 54.8 7.4l10.8-6.8c7.4-4.6 16.2-8.8 26.3-12.6 10.1-3.8 17.8-4.9 23.1-3.1 8.6 21.5 9.3 40.9 2.3 58.2 15 16.2 22.6 35.8 22.6 58.8 0 16.2-2 30.5-5.9 43-3.9 12.5-8.9 22.5-15.1 30-6.2 7.5-13.9 13.9-23.1 19-9.2 5.1-18.2 8.9-26.8 11.1-8.7 2.3-18.4 4-29.3 5.1 9.9 8.6 14.8 22.1 14.8 40.5v60.2c0 3.4 1.2 6.3 3.6 8.6 2.4 2.3 6.1 3 11.3 2 44.2-14.7 80.2-41.1 108.1-79.2 27.9-38.2 41.8-81.1 41.8-128.9.1-39.9-9.7-76.6-29.3-110.2z"
			/>
		</symbol>
	</defs>
</svg>
