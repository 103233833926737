import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';
import type { FirebasePsp } from '@bp/shared/models/firebase-psps';

import { loadFailure, loadSuccess } from './firebase-psps-shared-api.actions';
import { load, update } from './firebase-psps-shared.actions';

export const FEATURE_KEY = '[domain]:firebase-psps-shared';

export interface IState {

	all: FirebasePsp[] | null;

	pending: boolean;

	error: BpError | null;

}

export const initialState: IState = {
	all: null,
	pending: false,
	error: null,
};

const providersReducer = createReducer(

	initialState,

	on(update, (state, { psps: providers }): IState => ({
		...state,
		all: providers,
	})),

	on(load, (state): IState => ({
		...state,
		pending: true,
	})),

	on(loadSuccess, (state, { result }): IState => ({
		...state,
		all: result,
		pending: false,
		error: null,
	})),

	on(loadFailure, (state, { error }): IState => ({
		...state,
		error,
		pending: false,
	})),

);

export function reducer(state: IState | undefined, action: Action): IState {
	return providersReducer(state, action);
}
