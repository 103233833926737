import { Feature } from '../feature';
import { CommonFeatureActions, EditAndAccessFeatureActions } from '../feature-actions';

import {
	MembersFeatureActions,
	PayoutsFeatureActions,
	ReportsFeatureActions,
	TransactionsFeatureActions
} from './merchant-admin-feature-actions';

export class MerchantAdminFeature<T extends CommonFeatureActions = CommonFeatureActions> extends Feature<T> {
	static reports = new MerchantAdminFeature(ReportsFeatureActions);

	static transactions = new MerchantAdminFeature(TransactionsFeatureActions);

	static payouts = new MerchantAdminFeature(PayoutsFeatureActions);

	static cashiers = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static creditCardTokens = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static paymentRoutes = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static psps = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static members = new MerchantAdminFeature(MembersFeatureActions);

	static dashboard = new MerchantAdminFeature(CommonFeatureActions);

	static changePassword = new MerchantAdminFeature(CommonFeatureActions);

	static virtualTerminal = new MerchantAdminFeature(CommonFeatureActions);

	static subscriptionsManagement = new MerchantAdminFeature(CommonFeatureActions);

	static enterpriseSubscriptionCheckout = new MerchantAdminFeature(CommonFeatureActions);

	static pspCredentials = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static paymentRouteRules = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static paymentRouteCredentials = new MerchantAdminFeature(EditAndAccessFeatureActions);

	// #region SECTION User Workflows

	static otpVerify = new MerchantAdminFeature(CommonFeatureActions);

	// #region SECTION Signup Via Link Workflow

	static acceptInvite = new MerchantAdminFeature(CommonFeatureActions);

	static createAccount = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !SECTION

	// #region deprecated, remove after accepting invite implementation by backend

	static confirmationEmail = new MerchantAdminFeature(CommonFeatureActions);

	static setPasswordOnLogin = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !deprecated

	// #region SECTION Continue Signup

	static setSecurityQuestionsAnswers = new MerchantAdminFeature(CommonFeatureActions);

	static registerAuthenticator = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !SECTION

	// #region SECTION Reset Password

	static verifyAnswerBeforeResetPassword = new MerchantAdminFeature(CommonFeatureActions);

	static otpResetPasswordVerification = new MerchantAdminFeature(CommonFeatureActions);

	static resetPassword = new MerchantAdminFeature(CommonFeatureActions);

	static resetExpiredPassword = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !SECTION

	// #region SECTION Reset Authenticator App

	static sendResetAuthenticatorLink = new MerchantAdminFeature(CommonFeatureActions);

	static resetAuthenticator = new MerchantAdminFeature(CommonFeatureActions);

	static verifyAnswerBeforeResetAuthenticator = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !SECTION

	// #endregion !SECTION

	// #region SECTION Generated on the fly depending on the merchant level access

	static paywith = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !SECTION

	// #region SECTION Deprecated unused anywhere in the app but sent by the backend

	static bridgerRouter = new MerchantAdminFeature(EditAndAccessFeatureActions);

	static paymentProviders = new MerchantAdminFeature(CommonFeatureActions);

	// #endregion !SECTION

}
