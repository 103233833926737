import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import type { OnInit } from '@angular/core';
import { Directive, EventEmitter, Input, Output } from '@angular/core';

import type { Entity, FirebaseEntity } from '@bp/shared/models/metadata';
import { attrBoolValue } from '@bp/shared/utilities';

import { DrawerType } from '../models';

import { RightDrawerEntityComponent } from './right-drawer-entity';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class RightDrawerEntityHeaderButtonsBaseComponent<T extends Entity | FirebaseEntity> implements OnInit {

	@Input()
	get drawerType(): DrawerType | null | undefined {
		return this._drawerType$.value;
	}

	set drawerType(value: DrawerType | null | undefined) {
		this._drawerType$.next(value);
	}

	private readonly _drawerType$ = new BehaviorSubject<DrawerType | null | undefined>(undefined);

	@Input() hasDisableBtn!: boolean | '';

	private readonly _enableActionIsNotAllowedReason$ = new BehaviorSubject<string | null>(null);

	@Input() set enableActionIsNotAllowedDueTo(reason: string | null) {
		this._enableActionIsNotAllowedReason$.next(reason);
	}

	@Input() hasDeleteBtn!: boolean | '';

	@Input() hasMeatballMenu: boolean | '' = true;

	@Input() useAddLabelInsteadCreate!: boolean | '';

	@Input() enabled?: boolean | null;

	@Input() deleteLabel = 'Delete';

	@Output() readonly delete = new EventEmitter<void>();

	@Output() readonly disable = new EventEmitter<boolean>();

	get isEdit(): boolean {
		return this.drawerType === DrawerType.edit;
	}

	get isAdd(): boolean {
		return this.drawerType === DrawerType.new;
	}

	get isView(): boolean {
		return this.drawerType === DrawerType.view;
	}

	get isClone(): boolean {
		return this.drawerType === DrawerType.clone;
	}

	get isEditLike(): boolean {
		return DrawerType.editLike.includes(this.drawerType!);
	}

	readonly actionIsNotAllowed$ = combineLatest([
		this.drawer.formDirtyAndPending$,
		this._drawerType$,
	])
		.pipe(map(([[ formDirty, pending ]]) => formDirty || pending || this.isAdd));

	readonly actionIsNotAllowedTooltip$ = combineLatest([
		this.drawer.formDirtyAndPending$,
		this._drawerType$,
	])
		.pipe(map(([[ formDirty, pending ]]) => this._buildNotAllowedActionTooltip(pending, formDirty)));

	readonly enableActionIsNotAllowed$ = combineLatest([
		this.actionIsNotAllowed$,
		this._enableActionIsNotAllowedReason$,
	])
		.pipe(map(conditions => conditions.some(v => !!v)));

	readonly enableActionIsNotAllowedTooltip$ = combineLatest([
		this.actionIsNotAllowedTooltip$,
		this._enableActionIsNotAllowedReason$,
	])
		.pipe(map(tooltips => tooltips.find(v => !!v)));

	constructor(public drawer: RightDrawerEntityComponent<T>) { }

	private _buildNotAllowedActionTooltip(pending: boolean, formDirty: boolean): string | null {
		if (pending)
			return 'Please wait, the form is pending';

		if (this.isAdd)
			return 'Please, submit the form first';

		if (formDirty)
			return 'Please, save or discard the form';

		return null;
	}

	ngOnInit(): void {
		this.hasDeleteBtn = attrBoolValue(this.hasDeleteBtn);

		this.hasDisableBtn = attrBoolValue(this.hasDisableBtn);

		this.hasMeatballMenu = attrBoolValue(this.hasMeatballMenu);

		this.useAddLabelInsteadCreate = attrBoolValue(this.useAddLabelInsteadCreate);
	}

}
