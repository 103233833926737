import { combineReducers } from '@ngrx/store';

import * as fromEntity from './entity/credit-card.reducer';
import * as fromList from './list/credit-cards.reducer';

const reducers = {
	entity: fromEntity.reducer,
	list: fromList.reducer,
};

export const FEATURE_KEY = '[section]:credit-cards';

export interface IState {
	entity: fromEntity.IState;
	list: fromList.IState;
}

const creditCardFeatureReducer = combineReducers(reducers);

export function reducer(state: any, action: any) {
	return creditCardFeatureReducer(state, action);
}
