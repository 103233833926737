import { ToastrService } from 'ngx-toastr';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Actions } from '@ngrx/effects';

import { RouterService, StorageService } from '@bp/shared/services';
import type { ActionConfirmDialogData } from '@bp/shared/components/dialogs';

import { EntityEffects } from '@bp/admins-shared/features/entity';

import type { CreditCard } from '../../models';
import { CreditCardPageComponent } from '../../pages/credit-card-page';
import { CreditCardsApiService } from '../../services';

import { CreditCardFacade } from './credit-card.facade';

@Injectable()
export class CreditCardEffects extends EntityEffects<CreditCard> {

	readonly routeComponentType = CreditCardPageComponent;

	constructor(
		actions$: Actions,
		router: RouterService,
		api: CreditCardsApiService,
		facade: CreditCardFacade,
		storageService: StorageService,
		dialog: MatDialog,
		toaster: ToastrService,
	) {
		super(actions$, router, facade, storageService, dialog, toaster, api);
	}

	protected override _buildActionConfirmDialogData = (entity: CreditCard): ActionConfirmDialogData => ({
		name: entity.name,
		type: 'credit card',
	});
}
