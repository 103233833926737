import { createAction, props } from '@ngrx/store';

import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';

import { EntityActionPayload } from '../../models';

import {
	EntitiesListActions, FilteredEntitiesInMemoryActionPayload, FilteredRecordsPageActionPayload, LoadEntitiesActionPayload, QueryParamsChanged
} from './entities.actions';

export function composeEntitiesListActions<TEntity extends Entity | FirebaseEntity, TQueryParams>(
	scope: string,
): Omit<EntitiesListActions<TEntity, TQueryParams>, 'api'> {
	return {
		queryParamsChanged: createAction(
			`${ scope } Query Params Changed`,
			props<QueryParamsChanged<TQueryParams>>(),
		),

		load: createAction(
			`${ scope } Load`,
			props<LoadEntitiesActionPayload<TQueryParams>>(),
		),

		loadAll: createAction(
			`${ scope } Load All`,
		),

		filteredInMemory: createAction(
			`${ scope } Filtered In Memory`,
			props<FilteredEntitiesInMemoryActionPayload<TEntity>>(),
		),

		filteredRecordsPage: createAction(
			`${ scope } Filtered Records Page`,
			props<FilteredRecordsPageActionPayload<TEntity>>(),
		),

		changePage: createAction(
			`${ scope } Change Page`,
			props<{ page?: string }>(),
		),

		refresh: createAction(
			`${ scope } Refresh`,
		),

		resetState: createAction(
			`${ scope } Reset State`,
		),

		updateLocalEntity: createAction(
			`${ scope } Update Local Instance In the Collection If Any`,
			props<EntityActionPayload<TEntity>>(),
		),

	};
}
