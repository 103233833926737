<button
	*ngIf="(entity && !entity!.id) || identity.canEdit(entity?.authorUid)"
	@fadeIn
	type="submit"
	mat-flat-button
	color="primary"
	[disabled]="!canSave"
>
	save
</button>

<button
	*ngIf="identity.canEdit(entity?.authorUid)"
	@fadeIn
	type="button"
	mat-flat-button
	color="primary"
	(click)="delete$.next()"
>
	delete
</button>
