import { Default, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

import { DrawerType } from './drawer-type';

export class DrawerRouteParams extends MetadataEntity {

	@Mapper(DrawerType)
	@Default(DrawerType.view)
	drawerType!: DrawerType;

	@Default(null)
	id!: string | null;

	@Default(null)
	formId!: string | null;

}
