<bp-autocomplete
	appearance="standard"
	[pending]="jira.pending$ | async"
	[value]="name"
	[items]="filteredIssues$ | async"
	[placeholder]="namePlaceholder"
	[required]="true"
	[hideErrorText]="true"
	(valueChange)="jiraIssueKeyChange.emit($event?.key); jiraIssueKey = $event?.key"
	(inputChange)="onAutocompleteInputChange($event)"
>
</bp-autocomplete>

<a
	*ngIf="jiraIssueKey"
	@fade
	bpTargetBlank
	class="jira-link"
	href="https://bridgerpay.atlassian.net/browse/{{ jiraIssueKey }}"
>
	<bp-svg-icon name="jira" bpTooltip="{{ jiraIssueKey }} issue is connected"> </bp-svg-icon>
</a>

<ng-content></ng-content>
