import { createReducer } from '@ngrx/store';

import type { EntityDrawerState } from '@bp/admins-shared/features/entity';
import { composeEntityDrawerReducer, ENTITY_DRAWER_INITIAL_STATE } from '@bp/admins-shared/features/entity';

import type { Preset } from '../../models';

import { PRESET_DRAWER_ACTIONS } from './preset.actions';

export const NEW_PRESET = 'new-preset';

export interface IState extends EntityDrawerState<Preset> {

}

export const initialState: IState = {

	...ENTITY_DRAWER_INITIAL_STATE,

};

export const reducer = createReducer(
	initialState,

	...composeEntityDrawerReducer(initialState, PRESET_DRAWER_ACTIONS),

);
