/* eslint-disable @typescript-eslint/member-ordering */
import { without } from 'lodash-es';

import { Enumeration } from '@bp/shared/models/core/enum';
import { isInstanceOf, bpQueueMicrotask } from '@bp/shared/utilities';

import type { FeatureAction } from '@bp/shared-domains-identity';

import { BackofficeFeature } from './backoffice-feature';

export class BackofficeUserRole extends Enumeration {
	static careersManager = new BackofficeUserRole(() => [
		BackofficeFeature.careers,
		BackofficeFeature.careers.actions.edit,
	]);

	static newsroomManager = new BackofficeUserRole(() => [
		BackofficeFeature.newsroom,
		BackofficeFeature.newsroom.actions.edit,
	]);

	static eventsManager = new BackofficeUserRole(() => [
		BackofficeFeature.bridgerEvents,
		BackofficeFeature.bridgerEvents.actions.edit,
	]);

	static cashierDemostand = new BackofficeUserRole(() => [ BackofficeFeature.cashierDemostand ]);

	static usersManager = new BackofficeUserRole(() => [ BackofficeFeature.users, BackofficeFeature.users.actions.edit ]);

	// Used for MA v1
	static pspsManager = new BackofficeUserRole(() => [
		BackofficeFeature.bridgerPsps,
		BackofficeFeature.bridgerPsps.actions.edit,
		BackofficeFeature.bridgerPspSystemDetails,
		BackofficeFeature.bridgerPspSystemDetails.actions.edit,
	]);

	// Used for MA v1
	static pspsPublicInfoManager = new BackofficeUserRole(() => [
		BackofficeFeature.bridgerPsps,
		BackofficeFeature.bridgerPsps.actions.edit,
	]);

	static administrator: BackofficeUserRole;

	static almighty: BackofficeUserRole;

	static readonly: BackofficeUserRole;

	static {
		BackofficeUserRole.almighty = new BackofficeUserRole(() => BackofficeUserRole.getList().flatMap(role => role.permissions));

		BackofficeUserRole.administrator = new BackofficeUserRole(() => without(BackofficeUserRole.getList(), BackofficeUserRole.almighty).flatMap(role => role.permissions));

		BackofficeUserRole.readonly = new BackofficeUserRole(() => BackofficeUserRole.getList().flatMap(role => role.permissions.filter(isInstanceOf(BackofficeFeature))));
	}

	readonly permissions: (BackofficeFeature | FeatureAction)[] = [];

	constructor(deferredPermissions: () => (BackofficeFeature | FeatureAction)[] = () => []) {
		super();

		// we schedule the permissions initialization to the end of the event loop because
		// since they are not yet initialized at the construction time
		// @ts-expect-error the prop is readonly in nature
		bpQueueMicrotask(() => (this.permissions = deferredPermissions()));
	}
}
