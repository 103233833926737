import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FADE } from '@bp/shared/animations';

@Component({
	selector: 'bp-splash-screen',
	templateUrl: './splash-screen.component.html',
	styleUrls: [ './splash-screen.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class SplashScreenComponent {

	@Input() show!: boolean | null;

}
