import { Directive, forwardRef, ViewChild } from '@angular/core';

import { Destroyable } from '@bp/shared/models/common';
import type { Entity, FirebaseEntity } from '@bp/shared/models/metadata';

import { RightDrawerEntityComponent } from '../components';

import { DrawerType } from './drawer-type';

@Directive()
export abstract class RightDrawerEntityHostPageComponent<T extends Entity | FirebaseEntity> extends Destroyable {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	DrawerType = DrawerType;

	// eslint-disable-next-line @angular-eslint/no-forward-ref
	@ViewChild(forwardRef(() => RightDrawerEntityComponent)) rightDrawerEntityComponent?: RightDrawerEntityComponent<T>;

	switchToCloneDrawer(): void {
		this.rightDrawerEntityComponent?.changeDrawerRouteParams(DrawerType.clone);
	}
}
