<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<!-- eslint-disable @angular-eslint/template/conditional-complexity -->

<div
	bpTooltipShownOnViewportOverflow
	[class.feature-under-development]="metadata.underDevelopment"
	[bpTooltip]="tooltip ? tooltip : showGeneralTooltip ? (value | bpDisplayValue) : null"
>
	<ng-container
		*ngIf="
			viewType !== FieldViewType.boolean || !!customValueView || !!customValueTemplate;
			else booleanViewTypeTpl
		"
	>
		<caption *ngIf="showLabel && !table" class="card-property-view-caption">
			{{
				metadata.label
			}}

			<bp-hint *ngIf="metadata.longHint" class="ml-1" size="sm" [msg]="metadata.longHint"></bp-hint>

			<bp-copy *ngIf="metadata.copyable" class="ml-1" size="sm" [outlined]="true" [value]="value"> </bp-copy>
		</caption>

		<div *ngIf="value === '' || value === undefined || value === null || value.length === 0; else valueTpl">
			<div *ngIf="viewType === FieldViewType.thumbnail; else emptyValueTpl" class="thumbnail empty">
				<mat-icon>image</mat-icon>
			</div>

			<ng-template #emptyValueTpl>
				{{ viewEmptyValue ? viewEmptyValue : '—' }}
			</ng-template>
		</div>

		<ng-template #valueTpl>
			<ng-template
				*ngIf="!!customValueView || !!customValueTemplate; else defaultValueTpl"
				[ngTemplateOutlet]="customValueView?.tpl || customValueTemplate!"
				[ngTemplateOutletContext]="{ $implicit: value, metadata: metadata }"
			>
			</ng-template>

			<ng-template #defaultValueTpl>
				<div class="text-ellipsis" [ngSwitch]="viewType">
					<span *ngIf="showGeneralTooltip" bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
						<span *ngSwitchCase="FieldViewType.text">
							{{ formattedValue }}
						</span>

						<span *ngSwitchCase="FieldViewType.textarea">
							{{ value }}
						</span>

						<span *ngSwitchCase="FieldViewType.currency">
							{{ viewFormatter ? formattedValue : (value | bpCurrency) }}
						</span>

						<span *ngSwitchCase="FieldViewType.percent">
							{{ value | percent: '0.0-2' }}
						</span>

						<span *ngSwitchCase="FieldViewType.currencyCode">
							{{ value.symbol === value.code ? '' : value.symbol }} {{ value.code }}
						</span>
					</span>

					<ng-container *ngSwitchCase="FieldViewType.email">
						<a class="link" bpTargetBlank href="mailto:{{ value }}" (click)="$event.stopPropagation()">
							<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
								{{ value }}
							</span>
						</a>
					</ng-container>

					<ng-container *ngSwitchCase="FieldViewType.link">
						<a class="link" bpTargetBlank [href]="formattedValue" (click)="$event.stopPropagation()">
							<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
								{{ value }}
							</span>
						</a>
					</ng-container>

					<bp-country *ngSwitchCase="FieldViewType.country" [src]="value" [compact]="isCompact"> </bp-country>

					<bp-payment-card-brand *ngSwitchCase="FieldViewType.paymentOptionBrand" [src]="value">
					</bp-payment-card-brand>

					<ng-container *ngSwitchCase="FieldViewType.moment">
						{{ value | momentFormat: (viewFormatter ? viewFormatter(value) : defaultMomentFormat) | async }}
					</ng-container>

					<span
						*ngSwitchCase="FieldViewType.momentFromNow"
						bpTooltip="{{ value | momentFormat: 'LLL' | async }}"
					>
						{{ value | momentFromNow | async }}
					</span>

					<div *ngSwitchCase="FieldViewType.momentSplitIntoTwoRows" class="text-center">
						{{ value | momentFormat: 'L' | async }}
						<br />
						{{ value | momentFormat: 'LTS' | async }}
					</div>

					<ng-container *ngSwitchCase="FieldViewType.status">
						<span
							[ngClass]="'status-' + value.cssClass"
							[class.mr-1]="!isCompact"
							[class.circle-sm]="!isCompact"
							[class.circle]="isCompact"
							[bpTooltip]="isCompact ? value.displayName : null"
						>
						</span>
						<ng-container *ngIf="!isCompact">
							{{ value.displayName }}
						</ng-container>
					</ng-container>

					<ng-container *ngSwitchCase="FieldViewType.booleanCircle">
						<ng-container *ngIf="formattedValue; else defaultBooleanCircleViewTpl">
							<ng-container
								*ngIf="isEnumeration(formattedValue); else formattedValueBooleanCircleViewTpl"
							>
								<span
									[ngClass]="'status-' + formattedValue.cssClass"
									[class.mr-1]="!isCompact"
									[class.circle-sm]="!isCompact"
									[class.circle]="isCompact"
									[bpTooltip]="isCompact ? formattedValue.displayName : null"
								>
								</span>
								<ng-container *ngIf="!isCompact">
									{{ formattedValue.displayName }}
								</ng-container>
							</ng-container>

							<ng-template #formattedValueBooleanCircleViewTpl>
								<span
									[ngClass]="'status-' + value"
									[class.mr-1]="!isCompact"
									[class.circle-sm]="!isCompact"
									[class.circle]="isCompact"
									[bpTooltip]="isCompact ? formattedValue : null"
								>
								</span>
								<ng-container *ngIf="!isCompact">
									{{ formattedValue }}
								</ng-container>
							</ng-template>
						</ng-container>
						<ng-template #defaultBooleanCircleViewTpl>
							<span class="circle status-{{ value }}"> </span>
						</ng-template>
					</ng-container>

					<ng-container *ngSwitchCase="FieldViewType.chip">
						<mat-chip-list *ngIf="value?.length" [selectable]="false">
							<mat-chip *ngFor="let chip of value" selected [bpTooltip]="chip.description">
								{{ chip.displayName || chip.name || chip }}
							</mat-chip>
						</mat-chip-list>
					</ng-container>

					<bp-img *ngSwitchCase="FieldViewType.thumbnail" thumbnail [size]="40" [url]="value"> </bp-img>

					<bp-img *ngSwitchCase="FieldViewType.image" [url]="value"> </bp-img>
				</div>
			</ng-template>
		</ng-template>
	</ng-container>

	<ng-template #booleanViewTypeTpl>
		<mat-icon *ngIf="table; else defaultBooleanViewTypeTpl" [ngClass]="booleanIcon">
			{{ booleanIcon }}
		</mat-icon>

		<ng-template #defaultBooleanViewTypeTpl>
			<span [ngClass]="booleanIcon">
				<mat-icon class="mr-1">{{ booleanIcon }}</mat-icon>
				{{ metadata.label }}
				<bp-hint *ngIf="metadata.hint" class="ml-1" [msg]="metadata.hint"></bp-hint>
			</span>
		</ng-template>
	</ng-template>
</div>
