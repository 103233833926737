import { BehaviorSubject, of, switchMap } from 'rxjs';

import { ChangeDetectionStrategy, Component, ContentChild, Directive, Input } from '@angular/core';

import { FADE, SLIDE_IN } from '@bp/shared/animations';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

import { EntitiesInMemoryPagedListFacade, EntitiesListFacade } from '../../state';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'bp-entities-table-card-no-results',
})
export class EntitiesTableCardNoResultsDirective { }

@Component({
	selector: 'bp-entities-table-card',
	templateUrl: './entities-table-card.component.html',
	styleUrls: [ './entities-table-card.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, SLIDE_IN ],
})
export class EntitiesTableCardComponent implements OnChanges {

	@Input() facade!: EntitiesInMemoryPagedListFacade<any, any> | EntitiesListFacade<any, any>;

	@Input() showPaginator = true;

	/** User-supplied override of the no results element. */
	@ContentChild(EntitiesTableCardNoResultsDirective)
	noResultsTpl!: EntitiesTableCardNoResultsDirective;

	private readonly _facade$ = new BehaviorSubject<typeof this.facade | null>(null);

	totalItemsCount$ = this._facade$.pipe(
		switchMap(facade => facade instanceof EntitiesInMemoryPagedListFacade
			? facade.filteredInMemoryLength$
			: of(null)),
	);

	ngOnChanges({ facade }: SimpleChanges<EntitiesTableCardComponent>): void {
		if (facade)
			this._facade$.next(this.facade);
	}

}
