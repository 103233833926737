import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FADE, SLIDE } from '@bp/shared/animations';

import { ImgUploadBtnComponent } from '../img-upload-btn';

@Component({
	selector: 'bp-file-upload-btn',
	templateUrl: './file-upload-btn.component.html',
	styleUrls: [ './file-upload-btn.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, FADE ],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: FileUploadBtnComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: FileUploadBtnComponent,
			multi: true,
		},
	],
})
export class FileUploadBtnComponent extends ImgUploadBtnComponent {

	uploadFile(): void {
		this.filePicker.click();
	}

	override onClick(): void {
		// Does nothing
	}

}
