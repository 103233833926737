import { sortBy } from 'lodash-es';
import { map } from 'rxjs/operators';
import * as firestore from 'firebase/firestore';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import type { DTO, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { FirebaseService } from '@bp/shared/services';
import { assertIsQueryParamsBased } from '@bp/shared/models/common';
import type { FirebasePspKeys } from '@bp/shared/models/firebase-psps';
import { FirebasePsp } from '@bp/shared/models/firebase-psps';

import type { FirebasePspsQueryParams } from '../models';

@Injectable({
	providedIn: 'root',
})
export class FirebasePspsApiService implements IEntitiesFirebaseApiService<FirebasePsp, FirebasePspsQueryParams> {

	readonly collectionPath = 'admin-payment-providers';

	constructor(private readonly _firebaseService: FirebaseService) {

	}

	readonly factory = (v: DTO<FirebasePsp> | FirebasePsp) => v instanceof FirebasePsp
		? v
		: new FirebasePsp(v);

	listenToQueriedRecordsPageChanges(query?: FirebasePspsQueryParams) {
		assertIsQueryParamsBased(query);

		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
			firestoreQuery => {
				if (query.internalName) {
					// eslint-disable-next-line no-param-reassign
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(<FirebasePspKeys> 'internalName', '==', query.internalName),
					);
				}

				if (query.popular) {
					// eslint-disable-next-line no-param-reassign
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(<FirebasePspKeys> 'popular', '==', query.popular),
					);
				}

				if (query.paymentOptionType) {
					// eslint-disable-next-line no-param-reassign
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(
							<FirebasePspKeys> 'paymentOptionsTypes',
							'array-contains',
							query.paymentOptionType,
						),
					);
				}

				if (query.environment) {
					// eslint-disable-next-line no-param-reassign
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where(
							<FirebasePspKeys> 'environments',
							'array-contains',
							query.environment,
						),
					);
				}

				return firestoreQuery;
			},
		);
	}

	listenToCollectionChanges(): Observable<FirebasePsp[]> {
		return this._firebaseService
			.listenToCollectionChanges(this.collectionPath, this.factory)
			.pipe(map(firebasePsps => sortBy(firebasePsps, 'internalName')));
	}

	get(id: string): Observable<FirebasePsp | null> {
		return this._firebaseService
			.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	save(model: FirebasePsp): Observable<FirebasePsp> {
		return this._firebaseService.save(this.collectionPath, model, this.factory);
	}

	delete(model: FirebasePsp): Observable<void> {
		return this._firebaseService.delete(`${ this.collectionPath }/${ model.id! }`);
	}

	// async actualize() {
	// 	const entities = await lastValueFrom(this._firebaseService.getCollection<BridgerPsp>(
	// 		this.collectionPath,
	// 		dto => this.factory({
	// 			...dto,
	// 			publiclyVisible: dto.enabled,
	// 		}),
	// 	));

	// 	const batch = await this._firebaseService.batch();

	// 	try {
	// 		await Promise.all(entities.map(async v => batch.set(
	// 			await this._firebaseService.getDocumentRef(`${ this.collectionPath }/${ v.id }`),
	// 			JSON.parse(JSON.stringify(v)),
	// 			{ merge: true },
	// 		)));
	// 	} catch (error: unknown) {
	// 		console.error(error);
	// 	}

	// 	void batch.commit();
	// }

}
