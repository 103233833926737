import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './app.reducer';
import { APP_FEATURE_KEY } from './app.reducer';

export const selectAppState = createFeatureSelector<IState>(APP_FEATURE_KEY);

export const getUsers = createSelector(
	selectAppState,
	s => s.users?.filter(user => !user.isFired) ?? [],
);
