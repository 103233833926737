import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/shared/models/common';

import { FirebasePspsApiService } from '../services';

import { loadFailure, loadSuccess } from './firebase-psps-shared-api.actions';
import { load } from './firebase-psps-shared.actions';

@Injectable()
export class FirebasePspsEffects {

	onLoad$ = createEffect(() => this._actions$.pipe(
		ofType(load),
		switchMap(() => this._firebasePspsApiService
			.listenToCollectionChanges()
			.pipe(apiResult(loadSuccess, loadFailure))),
	));

	constructor(
		private readonly _actions$: Actions,
		private readonly _firebasePspsApiService: FirebasePspsApiService,
	) {

	}

}
