import type { Moment } from 'moment';

import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, FirebaseEntity, Label, Mapper, momentMapper, Required,
	Table, View
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';
import type { ICashierCreditCard } from '@bp/shared/models/cashier-core';

export type CreditCardKeys = NonFunctionPropertyNames<CreditCard>;

export class CreditCard extends FirebaseEntity implements ICashierCreditCard {

	@Label('Title')
	@Required()
	@Default('Untitled Test Credit Card')
	@Table()
	override name!: string;

	@Table()
	override authorUid!: string | null;

	@Mapper(momentMapper)
	@View(FieldViewType.moment)
	@Table()
	override updatedAt!: Moment | null;

	@Table()
	paymentProvider!: string;

	@Control(FieldControlType.number)
	@Table()
	@Required()
	number!: string;

	@Control(FieldControlType.number)
	@Table()
	@Required()
	expireMonth!: number;

	@Control(FieldControlType.number)
	@Required()
	expireYear!: number;

	@Control(FieldControlType.number)
	@Table()
	@Required()
	cvv!: number;

	constructor(data?: DTO<CreditCard>) {
		super(data);
	}

	override toString() {
		return this.name;
	}
}
