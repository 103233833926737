import { isEqual, isNil, noop, uniq } from 'lodash-es';

import { ChangeDetectorRef, Directive, HostBinding, Input, isDevMode, Output, inject, OnDestroy, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

import { Destroyable } from '@bp/shared/models/common';
import { bpQueueMicrotask } from '@bp/shared/utilities';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ControlComponent<TControlValue = any>
	extends Destroyable
	implements ControlValueAccessor, Validator, OnDestroy {

	@Input() value: TControlValue | null = null;

	@Output() readonly valueChange = new EventEmitter<TControlValue>();

	@HostBinding('class.control')
	isControl = true;

	@HostBinding('class.empty')
	get empty(): boolean {
		return isNil(this.value) || (<any> this.value) === '';
	}

	protected _cdr = inject(ChangeDetectorRef);

	protected _validator!: ValidatorFn | null;

	private __onChangeHandlers: ((value: TControlValue) => void)[] = [];

	validatorOnChange = noop;

	/** `View -> model callback called when input has been touched` */
	onTouched = noop;

	/** `View -> model callback called when value changes` */
	onChange = (v: TControlValue): void => void this.__onChangeHandlers.forEach(callback => void callback(v));

	// #region Implementation of the ControlValueAccessor interface
	writeValue(value: TControlValue): void {
		bpQueueMicrotask(() => {
			this.value = value;

			this._cdr.markForCheck();
		});
	}

	registerOnChange(onChangeHandler: (value: any) => void): void {
		this.__onChangeHandlers = uniq([ ...this.__onChangeHandlers, onChangeHandler ]);
	}

	registerOnTouched(onTouchedHandler: () => void): void {
		this.onTouched = onTouchedHandler;
	}
	// #endregion Implementation of the ControlValueAccessor interface

	// #region Implementation of the Validator interface
	registerOnValidatorChange(validatorOnChangeHandler: () => void): void {
		this.validatorOnChange = validatorOnChangeHandler;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		return this._validator ? this._validator(c) : null;
	}
	// #endregion Implementation of the Validator interface

	setValue(value: TControlValue, { emitChange }: { emitChange: boolean } = { emitChange: true }): void {
		// eslint-disable-next-line no-console
		isDevMode() && console.warn(value, this.value, `equal: ${ isEqual(value, this.value) }`, this.constructor.name);

		if (isEqual(value, this.value)) {
			this.validatorOnChange();

			return;
		}

		this.value = value;

		if (emitChange) {
			this.valueChange.emit(value);

			this.onChange(value);
		}

		// This._cdr.detectChanges();
		this._cdr.markForCheck();
	}

}
