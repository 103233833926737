import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AdminsSharedFirebasePspsModule } from './admins-shared-firebase-psps.module';
import { FirebasePspsEffects } from './state/firebase-psps-shared.effects';
import { FEATURE_KEY, reducer } from './state/firebase-psps-shared.reducer';

@NgModule({
	imports: [
		AdminsSharedFirebasePspsModule,

		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ FirebasePspsEffects ]),
	],
})
export class AdminsSharedFirebasePspsRootModule { }
