
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FADE } from '@bp/shared/animations';
import { FormMetadataEntityBaseComponent } from '@bp/shared/components/metadata';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { isEmpty } from '@bp/shared/utilities';

import { RightDrawerEntityNameControlComponent } from '@bp/admins-shared/features/entity';

import type { JiraIssue } from '@bp/firebase-functions';

import { JiraService } from '../../services';

@Component({
	selector: 'bp-right-drawer-entity-header',
	templateUrl: './right-drawer-header-entity.component.html',
	styleUrls: [ './right-drawer-header-entity.component.scss' ],
	host: {
		class: 'page-header right-drawer-header',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class PresetRightDrawerEntityHeaderComponent
	extends RightDrawerEntityNameControlComponent {
	@Input() jiraIssueKey?: string | null;

	@Output() readonly jiraIssueKeyChange = new EventEmitter<string>();

	private readonly _filteredIssues$ = new BehaviorSubject<JiraIssue[]>([]);

	filteredIssues$ = this._filteredIssues$.asObservable();

	constructor(
		public override parentFormCmpt: FormMetadataEntityBaseComponent<any>,
		public jira: JiraService,
	) {
		super(parentFormCmpt);

		this._onNameChangeSearchJiraIssues();

	}

	onAutocompleteInputChange(value?: string): void {
		this.nameControl.setValue(value?.toString());

		this.nameControl.markAsDirty();

		this.nameControl.markAsTouched();

		this.nameControl.updateValueAndValidity();
	}

	private _onNameChangeSearchJiraIssues(): void {
		(<Observable<string | undefined>> this.nameControl.valueChanges)
			.pipe(
				map(name => name?.trim()),

				distinctUntilChanged(),
				switchMap(name => isEmpty(name)
					? this.jira.getLastTenUpdated()
					: this.jira.search(name)),
				takeUntilDestroyed(this),
			)
			.subscribe(issues => void this._filteredIssues$.next(issues));
	}
}
