import { debounceTime, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs';

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/shared/models/common';

import { UsersApiService, UsersQueryParams } from '@bp/backoffice/domains/users';
import { IdentityFacade } from '@bp/backoffice/domains/identity';

import { usersUpdateFailure, usersUpdateSuccess } from './app-api.actions';
import { listenToUsersChanges } from './app.actions';
import { AppFacade } from './app.facade';

@Injectable()
export class AppEffects {

	listenToUsersChanges$ = createEffect(() => this._actions$.pipe(
		ofType(listenToUsersChanges),
		switchMap(() => this._usersApiService
			.listenToQueriedRecordsPageChanges(new UsersQueryParams({ fired: false, limit: -1 }))
			.pipe(
				map(({ records }) => records),
				map(users => users.sort(a => a.id === this._identityFacade.user?.id ? -1 : 1)),
				apiResult(usersUpdateSuccess, usersUpdateFailure),
				takeUntil(this._identityFacade.userLoggedOut$),
			)),
	));

	listenToUsersOnLogin = merge(
		this._identityFacade.userPresent$.pipe(first()),
		this._identityFacade.userLoggedIn$,
	)
		.pipe(debounceTime(20)) // emit only once since userPreset will get emitted along with userLoggedIn
		.subscribe(() => void this._appFacade.listenToUsers());

	constructor(
		private readonly _usersApiService: UsersApiService,
		private readonly _actions$: Actions,
		private readonly _appFacade: AppFacade,
		private readonly _identityFacade: IdentityFacade,
	) {
	}

}
