import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';
import type { FirebasePsp } from '@bp/shared/models/firebase-psps';

import { ACTIONS_SCOPE } from './firebase-psps-shared.actions';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const loadSuccess = createAction(
	`${ scope } Load Success`,
	props<ResultActionPayload<FirebasePsp[]>>(),
);

export const loadFailure = createAction(
	`${ scope } Load Failure`,
	props<ErrorActionPayload>(),
);
