import { filter } from 'rxjs/operators';

import type { OnChanges, OnDestroy } from '@angular/core';
import {
	ChangeDetectionStrategy, Component, EventEmitter, Input, Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { FormMetadataEntityBaseComponent } from '@bp/shared/components/metadata';
import type { IValidatorFunc } from '@bp/shared/features/validation';
import { Validators } from '@bp/shared/features/validation';
import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';
import type { SimpleChanges } from '@bp/shared/models/core';

@Component({
	selector: 'bp-right-drawer-entity-name-control',
	templateUrl: './right-drawer-entity-name-control.component.html',
	styleUrls: [ './right-drawer-entity-name-control.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightDrawerEntityNameControlComponent extends Destroyable implements OnChanges, OnDestroy {

	@Input() name?: string | null;

	@Input() suggestedName?: string | null;

	@Output() readonly nameChange = new EventEmitter<string>();

	@Input() namePlaceholder!: string;

	// Note that required validator is always switched on no matter this prop value.
	@Input() validator?: IValidatorFunc<string | undefined> | null = null;

	nameControl = new UntypedFormControl('', Validators.required);

	private _nameSetExternally?: string | null;

	constructor(
		public parentFormCmpt: FormMetadataEntityBaseComponent<any>,
	) {
		super();

		this._connectNameControlToParentForm();

		this._ifParentFormPendingDisableNameControl();

		this._whenNameControlValueChangesOutputValue();

		this._whenNameIsSameAsOnInputResetControl();

		this._onParentFormBecomePristineResetName();
	}

	ngOnChanges({ name, suggestedName, validator }: SimpleChanges<RightDrawerEntityNameControlComponent>): void {
		if (name)
			this._setNameOnExternalChange();

		if (suggestedName && this.suggestedName)
			this.setName(this.suggestedName);

		if (validator) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			validator.previousValue && this.nameControl.removeValidators(validator.previousValue);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			validator.currentValue && this.nameControl.addValidators(validator.currentValue);
		}
	}

	override ngOnDestroy(): void {
		this._disconnectControlFromParentForm();

		super.ngOnDestroy();
	}

	setName(name: string): void {
		this.nameControl.setValue(name);

		this.nameControl.markAsDirty();

		this.parentFormCmpt.markAsDirty();
	}

	private _setNameOnExternalChange(): void {
		if (this._nameSetExternally === this.name)
			return;

		this._nameSetExternally = this.name;

		this.nameControl.setValue(this.name);

		this.nameControl.markAsUntouched();
	}

	private _whenNameControlValueChangesOutputValue(): void {
		this._onNameControlValueChange(name => {
			if (name && name !== this._nameSetExternally)
			 	void this.nameChange.emit(name);
		});
	}

	private _whenNameIsSameAsOnInputResetControl(): void {
		this._onNameControlValueChange(name => {
			if (name === this._nameSetExternally)
				this.nameControl.markAsPristine();
		});
	}

	private _onNameControlValueChange(callback: (name: string) => void): void {
		this.nameControl.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(callback);
	}

	private _connectNameControlToParentForm(): void {
		this.parentFormCmpt.form!.setControl('__name__', this.nameControl, { emitEvent: false });
	}

	private _ifParentFormPendingDisableNameControl(): void {
		this.parentFormCmpt.pending && this.nameControl.disable();
	}

	private _disconnectControlFromParentForm(): void {
		this.parentFormCmpt.form!.removeControl('__name__', { emitEvent: false });
	}

	private _onParentFormBecomePristineResetName(): void {
		this.parentFormCmpt.formPristine$
			.pipe(
				filter(Boolean),
				takeUntilDestroyed(this),
			)
			.subscribe(() => this._nameSetExternally && void this.nameControl.setValue(this._nameSetExternally));
	}

}
