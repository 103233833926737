import { Enumeration } from '@bp/shared/models/core/enum';

export class CashierEnvironment extends Enumeration {

	static development = new CashierEnvironment(
		'DEV',
		'https://localhost:4203/cashier-loader.js',
		getImageUrl('development'),
	);

	static stagingV2 = new CashierEnvironment(
		'Staging V2',
		'https://checkout.bridgerpay.dev/v2/loader',
		getImageUrl('stagingV2'),
	);

	static productionV2 = new CashierEnvironment(
		'Production V2',
		'https://checkout.bridgerpay.com/v2/loader',
		getImageUrl('productionV2'),
	);

	static staging = new CashierEnvironment(
		'Staging V1',
		'https://cashier-stg.bridgerpay.com/loader',
		getImageUrl('stagingV1'),
	);

	static sandbox = new CashierEnvironment(
		'Sandbox V1',
		'https://cashier-sandbox.bridgerpay.dev/loader',
		getImageUrl('sandboxV1'),
	);

	static bridgeros = new CashierEnvironment(
		'Bridgeros V1',
		'https://cashier.bridgeros.com/loader',
		getImageUrl('bridgerosV1'),
	);

	static production = new CashierEnvironment(
		'Production V1',
		'https://cashier.bridgerpay.com/loader',
		getImageUrl('productionV1'),
	);

	constructor(
		displayName: string,
		public embedScriptSource: string,
		public imageUrl: string,
	) {
		super(displayName);
	}
}

function getImageUrl(imageName: string): string {
	return `https://static-assets.bridgerpay.workers.dev/${ imageName }_success.png`;
}
