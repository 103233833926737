import { shareReplay, switchMap } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FADE } from '@bp/shared/animations';
import { OptionalBehaviorSubject } from '@bp/shared/rxjs';

import { JiraService } from '@bp/backoffice/cashier-demostand/presets';

@Component({
	selector: 'bp-jira-issue-name',
	templateUrl: './jira-issue-name.component.html',
	styleUrls: [ './jira-issue-name.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE ],
})
export class JiraIssueNameComponent {

	@Input()
	set key(v: string) {
		this.key$.next(v);
	}

	key$ = new OptionalBehaviorSubject<string>();

	@Input() hideTooltip!: boolean;

	issue$ = this.key$
		.pipe(
			switchMap(v => this._jira.getIssue(v)),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);

	constructor(private readonly _jira: JiraService) { }

}
