import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatListModule } from '@angular/material/list';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';

import { AdminsSharedFeaturesLayoutModule } from '@bp/admins-shared/features/layout';

import {
	EntitiesTableCardComponent, EntitiesTableCardNoResultsDirective, RightDrawerEntityComponent,
	RightDrawerEntityHeaderButtonsComponent, RightDrawerEntityHeaderDirective, RightDrawerEntityNameControlComponent
} from './components';

const EXPOSED = [
	RightDrawerEntityComponent,
	RightDrawerEntityNameControlComponent,
	EntitiesTableCardComponent,
	RightDrawerEntityHeaderButtonsComponent,
	EntitiesTableCardNoResultsDirective,
	RightDrawerEntityHeaderDirective,
];

@NgModule({
	imports: [
		CommonModule,
		MatCardModule,
		MatProgressBarModule,
		MatButtonModule,
		MatMenuModule,
		MatIconModule,
		MatListModule,
		OverlayModule,
		ReactiveFormsModule,

		SharedDirectivesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedFeaturesSvgIconsModule,
		SharedFeaturesTooltipModule,
		AdminsSharedFeaturesLayoutModule,
	],
	exports: EXPOSED,
	declarations: EXPOSED,
})
export class AdminsSharedFeaturesEntityModule { }
