import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedComponentsMetadataModule } from '@bp/shared/components/metadata';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesModalModule } from '@bp/shared/features/modal';
import { SharedFeaturesSvgIconsModule } from '@bp/shared/features/svg-icons';
import { SharedFeaturesSvgPaymentCardBrandsIconsModule } from '@bp/shared/features/svg-payment-card-brands-icons';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedPipesModule } from '@bp/shared/pipes';

import { AdminsSharedCoreComponentsModule } from '@bp/admins-shared/core/components';
import { AdminsSharedFeaturesEntityModule } from '@bp/admins-shared/features/entity';
import { AdminsSharedFeaturesLayoutModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedDomainsIdentityModule } from '@bp/admins-shared/domains/identity';

import { BackofficeDomainsUsersModule } from '@bp/backoffice/domains/users';

import {
	 CreditCardNameComponent, CreditCardsAutocompleteComponent,
	JiraIssueNameComponent
} from './components';

const MODULES = [
	CommonModule,
	MatIconModule,
	MatMenuModule,
	MatButtonModule,
	MatToolbarModule,
	MatProgressBarModule,
	MatTabsModule,
	MatSlideToggleModule,
	MatTableModule,
	MatCardModule,
	ReactiveFormsModule,
	RouterModule,

	SharedComponentsControlsModule,
	SharedComponentsMetadataModule,
	SharedFeaturesSvgIconsModule,
	SharedFeaturesTooltipModule,
	SharedDirectivesModule,
	SharedFeaturesModalModule,
	SharedComponentsCoreModule,
	SharedPipesModule,
	SharedFeaturesSvgIconsModule,
	SharedFeaturesSvgPaymentCardBrandsIconsModule,

	AdminsSharedFeaturesLayoutModule,
	AdminsSharedCoreComponentsModule,
	AdminsSharedFeaturesEntityModule,
	AdminsSharedDomainsIdentityModule,

	BackofficeDomainsUsersModule,
];

const EXPOSED = [
	JiraIssueNameComponent,
	CreditCardNameComponent,
	CreditCardsAutocompleteComponent,
];

@NgModule({
	imports: MODULES,
	exports: [ EXPOSED, MODULES ],
	declarations: EXPOSED,
})
export class SharedModule { }
