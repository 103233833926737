
import { Injectable } from '@angular/core';
import type { Params } from '@angular/router';

import { select } from '@ngrx/store';

import { searchIgnoringCase } from '@bp/shared/utilities';

import { EntitiesListFacade } from '@bp/admins-shared/features/entity';

import { CommonQueryParams } from '@bp/backoffice/cashier-demostand/models';

import type { CreditCard } from '../../models';

import { CREDIT_CARDS_LIST_ACTIONS, listenToFirestoreCollectionUpdates } from './credit-cards.actions';
import { CREDIT_CARDS_LIST_SELECTORS, getAllCreditCards } from './credit-cards.selectors';

@Injectable({ providedIn: 'root' })
export class CreditCardsFacade extends EntitiesListFacade<CreditCard, CommonQueryParams> {

	readonly actions = CREDIT_CARDS_LIST_ACTIONS;

	readonly selectors = CREDIT_CARDS_LIST_SELECTORS;

	allRecords$ = this._store$.pipe(select(getAllCreditCards));

	queryParamsFactory(routeParams: Params) {
		return new CommonQueryParams(routeParams);
	}

	autocompleteFilterFn = (cc: CreditCard, query: string) => searchIgnoringCase(cc.name, query)
		|| searchIgnoringCase(cc.paymentProvider, query);

	listenToAllRecordsUpdates() {
		this._store$.dispatch(listenToFirestoreCollectionUpdates());
	}

}
