import { switchMap, takeUntil } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { createEffect, ofType } from '@ngrx/effects';

import { apiResult } from '@bp/shared/models/common';

import { FirebaseEntitiesListEffects } from '@bp/admins-shared/features/entity';

import type { CommonQueryParams } from '@bp/backoffice/cashier-demostand/models';
import { IdentityFacade } from '@bp/backoffice/domains/identity';

import type { CreditCard } from '../../models';
import { CreditCardsPageComponent } from '../../pages';
import { CreditCardsApiService } from '../../services';

import { firebaseCollectionUpdate, listeningToFirestoreCollectionUpdatesFailure } from './credit-cards-api.actions';
import { listenToFirestoreCollectionUpdates } from './credit-cards.actions';
import { CreditCardsFacade } from './credit-cards.facade';

@Injectable()
export class CreditCardsEffects extends FirebaseEntitiesListEffects<CreditCard, CommonQueryParams, CreditCardsFacade> {

	readonly routeComponentType = CreditCardsPageComponent;

	listenToCollectionChanges$ = createEffect(() => this._actions$.pipe(
		ofType(listenToFirestoreCollectionUpdates),
		switchMap(() => this._creditCardsApiService
			.listenToCollectionChanges()
			.pipe(
				apiResult(firebaseCollectionUpdate, listeningToFirestoreCollectionUpdatesFailure),
				takeUntil(this._identityFacade.userLoggedOut$),
			)),
	));

	constructor(
		private readonly _creditCardsApiService: CreditCardsApiService,
		private readonly _identityFacade: IdentityFacade,
		creditCards: CreditCardsFacade,
	) {
		super(_creditCardsApiService, creditCards);
	}

}
