import { Injectable } from '@angular/core';
import type { Params } from '@angular/router';

import { EntitiesListFacade } from '@bp/admins-shared/features/entity';

import type { Preset } from '../../models';
import { PresetsQueryParams } from '../../models';

import { PRESETS_LIST_ACTIONS } from './presets.actions';
import { PRESETS_LIST_SELECTORS } from './presets.selectors';

@Injectable({ providedIn: 'root' })
export class PresetsFacade extends EntitiesListFacade<Preset, PresetsQueryParams> {

	readonly actions = PRESETS_LIST_ACTIONS;

	readonly selectors = PRESETS_LIST_SELECTORS;

	queryParamsFactory(routeParams: Params) {
		return new PresetsQueryParams(routeParams);
	}

}
