import { Enumeration } from '@bp/shared/models/core/enum';

export class DrawerType extends Enumeration {

	static new = new DrawerType();

	static edit = new DrawerType();

	static clone = new DrawerType();

	static view = new DrawerType();

	static loadError = new DrawerType();

	static editLike = [ DrawerType.edit, DrawerType.new, DrawerType.clone ];

}
