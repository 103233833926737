import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { IdentityLoggedOutGuard } from '@bp/shared-domains-identity';

import { LoginPageComponent } from './login-page';

const routes: Routes = [
	{
		path: 'login',
		canActivate: [ IdentityLoggedOutGuard ],
		component: LoginPageComponent,
		data: {
			fullscreen: true,
			title: 'Login',
		},
	},
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
})
export class LoginRoutingModule {}
