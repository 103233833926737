<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<button type="submit" class="hidden"></button>

	<bp-alert [errors]="errors" type="danger"> </bp-alert>

	<bp-property-metadata-controls-section
		heading="Preset Details"
		[form]="form"
		[metadata]="metadata"
		[sectionScheme]="uiScheme"
	>
		<bp-credit-cards-autocomplete
			*bpCustomControl="'creditCardId'; inferFrom: metadata; let propertyMetadata"
			label="Test Credit Card or PSP"
			[formControlName]="propertyMetadata.property"
		>
		</bp-credit-cards-autocomplete>

		<bp-autocomplete
			*bpCustomControl="'paymentProvider'; inferFrom: metadata; let propertyMetadata"
			name="psp"
			label="Credit Card PSP"
			[formControlName]="propertyMetadata.property"
			[items]="bridgerPspsSharedFacade.allInternalNames$ | async"
		>
		</bp-autocomplete>
	</bp-property-metadata-controls-section>
</form>
