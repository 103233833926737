import { merge } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { Type } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ActionConfirmDialogComponent, ActionConfirmDialogData } from '@bp/shared/components/dialogs';
import { Action } from '@bp/shared/typings';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function buildConfirmedEntityActionWorkflowEffect<
	TActionPayload,
	TActionConfirmDialogData extends ActionConfirmDialogData = ActionConfirmDialogData
>(config: {
	actions$: Actions;
	dialog: MatDialog;
	confirmAction: Action<TActionPayload>;
	dismissActionConfirmation: Action;
	action: Action<TActionPayload>;
	actionConfirmDialogComponent: Type<ActionConfirmDialogComponent<TActionConfirmDialogData>>;
	buildActionConfirmDialogData: (payload: ReturnType<Action<TActionPayload>>) => TActionConfirmDialogData;
}) {
	return createEffect(() => merge(
		config.actions$.pipe(
			ofType(config.confirmAction),
			exhaustMap(payload => config.dialog
				.open<ActionConfirmDialogComponent<TActionConfirmDialogData>, TActionConfirmDialogData, boolean>(
				config.actionConfirmDialogComponent,
				{
					data: config.buildActionConfirmDialogData(payload),
				},
			)
				.afterClosed()
				.pipe(map(result => result
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					? config.action(<any> payload)
					: config.dismissActionConfirmation()))),
		),
	));
}
