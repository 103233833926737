import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { IdentityFacade as IdentityBaseFacade, LOGIN_ROUTE_PATHNAME_TOKEN } from '@bp/shared-domains-identity';

import { IdentityEffects } from './state/identity.effects';
import { FEATURE_STATE_KEY, reducer } from './state/identity.reducer';
import { IdentityFacade } from './state';
import { identityStateRehydratorMetaReducer } from './state/identity-state-rehydrator.meta-reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_STATE_KEY, reducer, {
			metaReducers: [ identityStateRehydratorMetaReducer ],
		}),
		EffectsModule.forFeature([ IdentityEffects ]),
	],
	providers: [
		{ provide: LOGIN_ROUTE_PATHNAME_TOKEN, useValue: '/login' },
		{ provide: IdentityBaseFacade, useExisting: IdentityFacade },
	],
})
export class BackofficeDomainsIdentityRootModule {}
