import { EMPTY, merge, Observable, of } from 'rxjs';
import { catchError, exhaustMap, groupBy, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { BpError } from '@bp/shared/models/core';
import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';
import { Action } from '@bp/shared/typings';
import { reportJsErrorIfAny } from '@bp/shared/rxjs';

import { EntityAsyncActionFailurePayload, EntityAsyncActionPayload, EntityAsyncActionSuccessPayload } from '../models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export function buildEntityAsyncActionWorkflowEffect<
	TEntity extends Entity | FirebaseEntity,
	TActionPayload extends EntityAsyncActionPayload<TEntity>,
	TActionSuccessResult extends TEntity | undefined,
	TActionFailurePayload extends EntityAsyncActionFailurePayload<TEntity>,
	TOnActionResult = TActionSuccessResult extends undefined ? void : TActionSuccessResult
>(config: {
	actions$: Actions;
	action: Action<TActionPayload>;
	actionSuccess: Action<EntityAsyncActionSuccessPayload<TEntity, TActionSuccessResult>>;
	actionFailure: Action<TActionFailurePayload>;
	onAction: (payload: ReturnType<Action<TActionPayload>>) => Observable<TOnActionResult>;
	onActionSuccess?: (payload: ReturnType<Action<EntityAsyncActionSuccessPayload<TEntity, TActionSuccessResult>>>) => void;
	onActionFailure?: (payload: ReturnType<Action<TActionFailurePayload>>) => void;
}) {
	return createEffect(() => merge(
		config.actions$.pipe(
			ofType(config.action),
			groupBy(({ entity }) => entity.id),
			mergeMap(groupByEntityId$ => groupByEntityId$.pipe(
				exhaustMap(actionPayload => config.onAction(actionPayload)
					.pipe(
						map(result => config.actionSuccess({
							...actionPayload,
							result: <TActionSuccessResult><unknown>result,
						})),
						reportJsErrorIfAny,
						catchError((error: unknown) => of(
							config.actionFailure({
								...actionPayload,
								error: new BpError(error),
							}),
						)),
					)),
			)),
		),

		config.onActionSuccess
			? config.actions$.pipe(
				ofType(config.actionSuccess),
				tap(payload => void config.onActionSuccess!(payload)),
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				ignoreElements(), // Same as ngrx dispatch: false
			)
			: EMPTY,

		config.onActionFailure
			? config.actions$.pipe(
				ofType(config.actionFailure),
				tap(payload => void config.onActionFailure!(payload)),
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				ignoreElements(), // Same as ngrx dispatch: false
			)
			: EMPTY,
	));
}
