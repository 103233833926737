import { IPageQueryParamsDTO, mixinPageQueryParams, QueryParamsBase, StringifyQuery } from '@bp/shared/models/common';

interface IPresetsQueryParams {

	authorUid?: string;

	search?: string;

	country?: string;

	isShared?: boolean;

	userUid?: string;

}

type PresetsQueryParamsDTO = IPageQueryParamsDTO & StringifyQuery<IPresetsQueryParams>;

class PresetsQueryParamsBase extends QueryParamsBase<PresetsQueryParamsDTO> { }

export class PresetsQueryParams extends mixinPageQueryParams(PresetsQueryParamsBase) implements IPresetsQueryParams {

	authorUid?: string;

	search?: string;

	country?: string;

	isShared?: boolean;

	userUid?: string;

	constructor(dto: PresetsQueryParamsDTO) {
		super(dto);

		this.userUid = dto.userUid;

		this.authorUid = dto.authorUid ?? dto.userUid;

		this.search = dto.search;

		this.country = dto.country;

		this.isShared = !dto.userUid || !!dto.isShared;
	}
}
