<bp-input
	[pending]="pending"
	[name]="name"
	[nativeAutocomplete]="nativeAutocomplete"
	[color]="color"
	[label]="label"
	[hint]="hint"
	[required]="!!required"
	[formControl]="_internalControl"
	[placeholder]="placeholder"
	[autocomplete]="autocomplete"
	[throttle]="throttle"
	[debounce]="debounce"
	[appearance]="appearance"
	[hideErrorText]="hideErrorText"
	[floatLabel]="floatLabel"
	[hasSearchIcon]="hasSearchIcon"
>
	<bp-input-hint *ngIf="suggestedItem && suggestedItem !== value" @fade>
		<button
			type="button"
			class="link interactive primary-text mat-caption"
			[bpTooltip]="suggestedItemTooltip"
			(click)="setValue(suggestedItem)"
		>
			{{ suggestedItemButtonTextPrefix }}{{ suggestedItem }}
		</button>
	</bp-input-hint>
</bp-input>

<mat-autocomplete #autocomplete="matAutocomplete" [class]="panelClass || ''">
	<div *ngIf="autocomplete.isOpen" @fadeInListStaggered>
		<mat-option *ngIf="!pending && !(filtered$ | async)?.length" disabled>
			{{ 'No items' | bpTranslate | async }}
		</mat-option>

		<div *ngFor="let item of filtered$ | async">
			<mat-option *bpDelayedRender="0; allStagger: true" [class.selected]="item === value" [value]="item">
				<ng-container *ngIf="optionTpl; else defaultOptionTpl">
					<ng-container *ngTemplateOutlet="optionTpl; context: { $implicit: item }"> </ng-container>
				</ng-container>
				<ng-template #defaultOptionTpl>
					{{ item | bpDisplayValue: itemDisplayPropertyName | bpTranslate | async }}
				</ng-template>
			</mat-option>
		</div>
	</div>
	<!-- the mat option below is a trick not to render all the options on render of this component, mat-autocomplete needs options to be present to start render them in dom -->
	<mat-option style="display: none" disabled></mat-option>
</mat-autocomplete>
