import type { ActiveToast, ToastrService } from 'ngx-toastr';
import type { Observable } from 'rxjs';
import { from, defer, EMPTY, merge, of } from 'rxjs';
import {
	catchError, exhaustMap, filter, map, mergeMap, switchMap, tap
} from 'rxjs/operators';
import { isArray, isObject } from 'lodash-es';

import type { Type } from '@angular/core';
import type { MatDialog } from '@angular/material/dialog';
import type { ActivatedRoute, Params } from '@angular/router';

import type { Actions } from '@ngrx/effects';
import { createEffect, ofType } from '@ngrx/effects';

import type { ToastConfig } from '@bp/shared/components/core';
import { BpError } from '@bp/shared/models/core';
import type { DTO, Entity, FirebaseEntity, IEntitiesApiBaseService } from '@bp/shared/models/metadata';
import { filterWhileNotifierTrue, reportJsErrorIfAny } from '@bp/shared/rxjs';
import type { RouterService, StorageService } from '@bp/shared/services';
import type { ActionConfirmDialogData } from '@bp/shared/components/dialogs';
import { DisableConfirmDialogComponent, DeleteConfirmDialogComponent } from '@bp/shared/components/dialogs';

import { isRightDrawerOutlet } from '@bp/admins-shared/features/layout';

import { DrawerRouteParams, DrawerType } from '../../models';
import { buildConfirmedEntityActionWorkflowEffect, buildEntityAsyncActionWorkflowEffect } from '../../workflow-effects';

import type { EntityFacade } from './entity.facade';
import type { EntityDrawerActions } from './entity.actions';

export abstract class EntityEffects<TEntity extends Entity | FirebaseEntity> {

	readonly abstract routeComponentType: Type<any> | Type<any>[];

	get actions(): EntityDrawerActions<TEntity> {
		return this._entityFacade.actions;
	}

	private readonly _routeComponents$ = defer(() => from(
		isArray(this.routeComponentType) ? this.routeComponentType : [ this.routeComponentType ],
	));

	protected _routeComponentActivation$ = this._routeComponents$
		.pipe(
			mergeMap(routeComponentType => this._routerService.onNavigationEndToRouteComponent(routeComponentType)),
		);

	protected _routeComponentDeactivation$ = this._routeComponents$
		.pipe(
			mergeMap(routeComponentType => this._routerService.onNavigationEndFromRouteComponent(routeComponentType)),
		);

	protected _onRouteComponentActivation$ = createEffect(() => defer(() => this._routeComponentActivation$
		.pipe(
			this._routeComponentActivationHook,
			this._generateActionsOnRouteChange,
		)));

	protected _onLoad$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.load),
		switchMap(({ id, drawerType, formId }) => this._load(id)
			.pipe(
				map(entity => entity
					? this.actions.api.loadSuccess({
						entity,
						drawerType,
						formId,
					})
					: this.actions.api.loadFailure({ error: BpError.notFound })),
				catchError((error: unknown) => of(this.actions.api.loadFailure({
					error: <BpError> error,
				}))),
				reportJsErrorIfAny,
			)),
	));

	protected _loadFreshEntityStateOnRefresh$ = createEffect(() => merge(
		this._actions$.pipe(ofType(this.actions.load)),
		this._actions$.pipe(
			ofType(this.actions.changeDrawerRouteParams),
			map(payload => this.actions.load({
				...payload,
				id: payload.entity.id!,
			})),
		),
	)
		.pipe(
			switchMap(loadPayload => this._actions$.pipe(
				ofType(this.actions.refresh),
				map(() => this.actions.load(loadPayload)),
			)),
		));

	protected _onSave$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.save),
		filter(({ entity, optimistic }) => optimistic ? !!entity.id : true),
		exhaustMap(({ entity, optimistic, suppressSavedToast }) => this._save(entity, suppressSavedToast)
			.pipe(
				map(saveResult => this.actions.api.saveSuccess({
					entity,
					isAdd: !entity.id,
					suppressSavedToast,
					result: optimistic
						? entity
						: saveResult,
				})),
				catchError((error: unknown) => of(this.actions.api.saveFailure({
					entity,
					error: <BpError> error,
				}))),
				reportJsErrorIfAny,
			)),
	));

	protected _onOptimisticSaveUpdateLocalEntity$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.save),
			filter(({ optimistic }) => !!optimistic),
			filter(({ entity }) => this._facadeHasDraftOrSameEntity(entity)),
			tap(({ entity }) => void this._entityFacade.updateLocalEntity(entity)),
		),
		{ dispatch: false },
	);

	protected _onOptimisticSaveShowToastWithUndoBtn$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.save),
			filter(({ optimistic }) => !!optimistic),
			tap(({ rollbackEntity }) => {
				if (this._updatedActiveToast)
					this._toaster.remove(this._updatedActiveToast.toastId);

				this._updatedActiveToast = this._toaster.success(
					'Updated',
					undefined,
					<ToastConfig> { undoBtn: !!rollbackEntity },
				);
			}),
			// eslint-disable-next-line rxjs/no-unsafe-switchmap
			switchMap(({ rollbackEntity }) => this._updatedActiveToast!.onAction.pipe(
				tap(() => void this._entityFacade.save(rollbackEntity!, true)),
			)),
		),
		{ dispatch: false },
	);

	protected _onSaveSuccess$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.saveSuccess),
			tap(({ result, suppressSavedToast }) => {
				if (this._facadeHasDraftOrSameEntity(result)) {
					this._entityFacade.updateLocalEntity(result);

					void this._entityFacade.deleteDraftEntityFromStorage();
				}

				!suppressSavedToast && this._toaster.success('Saved');
			}),
		),
		{ dispatch: false },
	);

	protected _showErrorToastOnSaveFailureInDrawerViewMode$ = createEffect(
		() => merge(
			this._actions$.pipe(
				ofType(this.actions.api.saveFailure),
				filterWhileNotifierTrue(this._entityFacade.isViewDrawer$),
			),
			this._actions$.pipe(ofType(this.actions.api.deleteFailure)),
		)
			.pipe(tap(({ error }) => {
				this._updatedActiveToast?.toastRef.manualClose();

				this._toaster.error(error.message);
			}))
		,
		{ dispatch: false },
	);

	/*
	 * To fetch an actual state on some minor actions performed during view to rollback possible changes
	 * like deleting an item or changing a status of an item
	 */
	protected _refreshOnSaveFailureInDrawerViewMode$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.api.saveFailure),
			filterWhileNotifierTrue(this._entityFacade.isViewDrawer$),
			map(() => this.actions.refresh()),
		),
	);

	protected _onClone$ = createEffect(() => this._actions$.pipe(
		ofType(
			this.actions.changeDrawerRouteParams,
			this.actions.api.loadSuccess,
		),
		mergeMap(v => v.drawerType === DrawerType.clone
			? of(this.actions.clone({
				entity: this._entityFacade.factory({
					...<DTO<TEntity>><unknown> v.entity,
					id: null,
					name: `Clone of ${ v.entity.name! }`,
				}),
			}))
			: EMPTY),
	));

	protected _saveOptimisticallyOnViewActions$ = createEffect(
		() => this._actions$.pipe(
			ofType(
				this.actions.disable,
				this.actions.toggleActivityStatus,
			),
			tap(payload => payload.entity.id
				? void this._entityFacade.optimisticSave(payload)
				: void this._entityFacade.updateLocalEntity(payload.entity)),
		),
		{ dispatch: false },
	);

	protected _confirmDeleteWorkflowEffect$ = buildConfirmedEntityActionWorkflowEffect({
		actions$: this._actions$,
		dialog: this._dialog,
		actionConfirmDialogComponent: DeleteConfirmDialogComponent,
		buildActionConfirmDialogData: ({ entity }) => this._buildDeleteConfirmDialogData(entity),
		confirmAction: this.actions.deleteConfirm,
		dismissActionConfirmation: this.actions.deleteConfirmDismiss,
		action: this.actions.delete,
	});

	protected _deleteAsyncWorkflowEffect$ = buildEntityAsyncActionWorkflowEffect({
		actions$: this._actions$,
		action: this.actions.delete,
		actionSuccess: this.actions.api.deleteSuccess,
		actionFailure: this.actions.api.deleteFailure,
		onAction: ({ entity }) => this._delete(entity),
		onActionSuccess: () => void this._onDeleteSuccess(),
	});

	protected _disableWorkflowEffect$ = buildConfirmedEntityActionWorkflowEffect({
		actions$: this._actions$,
		dialog: this._dialog,
		actionConfirmDialogComponent: DisableConfirmDialogComponent,
		buildActionConfirmDialogData: ({ entity }) => this._buildDisableConfirmDialogData(entity),
		confirmAction: this.actions.disableConfirm,
		dismissActionConfirmation: this.actions.disableConfirmDismiss,
		action: this.actions.disable,
	});

	private _updatedActiveToast?: ActiveToast<any>;

	constructor(
		protected readonly _actions$: Actions,
		protected readonly _routerService: RouterService,
		protected readonly _entityFacade: EntityFacade<TEntity>,
		protected readonly _storageService: StorageService,
		protected readonly _dialog: MatDialog,
		protected readonly _toaster: ToastrService,
		protected readonly _entitiesApiService?: IEntitiesApiBaseService<TEntity>,
	) { }

	protected _buildActionConfirmDialogData = (_entity: TEntity): ActionConfirmDialogData => ({
		type: 'TYPE: Change Me!',
		name: 'NAME: Change Me!',
	});

	protected _buildDeleteConfirmDialogData = (entity: TEntity): ActionConfirmDialogData => this._buildActionConfirmDialogData(entity);

	protected _buildDisableConfirmDialogData = (entity: TEntity): ActionConfirmDialogData => this._buildActionConfirmDialogData(entity);

	protected _tryGetEntityId = (entity: TEntity | null): string | null | undefined => entity?.id;

	protected _routeComponentActivationHook = (route$: Observable<ActivatedRoute>): Observable<ActivatedRoute> => route$;

	protected _load = (id: string): Observable<TEntity | null> => {
		this._assertApiServiceIsProvided(this._entitiesApiService);

		return this._entitiesApiService.get(id);
	};

	protected _save = (entity: TEntity, _suppressSavedToast = false): Observable<TEntity> => {
		this._assertApiServiceIsProvided(this._entitiesApiService);

		return this._entitiesApiService.save(entity);
	};

	protected _delete = (entity: TEntity): Observable<void> => {
		this._assertApiServiceIsProvided(this._entitiesApiService);

		return this._entitiesApiService.delete(entity);
	};

	protected _onDeleteSuccess(): void {
		if (this._entityFacade.activatedRouteOutlet)
			void this._routerService.closeOutlet(this._entityFacade.activatedRouteOutlet);
	}

	private _assertApiServiceIsProvided(service: unknown): asserts service is IEntitiesApiBaseService<TEntity> {
		if (isObject(service))
			return;

		throw new Error(
			'The entities api service has not been provided, either override the api call methods or provide an api service',
		);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private readonly _generateActionsOnRouteChange = (route$: Observable<ActivatedRoute>) => route$.pipe(
		switchMap(activatedRoute => merge(
			of(this.actions.drawerRouteOutletChanged({ outlet: this._searchDrawerOutlet(activatedRoute) })),

			activatedRoute.params.pipe(mergeMap(params => merge(
				of(this.actions.activeRouteParamsChanged({ params })),
				this._generateActionOnDrawerRouteParamsChange(params),
			))),
		)),
	);

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private readonly _generateActionOnDrawerRouteParamsChange = (params: Params) => of(new DrawerRouteParams(params))
		.pipe(
			switchMap(
				drawerRouteParams => drawerRouteParams.drawerType === DrawerType.new
					? this._getFromStorageOrCreateNew$()
					: of(this._loadOrChangeDrawerType(drawerRouteParams, this._entityFacade.entity)),
			),
		);

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private _loadOrChangeDrawerType(
		{ drawerType, id, formId }: DrawerRouteParams,
		entity: TEntity | null,
	) {
		return this._tryGetEntityId(entity) === id
			? this.actions.changeDrawerRouteParams({
				drawerType,
				formId,
				entity: entity!,
			})
			: this.actions.load({
				drawerType,
				formId,
				id: id!,
			});
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private async _getFromStorageOrCreateNew$() {
		return this.actions.create({
			entity: (await this._entityFacade.getDraftEntityFromStorage()) ?? await this._buildNewEntity(),
		});
	}

	protected async _buildNewEntity(): Promise<TEntity> {
		return Promise.resolve(this._entityFacade.factory());
	}

	/**
	 * We do have different routes structures for drawers (e.g. PSPs credentials and payment option PSPs credentials).
	 * First is rendered inside drawer directly, while latter is rendered inside primary outlet of drawer
	 * (inside synthetic EmptyOutlet, see https://github.com/angular/angular/pull/23459).
	 * So to get actual drawer, we lookup for the whole tree to detect the closest drawer outlet if any.
	 */
	private _searchDrawerOutlet(activatedRoute: ActivatedRoute): string | null {
		let route: ActivatedRoute | null = activatedRoute;

		do {
			if (isRightDrawerOutlet(route.outlet))
				return route.outlet;

			route = route.parent;
		} while (route !== null);

		return null;
	}

	/**
	 * Note sometimes entity could be changed outside of drawer, and so facade may contain another one.
	 * In that cases we don't want to make some updates, and so we check against this case.
	 */
	private _facadeHasDraftOrSameEntity(entity: TEntity): boolean {
		return !this._entityFacade.entity?.id || (entity.id === this._entityFacade.entity.id);
	}

}
