import { ToastrService } from 'ngx-toastr';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { FADE, FADE_IN, FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import type { ControlsSectionScheme } from '@bp/shared/components/metadata';
import { FormMetadataEntityBaseComponent, ROW_EMPTY_CELL } from '@bp/shared/components/metadata';
import type { DTO } from '@bp/shared/models/metadata';
import { takeUntilDestroyed } from '@bp/shared/models/common';

import { FirebasePspsSharedFacade } from '@bp/admins-shared/domains/firebase-psps';

import { CreditCard } from '../../models';

@Component({
	selector: 'bp-credit-card-edit-form',
	templateUrl: './credit-card-edit-form.component.html',
	styleUrls: [ './credit-card-edit-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, FADE_IN, FADE_IN_LIST_STAGGERED ],
})
export class CreditCardEditFormComponent extends FormMetadataEntityBaseComponent<CreditCard> {

	override metadata = CreditCard.getClassMetadata();

	sections: {
		id?: string;
		title: string;
		scheme: ControlsSectionScheme<CreditCard>;
	}[] = [
			{
				title: 'Test Credit Card Details',
				scheme: [
					[ 'number' ],
					[ 'expireMonth', 'expireYear' ],
					[ 'cvv', ROW_EMPTY_CELL ],
				],
			},
			{
				id: 'service',
				title: 'Service Details',
				scheme: [[ 'paymentProvider' ]],
			},
		];

	constructor(
		public bridgerPspsSharedFacade: FirebasePspsSharedFacade,
		private readonly _parentFormEntity: FormMetadataEntityBaseComponent<CreditCard>,
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.setFormScheme({
			number: null,
			expireMonth: null,
			expireYear: null,
			paymentProvider: null,
			cvv: null,
		});

		this.form$
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => void this._parentFormEntity.form!.setControl('details-form', v!));
	}

	override factory = (v?: DTO<CreditCard>): CreditCard => new CreditCard(v);
}
