import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PresetEffects } from './state/entity/preset.effects';
import { FEATURE_KEY, reducer } from './state/feature.reducer';
import { PresetsEffects } from './state/list/presets.effects';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([
			PresetEffects,
			PresetsEffects,
		]),
	],
})
export class PresetsRootModule { }
