import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import { ControlComponent } from '@bp/shared/components/core';

import type { User } from '../../models';

@Component({
	selector: 'bp-user-selector',
	templateUrl: './user-selector.component.html',
	styleUrls: [ './user-selector.component.scss' ],
	host: {
		'(focusin)': 'onTouched()',
	},
	animations: [ FADE_IN_LIST_STAGGERED ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: UserSelectorComponent,
			multi: true,
		},
	],
})
export class UserSelectorComponent extends ControlComponent<string | null> {

	@Input() items!: User[] | null;

	isActive(user: User): boolean {
		return user.id === this.value;
	}

}
