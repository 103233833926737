type JiraIssueStatusCategory = 'done' | 'intermediate' | 'new' | 'undefined';

export enum JiraIssueStatus {
	Open = 'open',
	InProgress = 'inProgress',
	Released = 'released',
	Tested = 'tested',
	ReadyForTesting = 'readyForTesting',
	Testing = 'testing',
	CodeReview = 'codeReview',
	AnalysisDone = 'analysisDone',
	InDevelopment = 'inDevelopment',
	Cancelled = 'cancelled',
	Abandoned = 'abandoned',
	Active = 'active',
	Done = 'done',
	Backlog = 'backlog',
	SelectedForDevelopment = 'selectedForDevelopment',
	ApprovedForWork = 'approvedForWork',
	ToDo = 'toDo',
	OnHold = 'onHold',
	Assessment = 'assessment',
	Approved = 'approved',
	InReview = 'inReview',
	ReadyForReview = 'readyForReview',
}

const JIRA_ISSUES_STATUS_ID_TO_STATUS: Record<number, JiraIssueStatus | undefined> = {
	1: JiraIssueStatus.Open,
	3: JiraIssueStatus.InProgress,
	10_147: JiraIssueStatus.Assessment,
	10_176: JiraIssueStatus.Approved,
	10_177: JiraIssueStatus.InReview,
	10_179: JiraIssueStatus.ReadyForReview,
	10_120: JiraIssueStatus.ToDo,
	10_124: JiraIssueStatus.OnHold,
	10_019: JiraIssueStatus.ApprovedForWork,
	10_003: JiraIssueStatus.Released,
	10_094: JiraIssueStatus.Tested,
	10_093: JiraIssueStatus.Testing,
	10_023: JiraIssueStatus.ReadyForTesting,
	10_014: JiraIssueStatus.ReadyForTesting,
	10_091: JiraIssueStatus.InDevelopment,
	10_011: JiraIssueStatus.AnalysisDone,
	10_092: JiraIssueStatus.CodeReview,
	10_017: JiraIssueStatus.Cancelled,
	10_095: JiraIssueStatus.Abandoned,
	10_096: JiraIssueStatus.Active,
	10_108: JiraIssueStatus.Backlog,
	10_109: JiraIssueStatus.SelectedForDevelopment,
	10_110: JiraIssueStatus.Done,
};

export class JiraIssue {

	static fromJiraApiIssue({ key, fields: {
		summary, status, issuetype, project, assignee, priority, statuscategorychangedate,
	} }: IJiraApiIssue): JiraIssue {
		return new JiraIssue({
			key,
			summary,
			issueType: issuetype.name,
			issueIconUrl: `${ issuetype.iconUrl.replace('medium', 'xlarge') }&format=png`,
			assigneeName: assignee?.displayName,
			assigneeAvatarUrl: assignee?.avatarUrls['48x48'],
			statusCategory: status.statusCategory.key,
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			status: JIRA_ISSUES_STATUS_ID_TO_STATUS[status.id] ?? null,
			projectKey: project.key,
			projectName: project.name,
			projectIconUrl: project.avatarUrls['48x48'],
			priorityIconUrl: `${ priority.iconUrl }?format=png`,
			priority: priority.name,
			daysWithoutStatusChange: getDaysDifference(statuscategorychangedate),
			searchTerms: [
				...key
					.toLowerCase()
					.split('-'),
				...summary
					.toLowerCase()
					.split(/\s|\/|:|-/u)
					.filter(v => !!v),
			],
			updatedAt: new Date().toISOString(),
		});
	}

	key!: string;

	summary!: string;

	issueType!: string | 'Bug';

	issueIconUrl!: string;

	status!: JiraIssueStatus | null;

	statusCategory!: JiraIssueStatusCategory;

	searchTerms!: string[];

	displayName!: string;

	searchName!: string;

	updatedAt!: string;

	projectKey!: string;

	projectName!: string;

	projectIconUrl!: string;

	assigneeName!: string | null;

	assigneeAvatarUrl!: string | null;

	priorityIconUrl!: string;

	priority!: string;

	daysWithoutStatusChange!: number;

	get isBugIssue() {
		return this.issueType === 'Bug';
	}

	constructor(data: Partial<JiraIssue>) {
		Object.assign(this, data);

		this.displayName = `${ this.key }: ${ this.summary }`;
	}

	toString() {
		return this.displayName;
	}

}

export interface IJiraApiIssue {
	key: string;
	fields: {
		statuscategorychangedate: string;
		summary: string;
		issuetype: {
			name: string | 'Bug';
			iconUrl: string;
		};
		status: {
			id: number;
			statusCategory: {
				key: JiraIssueStatusCategory;
			};
		};
		assignee?: {
			displayName: string;
			avatarUrls: {
				'48x48': string;
			};
		};
		project: {
			name: string;
			key: string;
			avatarUrls: {
				'48x48': string;
			};
		};

		priority: {
			iconUrl: string;
			name: string;
		};
	};
}

export interface IJiraWebhook {

	webhookEvent: 'jira:issue_created' | 'jira:issue_deleted' | 'jira:issue_updated';

	issue: IJiraApiIssue;

}

function getDaysDifference(statuscategorychangedate: string): number {
	return Math.trunc(
		(Date.now() - new Date(statuscategorychangedate).getTime()) / 1000 / 24 / 60 / 60,
	);
}
