import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { SharedComponentsControlsModule } from '@bp/shared/components/controls';
import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { SharedDirectivesModule } from '@bp/shared/directives';
import { SharedFeaturesTooltipModule } from '@bp/shared/features/tooltip';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';

import {
	PspThumbnailModule
} from './components';

const EXPOSED_COMPONENTS: any[] | Type<any> = [
];

@NgModule({
	imports: [
		CommonModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatIconModule,
		MatPseudoCheckboxModule,
		MatRippleModule,
		MatSelectModule,
		ReactiveFormsModule,
		ScrollingModule,

		SharedDirectivesModule,
		SharedPipesModule,
		SharedComponentsCoreModule,
		SharedComponentsControlsModule,
		SharedFeaturesTooltipModule,
		SharedFeaturesValidationModule,

		PspThumbnailModule,
	],
	exports: [ EXPOSED_COMPONENTS, PspThumbnailModule ],
	declarations: EXPOSED_COMPONENTS,
})
export class AdminsSharedFirebasePspsModule { }
