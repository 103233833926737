import { Injectable } from '@angular/core';

import type { DTO } from '@bp/shared/models/metadata';

import { EntityFacade } from '@bp/admins-shared/features/entity';

import { Preset } from '../../models';

import { PRESET_DRAWER_ACTIONS } from './preset.actions';
import { NEW_PRESET } from './preset.reducer';
import { PRESET_DRAWER_SELECTORS } from './preset.selectors';

@Injectable({ providedIn: 'root' })
export class PresetFacade extends EntityFacade<Preset> {

	readonly actions = PRESET_DRAWER_ACTIONS;

	readonly selectors = PRESET_DRAWER_SELECTORS;

	readonly draftEntityStorageKey = NEW_PRESET;

	readonly factory = (v?: DTO<Preset>) => new Preset(v);

	share(entity: Preset) {
		this.save(this.factory({ ...entity, isShared: true }));
	}

	unshare(entity: Preset) {
		this.save(this.factory({ ...entity, isShared: false }));
	}

}
