import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import type { Preset } from '../../presets';

import { mount, resetState } from './demostand.actions';
import { getPreset } from './demostand.selectors';

@Injectable({ providedIn: 'root' })
export class DemostandFacade {
	preset$ = this._store$.select(getPreset);

	constructor(private readonly _store$: Store) { }

	mount(preset: Preset) {
		this._store$.dispatch(mount({ preset }));
	}

	resetState() {
		this._store$.dispatch(resetState());
	}
}
