import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import type { Preset } from '../../presets/models';

import { mount, resetState } from './demostand.actions';

export const FEATURE_KEY = '[section]:demostand';

export interface IState {
	preset: Preset | null;
}

export const initialState: IState = {
	preset: null,
};

const demostandReducer = createReducer(
	initialState,

	on(resetState, (): IState => initialState),

	on(mount, (state, { preset }): IState => ({ ...state, preset })),
);

export function reducer(state: IState | undefined, action: Action) {
	return demostandReducer(state, action);
}
