import { on, ActionCreator, ReducerTypes } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';
import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';

import { DrawerType } from '../../models';

import { EntityDrawerActions } from './entity.actions';
import { composeAsyncActionOverEntityReducer } from './compose-async-action-over-entity-reducer';

export type AsyncActionOverEntityState = {
	pending: boolean;
	error: BpError | null;
};

export type EntityDrawerState<TEntity extends Entity | FirebaseEntity> = {

	entity: TEntity | null;

	entityId: string | null;

	drawerType: DrawerType | null;

	/**
	 * On any non get request
	 */
	pending: boolean;

	/**
	 * On get request
	 */
	loading: boolean | null;

	error: BpError | null;

	formId: string | null;

	activatedRouteOutlet: string | null;

	asyncActionsOverEntity: {
		[actionType: string]: {
			[entityId: string]: AsyncActionOverEntityState;
		} | undefined;
	};
};

export const ENTITY_DRAWER_INITIAL_STATE: EntityDrawerState<any> = {
	entity: null,
	entityId: null,
	drawerType: null,
	formId: null,
	pending: false,
	loading: null,
	error: null,
	activatedRouteOutlet: null,
	asyncActionsOverEntity: {},
};

export function composeEntityDrawerReducer<
	TEntity extends Entity | FirebaseEntity,
	TState extends EntityDrawerState<TEntity>>(
	initialState: TState,
	actions: EntityDrawerActions<TEntity>,
): ReducerTypes<TState, ActionCreator[]>[] {
	return [

		...composeAsyncActionOverEntityReducer<TEntity, TState, TEntity>({
			action: actions.save,
			actionSuccess: actions.api.saveSuccess,
			actionFailure: actions.api.saveFailure,
		}),

		on(actions.resetState, () => <any>initialState),

		on(
			actions.load,
			(state, { id }) => ({
				...state,
				entityId: id,
				loading: true,
			}),
		),

		on(
			actions.api.loadSuccess,
			actions.api.loadFailure,
			state => ({ ...state, loading: false }),
		),

		on(
			actions.markAsPending,
			actions.save,
			actions.load,
			actions.delete,
			state => ({
				...state,
				pending: true,
			}),
		),

		on(
			actions.api.saveSuccess,
			actions.api.saveFailure,
			actions.api.loadSuccess,
			actions.api.loadFailure,
			actions.api.deleteSuccess,
			actions.api.deleteFailure,
			state => ({
				...state,
				pending: false,
			}),
		),

		on(
			actions.load,
			state => ({
				...state,
				error: null,
				formId: null,
			}),
		),

		on(
			actions.changeDrawerRouteParams,
			(state, { drawerType, formId }) => ({
				...state,
				drawerType,
				formId: formId ?? null,
				error: null,
			}),
		),

		on(
			actions.drawerRouteOutletChanged,
			(state, { outlet }) => ({
				...state,
				activatedRouteOutlet: outlet,
			}),
		),

		on(
			actions.create,
			actions.clone,
			(state, { entity }) => ({
				...state,
				entity,
				drawerType: DrawerType.new,
			}),
		),

		on(
			actions.updateLocalEntity,
			 (state, { entity }) => ({
				...state,
				entity,
			}),
		),

		on(
			actions.api.loadSuccess,
			 (state, { entity, drawerType, formId }) => ({
				...state,
				entity,
				drawerType,
				formId,
				error: null,
			}),
		),

		on(
			actions.api.loadFailure,
			 (state, { error }) => ({
				...state,
				error,
				drawerType: DrawerType.loadError,
			}),
		),

		on(
			actions.api.saveSuccess,
			state => ({
				...state,
				error: null,
			}),
		),

		on(
			actions.api.saveFailure,
			 (state, { error }) => ({
				...state,
				error,
			}),
		),
	];
}
