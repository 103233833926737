
import { without } from 'lodash-es';
import type { Moment } from 'moment';

import type { DTO } from '@bp/shared/models/metadata';
import {
	Label, FirebaseEntity, Control, FieldControlType, FieldViewType, Table, View, Default, Mapper
} from '@bp/shared/models/metadata';

import { BackofficeUserRole } from './permissions';

export class User extends FirebaseEntity {

	@Table()
	email!: string;

	@Label('Name')
	@Table()
	@Default(null)
	displayName!: string | null;

	@Label('Avatar')
	@Table()
	@View(FieldViewType.thumbnail)
	@Default(null)
	avatarUrl!: string | null;

	@Table({ sortable: true })
	@View(FieldViewType.boolean)
	@Control(FieldControlType.switch)
	@Default(false)
	isFired!: boolean;

	@Control(
		FieldControlType.chip,
		{

			list: without(BackofficeUserRole.getList(), BackofficeUserRole.almighty),
			placeholder: 'Add role...',
		},
	)
	@Mapper(BackofficeUserRole)
	@Table()
	@View(FieldViewType.chip)
	@Default([])
	roles!: BackofficeUserRole[];

	@Label('Visited')
	@View(FieldViewType.momentFromNow)
	@Table({ sortable: true })
	visitedAt!: Moment | null;

	@Label('Updated')
	@View(FieldViewType.momentFromNow)
	@Table({ sortable: true, defaultSortField: true })
	override updatedAt!: Moment | null;

	@Table({ sortable: true })
	override updatedBy!: string;

	constructor(dto?: DTO<User>) {
		super(dto);
	}

}
