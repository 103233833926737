import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE_IN, SLIDE_UP } from '@bp/shared/animations';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { TitleService } from '@bp/shared/services';
import { filterPresent } from '@bp/shared/rxjs';

import { DrawerType, RightDrawerEntityHostPageComponent } from '@bp/admins-shared/features/entity';

import { IdentityFacade } from '@bp/backoffice/domains/identity';

import { DemostandFacade } from '../../../demostand/state';
import { PresetFacade } from '../../state';
import type { Preset } from '../../models';

@Component({
	selector: 'bp-preset-page',
	templateUrl: './preset-page.component.html',
	styleUrls: [ './preset-page.component.scss' ],
	host: {
		class: 'right-drawer-content',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE_UP, FADE_IN ],
})
export class PresetPageComponent extends RightDrawerEntityHostPageComponent<Preset> {

	canEdit!: boolean;

	canEdit$ = combineLatest([
		this.presetFacade.entity$.pipe(filterPresent),
		this._identityFacade.user$.pipe(filterPresent),
	])
		.pipe(
			map(([ entity, user ]) => (this.canEdit = user.isAlmighty || entity.authorUid === user.id)),
			takeUntilDestroyed(this),
		);

	testingDetailsFormId = 'testing-details-form';

	showTestingDetailsView$ = this.presetFacade.drawerTypeAndFormId$.pipe(
		map(([ type, formId ]) => type === DrawerType.view
			|| type === DrawerType.edit && formId !== this.testingDetailsFormId),
	);

	showTestingDetailsForm$ = this.presetFacade.drawerTypeAndFormId$.pipe(
		map(([ type, formId ]) => type === DrawerType.new
			|| type === DrawerType.edit && formId === this.testingDetailsFormId),
	);

	cashierDetailsFormId = 'cashier-details-form';

	showCashierDetailsView$ = this.presetFacade.drawerTypeAndFormId$.pipe(
		map(([ type, formId ]) => type === DrawerType.view
			|| type === DrawerType.edit && formId !== this.cashierDetailsFormId),
	);

	showCashierDetailsForm$ = this.presetFacade.drawerTypeAndFormId$.pipe(
		map(([ type, formId ]) => type === DrawerType.new
			|| type === DrawerType.edit && formId === this.cashierDetailsFormId),
	);

	constructor(
		public presetFacade: PresetFacade,
		public demostandFacade: DemostandFacade,
		private readonly _identityFacade: IdentityFacade,
		title: TitleService,
	) {
		super();

		this.presetFacade.entity$
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => void title.setMaskValue({ preset: v?.name ?? 'New' }));
	}
}
