import { environmentExtender } from './environment.extender';

export const environment = environmentExtender({

	name: 'staging',

	cashierLoaderUrl: 'https://checkout.{{hostDomain}}/v2/loader',

	googleTagTransportUrl: 'https://analytics.bridgerpay.dev',

	merchantAdminUrl: 'https://dash.bridgerpay.dev',

	api: 'relative',

});
