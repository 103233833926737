<bp-entities-table-card [facade]="creditCardsFacade">
	<table class="page-table" mat-table [dataSource]="(creditCardsFacade.records$ | async)!" [trackBy]="trackById">
		<ng-container matColumnDef="menu">
			<td *matCellDef="let row" mat-cell>
				<button mat-icon-button [matMenuTriggerFor]="rowMenu" (click)="$event.stopPropagation()">
					<mat-icon>more_vert</mat-icon>

					<mat-menu #rowMenu="matMenu" class="row-menu">
						<a
							mat-menu-item
							[outletLinkRelativeToParent]="{
								'[right-drawer][1]': ['credit-card', { id: row.id, drawerType: 'edit' }]
							}"
						>
							Edit
						</a>

						<button
							type="button"
							mat-menu-item
							[disabled]="!!identityFacade.user?.canEdit(row.authorUid)"
							(click)="creditCardFacade.delete(row)"
						>
							Delete
						</button>
					</mat-menu>
				</button>
			</td>
		</ng-container>

		<ng-container *ngFor="let md of columnsMetadata$ | async as list; let i = index" [matColumnDef]="md.property">
			<ng-container *ngIf="!md.table!.headless">
				<th
					*matHeaderCellDef
					class="nowrap text-center"
					[attr.colspan]="list | colspan: i:(i === 0 ? 1 : 0)"
					mat-header-cell
				>
					{{ md.property === 'expireMonth' ? 'Expire' : md.label }}
				</th>
			</ng-container>

			<td
				*matCellDef="let row"
				mat-cell
				[outletLinkRelativeToParent]="{
					'[right-drawer][1]': ['credit-card', { id: row.id, drawerType: 'view' }]
				}"
			>
				<ng-container [ngSwitch]="md.property">
					<bp-avatar *ngSwitchCase="'authorUid'" [id]="row[md.property]"> </bp-avatar>

					<ng-container *ngSwitchCase="'expireMonth'">
						{{ row.expireMonth }}/{{ row.expireYear }}
					</ng-container>

					<bp-property-metadata-view
						*ngSwitchDefault
						[metadata]="md"
						[table]="md.table"
						[value]="row[md.property]"
					>
					</bp-property-metadata-view>
				</ng-container>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="(headerColumnsIds$ | async)!" mat-header-row @instantHideOnVoid></tr>

		<tr
			*matRowDef="let row; columns: (displayedColumnsIds$ | async)!"
			mat-row
			@instantHideOnVoid
			class="clickable"
			[class.active]="
				(creditCardFacade.isOpenedDrawer$ | async) && row.id === (creditCardFacade.entity$ | async)?.id
			"
		></tr>
	</table>
</bp-entities-table-card>
