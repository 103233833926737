import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CreditCardEffects } from './state/entity/credit-card.effects';
import { FEATURE_KEY, reducer } from './state/feature.reducer';
import { CreditCardsEffects } from './state/list/credit-cards.effects';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([
			CreditCardEffects,
			CreditCardsEffects,
		]),
	],
})
export class CreditCardsRootModule { }
