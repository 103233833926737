import { createFeatureSelector } from '@ngrx/store';

import { composeIdentitySelectors } from '@bp/shared-domains-identity';

import type { Identity } from '../models';

import type { IState } from './identity.reducer';
import { FEATURE_STATE_KEY } from './identity.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_STATE_KEY);

export const IDENTITY_SELECTORS = composeIdentitySelectors<Identity, IState>(selectFeatureState);
