import type { EntitiesListActions } from '@bp/admins-shared/features/entity';
import { composeEntitiesListActions } from '@bp/admins-shared/features/entity';

import type { Preset, PresetsQueryParams } from '../../models';

import { ACTIONS_SCOPE, PRESETS_LIST_API_ACTIONS } from './presets-api.actions';

export const PRESETS_LIST_ACTIONS: EntitiesListActions<Preset, PresetsQueryParams> = {

	...composeEntitiesListActions(ACTIONS_SCOPE),

	api: PRESETS_LIST_API_ACTIONS,

};
