import { createReducer } from '@ngrx/store';

import type { EntityDrawerState } from '@bp/admins-shared/features/entity';
import { composeEntityDrawerReducer, ENTITY_DRAWER_INITIAL_STATE } from '@bp/admins-shared/features/entity';

import type { CreditCard } from '../../models';

import { CREDIT_CARD_DRAWER_ACTIONS } from './credit-card.actions';

export const NEW_CREDIT_CARD = 'new-credit-card';

export interface IState extends EntityDrawerState<CreditCard> { }

export const initialState: IState = {

	...ENTITY_DRAWER_INITIAL_STATE,

};

export const reducer = createReducer(
	initialState,

	...composeEntityDrawerReducer(initialState, CREDIT_CARD_DRAWER_ACTIONS),

);
