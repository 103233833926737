import { firstValueFrom } from 'rxjs';

import { HttpClientModule } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { SharedComponentsCoreModule } from '@bp/shared/components/core';
import { environment } from '@bp/shared/environments';
import { SharedFeaturesValidationModule } from '@bp/shared/features/validation';
import { SharedPipesModule } from '@bp/shared/pipes';
import {
	FirebaseAppConfig, PLATFORM, FIREBASE_APP_CONFIG, SharedServicesModule, TelemetryService, FirebaseService, MockFirebaseService, MockedBackendState
} from '@bp/shared/services';
import {
	LOCAL_SERVER_META_REDUCERS_PROVIDERS, REMOTE_SERVER_META_REDUCERS_PROVIDERS, sharedReducer
} from '@bp/shared/state';
import { Platform } from '@bp/shared/typings';
import { ensureType } from '@bp/shared/utilities';

import { AdminsSharedFirebasePspsRootModule } from '@bp/admins-shared/domains/firebase-psps';
import { AdminsSharedFeaturesLayoutRootModule } from '@bp/admins-shared/features/layout';
import { AdminsSharedFeaturesNotificationsHubRootModule } from '@bp/admins-shared/features/notifications-hub';
import { AdminsSharedPagesErrorsModule } from '@bp/admins-shared/pages/errors';
import { CONTROL_DEFAULT_OPTIONS_PROVIDERS } from '@bp/admins-shared/core/models';

import { BackofficeDomainsIdentityRootModule } from '@bp/backoffice/domains/identity';
import { PresetsRootModule } from '@bp/backoffice/cashier-demostand/presets';
import { CreditCardsRootModule } from '@bp/backoffice/cashier-demostand/credit-cards';

import { AppRoutingModule } from './app-routing.module';
import { AppStartupService } from './app-startup.service';
import { CoreModule, RootComponent } from './core';
import { AppEffects } from './state/app.effects';
import { APP_FEATURE_KEY, reducer } from './state/app.reducer';
import { LoginModule } from './sections/login';
import { CashierDemostandRoutingModule } from './sections/cashier-demostand/cashier-demostand-routing.module';

TelemetryService.log('App module execution begun');

@NgModule({
	imports: [
		HttpClientModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.isRemoteServer,
			registrationStrategy: 'registerWhenStable:5000',
		}),

		// Redux related
		StoreModule.forRoot(sharedReducer, {
			runtimeChecks: {

				/*
				 * StrictActionSerializability: false,
				 * StrictStateSerializability: false
				 */
				strictStateImmutability: false,
				strictActionImmutability: false,

				/*
				 * We dont use the built-in immutability check because
				 * it freezes the whole moment structure
				 * so we utilize custom immutabilityCheckMetaReducer
				 */
			},
		}),
		StoreModule.forFeature(APP_FEATURE_KEY, reducer),
		EffectsModule.forRoot([ AppEffects ]),
		StoreRouterConnectingModule.forRoot({
			routerState: RouterState.Minimal,
			navigationActionTiming: NavigationActionTiming.PostActivation,
		}),

		SharedPipesModule.forRoot(),
		SharedServicesModule.forRoot(),
		SharedComponentsCoreModule.forRoot(),
		SharedFeaturesValidationModule.forRoot(),
		AdminsSharedFirebasePspsRootModule,
		AdminsSharedFeaturesLayoutRootModule,
		AdminsSharedFeaturesNotificationsHubRootModule,
		AppRoutingModule,
		PresetsRootModule,
		CreditCardsRootModule,
		LoginModule,
		CashierDemostandRoutingModule,
		BackofficeDomainsIdentityRootModule,
		CoreModule,
		AdminsSharedPagesErrorsModule, // Should be the last module with routes to properly catch all notfound routes
	],
	providers: [
		environment.isRemoteServer ? REMOTE_SERVER_META_REDUCERS_PROVIDERS : LOCAL_SERVER_META_REDUCERS_PROVIDERS,
		CONTROL_DEFAULT_OPTIONS_PROVIDERS,
		{
			provide: FIREBASE_APP_CONFIG,
			useValue: <FirebaseAppConfig> {
				appId: '1:977741303368:web:30375b7774af4c6f00927d',
				enablePersistence: true,
				hasAuth: true,
			},
		},
		{
			provide: FirebaseService,
			useClass: MockedBackendState.isActive ? MockFirebaseService : FirebaseService,
		},
		{
			provide: PLATFORM,
			useValue: ensureType<Platform>('backoffice'),
		},
	],
	bootstrap: [ RootComponent ],
})
export class AppModule {

	constructor(
		private readonly _appStartupService: AppStartupService,
		private readonly _app: ApplicationRef,
	) {
		void this._whenAppIsStableInitStartupLogic();
	}

	private async _whenAppIsStableInitStartupLogic(): Promise<void> {
		await firstValueFrom(this._app.isStable);

		this._appStartupService.init();
	}

}
