<mat-toolbar color="primary">
	<div class="left">
		<bp-burger-btn
			class="btn-menu"
			[showCross]="(layoutFacade.showSidenav$ | async) === true"
			(click)="layoutFacade.showSidenav ? layoutFacade.closeSidenav() : layoutFacade.openSidenav()"
		>
		</bp-burger-btn>

		<a
			mat-button
			class="logo"
			[routerLink]="['/cashier-demostand/presets', (identityFacade.user$ | async)!.id]"
			(click)="layoutFacade.closeSidenav()"
		>
			<bp-svg-icon name="logo-short-text"></bp-svg-icon>
		</a>

		<ng-content></ng-content>
	</div>

	<div class="right">
		<ng-content select=".ng-content-toolbar-right-section"></ng-content>

		<mat-menu
			#userMenu="matMenu"
			class="user-menu"
			routerLinkActive="active-link"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			<button mat-menu-item (click)="identityFacade.confirmLogout()">Log Out</button>
		</mat-menu>

		<button class="user btn-icon" mat-icon-button [matMenuTriggerFor]="userMenu">
			<bp-avatar [id]="(identityFacade.user$ | async)?.id"> </bp-avatar>
		</button>
	</div>
</mat-toolbar>
