import { BehaviorSubject, from, Subscription } from 'rxjs';

import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import type { User } from '../../models';
import { UsersApiService } from '../../services';

@Component({
	selector: 'bp-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: [ './avatar.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent extends Destroyable implements OnChanges {

	@Input() id?: string | null;

	@Input() email?: string | null;

	@Input() user?: User | null;

	user$ = new BehaviorSubject<User | null | undefined>(null);

	showPlaceholder = false;

	private _userByEmailSubscription = Subscription.EMPTY;

	constructor(private readonly _usersApi: UsersApiService) {
		super();
	}

	ngOnChanges({ id, email, user }: Partial<SimpleChanges>): void {
		if (id && this.id) {
			from(this._usersApi.get(this.id))
				.pipe(takeUntilDestroyed(this))
				.subscribe(v => void this.user$.next(v));
		}

		if (email && this.email) {
			this._userByEmailSubscription.unsubscribe();

			this._userByEmailSubscription = this._usersApi.listenToUserChangesByEmail(this.email)
				.pipe(takeUntilDestroyed(this))
				.subscribe(v => void this.user$.next(v));
		}

		if (user)
			this.user$.next(this.user);
	}

}
