import { defer } from 'rxjs';
import { map, repeatWhen, take } from 'rxjs/operators';

import { Params } from '@angular/router';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';
import { bpQueueMicrotask } from '@bp/shared/utilities';

import { EntitiesListSelectors } from './compose-entities-list-selectors';
import { EntitiesListActions } from './entities.actions';

@Injectable({ providedIn: 'root' })
export abstract class EntitiesListFacade<
	TEntity extends Entity | FirebaseEntity,
	TLoadQueryParams
> {

	readonly abstract actions: EntitiesListActions<TEntity, TLoadQueryParams>;

	readonly abstract selectors: EntitiesListSelectors<TEntity>;

	readonly recordsPage$ = defer(() => this._store$.select(this.selectors.recordsPage));

	readonly page$ = defer(() => this._store$.select(this.selectors.page));

	readonly pending$ = defer(() => this._store$.select(this.selectors.pending));

	readonly error$ = defer(() => this._store$.select(this.selectors.error));

	readonly records$ = this.recordsPage$.pipe(
		map(v => (this.records = v ? v.records : [])),
	);

	records: TEntity[] = [];

	readonly isEmptyRecords$ = this.records$.pipe(
		map(v => v.length === 0),
	);

	readonly reset$ = defer(() => this._actions$.pipe(
		ofType(this.actions.resetState),
	));

	/**
	 * Any entity update happened during lifecycle of the entity in the loaded list
	 */
	readonly updateLocalEntity$ = defer(() => this._actions$.pipe(
		ofType(this.actions.updateLocalEntity),
	));

	readonly isFirstPending$ = this.pending$.pipe(
		take(2),
		// eslint-disable-next-line rxjs/no-ignored-notifier
		repeatWhen(() => this.reset$),
	);

	constructor(
		protected _store$: Store,
		protected _actions$: Actions,
	) {
		// at the end of the event loop to be sure the selectors and actions are set
		bpQueueMicrotask(() => void this._updateRecordsPropertyOnStateChange());
	}

	abstract queryParamsFactory(params: Params): TLoadQueryParams;

	/**
	 * Loads one page of the records
	 */
	load(query?: TLoadQueryParams): void {
		this._store$.dispatch(this.actions.load({ query }));
	}

	queryParamsChange(query?: TLoadQueryParams): void {
		this._store$.dispatch(this.actions.queryParamsChanged({ query }));
	}

	changePage(page?: string): void {
		this._store$.dispatch(this.actions.changePage({ page }));
	}

	/**
	 * Load entity from the back with the same query params used to load the entity
	 */
	refresh(): void {
		this._store$.dispatch(this.actions.refresh());
	}

	updateLocalEntity(entity: TEntity): void {
		this._store$.dispatch(this.actions.updateLocalEntity({ entity }));
	}

	resetState(): void {
		this._store$.dispatch(this.actions.resetState());
	}

	private _updateRecordsPropertyOnStateChange(): void {
		this.records$
			.subscribe(records => (this.records = records));
	}

}
