<mat-form-field
	[color]="color"
	[appearance]="_matAppearance"
	[hideRequiredMarker]="_defaultOptions?.hideRequiredMarker"
	[floatLabel]="_floatLabel"
	[class.error-hidden]="hideErrorText"
>
	<mat-label *ngIf="label">{{ label | bpTranslate | async }}</mat-label>

	<mat-select
		[formControl]="_internalControl"
		[placeholder]="(placeholder | bpTranslate | async) ?? ''"
		[required]="!!required"
		[multiple]="multiple"
		[panelClass]="panelClass"
		[disabled]="!!disabled"
		[disableOptionCentering]="disableOptionCentering"
		(selectionChange)="selectionChange.emit($event)"
	>
		<mat-select-trigger>
			<ng-container *ngIf="multiple; else singleValueTpl">
				{{ _internalControl?.value?.length }}
				{{ itemKind | pluralizeBy: _internalControl?.value?.length }} selected
			</ng-container>

			<ng-template #singleValueTpl>
				{{ _internalControl.value | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async }}
			</ng-template>
		</mat-select-trigger>

		<mat-option *ngIf="!required" #resetOption class="reset-option" [ngClass]="optionClass!" [value]="_resetOption">
			{{ resetOptionText | bpTranslate | async }}
		</mat-option>

		<mat-option
			*ngFor="let item of items;"
			bpTooltipShownOnViewportOverflow
			[value]="useItemValueOf ? (item | bpValueOf) : item"
			[ngClass]="optionClass!"
			[bpTooltip]="item | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async"
		>
			<span bpShowTooltipOnViewportOverflow bpViewportOverflownObserver>
				<ng-template
					*ngIf="_customSelectOption; else defaultSelectOptionViewTpl"
					[ngTemplateOutlet]="_customSelectOption.tpl"
					[ngTemplateOutletContext]="{ $implicit: item }"
				></ng-template>

				<ng-template #defaultSelectOptionViewTpl>
					{{ item | bpDisplayValue: itemDisplayValuePropertyName | bpTranslate | async }}
				</ng-template>
			</span>
		</mat-option>
	</mat-select>

	<mat-hint *ngIf="hint">{{ hint | bpTranslate | async }}</mat-hint>

	<mat-error [bpFieldError]="name" [bpFieldErrorSuppress]="hideErrorText"></mat-error>
</mat-form-field>
