import { ReducerTypes, ActionCreator, on } from '@ngrx/store';

import { Entity, FirebaseEntity } from '@bp/shared/models/metadata';
import { Action } from '@bp/shared/typings';

import { EntityAsyncActionFailurePayload, EntityAsyncActionPayload, EntityAsyncActionSuccessPayload } from '../../models';

import { EntityDrawerState } from './compose-entity-drawer-reducer';

export function composeAsyncActionOverEntityReducer<
	TEntity extends Entity | FirebaseEntity,
	TState extends EntityDrawerState<TEntity>,
	TSuccessResult extends TEntity | undefined = undefined>(
	{
		action,
		actionSuccess,
		actionFailure,
	}: {
		action: Action<EntityAsyncActionPayload<TEntity>>;
		actionSuccess: Action<EntityAsyncActionSuccessPayload<TEntity, TSuccessResult>>;
		actionFailure: Action<EntityAsyncActionFailurePayload<TEntity>>;
	},
): ReducerTypes<TState, ActionCreator[]>[] {
	return [
		on(action, (state, { entity }) => ({
			...state,
			asyncActionsOverEntity: {
				...state.asyncActionsOverEntity,
				[action.type]: {
					...state.asyncActionsOverEntity[action.type],
					[entity.id!]: {
						pending: true,
						error: null,
					},
				},
			},
		})),

		on(actionSuccess, actionFailure, (state, { entity }) => ({
			...state,
			asyncActionsOverEntity: {
				...state.asyncActionsOverEntity,
				[action.type]: {
					...state.asyncActionsOverEntity[action.type],
					[entity.id!]: {
						pending: false,
					},
				},
			},
		})),

		on(actionFailure, (state, { entity, error }) => ({
			...state,
			asyncActionsOverEntity: {
				...state.asyncActionsOverEntity,
				[action.type]: {
					...state.asyncActionsOverEntity[action.type],
					[entity.id!]: {
						error,
					},
				},
			},
		})),
	];
}
