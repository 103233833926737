import { Injectable } from '@angular/core';

import type { DTO } from '@bp/shared/models/metadata';

import { EntityFacade } from '@bp/admins-shared/features/entity';

import { CreditCard } from '../../models';

import { CREDIT_CARD_DRAWER_ACTIONS } from './credit-card.actions';
import { NEW_CREDIT_CARD } from './credit-card.reducer';
import { CREDIT_CARD_DRAWER_SELECTORS } from './credit-card.selectors';

@Injectable({ providedIn: 'root' })
export class CreditCardFacade extends EntityFacade<CreditCard> {

	readonly actions = CREDIT_CARD_DRAWER_ACTIONS;

	readonly selectors = CREDIT_CARD_DRAWER_SELECTORS;

	readonly draftEntityStorageKey = NEW_CREDIT_CARD;

	readonly factory = (v?: DTO<CreditCard>) => new CreditCard(v);

}
