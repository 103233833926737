import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppFacade } from '@bp/backoffice/app-state';

@Component({
	selector: 'bp-presets-page',
	templateUrl: './presets-page.component.html',
	styleUrls: [ './presets-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetsPageComponent {

	isPrivate = <boolean> this._route.snapshot.data['isPrivate'];

	constructor(
		public appFacade: AppFacade,
		private readonly _route: ActivatedRoute,
	) { }

}
