import { createAction } from '@ngrx/store';

import type { EntitiesListActions } from '@bp/admins-shared/features/entity';
import { composeEntitiesListActions } from '@bp/admins-shared/features/entity';

import type { CommonQueryParams } from '@bp/backoffice/cashier-demostand/models';

import type { CreditCard } from '../../models';

import { ACTIONS_SCOPE, CREDIT_CARDS_LIST_API_ACTIONS } from './credit-cards-api.actions';

export const CREDIT_CARDS_LIST_ACTIONS: EntitiesListActions<CreditCard, CommonQueryParams> = {

	...composeEntitiesListActions(ACTIONS_SCOPE),

	api: CREDIT_CARDS_LIST_API_ACTIONS,

};

export const listenToFirestoreCollectionUpdates = createAction(
	`${ ACTIONS_SCOPE } Listen To Firestore Collection Updates`,
);
