import type { Moment } from 'moment';
import moment from 'moment';

import { OUT_OF_AGE_DATE, Validators } from '@bp/shared/features/validation';
import { PaymentOptionType } from '@bp/shared/models/business';
import type { CashierLanguage } from '@bp/shared/models/cashier';
import { cashierLanguageMapper, CashierLanguages } from '@bp/shared/models/cashier';
import type { ICashierConfiguration } from '@bp/shared/models/cashier-core';
import { CashierEnvironment, CashierLaunchButtonMode, CashierTheme, AlwaysVisibleInputsForProvidersScheme } from '@bp/shared/models/cashier-core';
import type { Country, State } from '@bp/shared/models/countries';
import { countryMapper, stateMapper } from '@bp/shared/models/countries';
import { Currency } from '@bp/shared/models/currencies';
import type { DTO } from '@bp/shared/models/metadata';
import {
	Control, Default, FieldControlType, FieldViewType, FirebaseEntity, Hint, Label, Mapper,
	momentMapper, Required, Table, Validator, View, booleanMapper, uriComponentObjectMapper
} from '@bp/shared/models/metadata';
import type { NonFunctionPropertyNames } from '@bp/shared/typings';

export type PresetKeys = NonFunctionPropertyNames<Preset>;

export class Preset extends FirebaseEntity implements ICashierConfiguration {

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Hint('Whether the preset is shared with everyone in bridgerpay or not')
	@Default(false)
	@Table()
	isShared!: boolean;

	@Label('Title')
	@Required()
	@Default('Untitled Preset')
	@Table()
	override name!: string;

	@Table()
	@Default(null)
	override authorUid!: string | null;

	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	@Table()
	@Default(null)
	override updatedAt!: Moment | null;

	@Control(FieldControlType.select, {
		list: CashierEnvironment.getList(),
	})
	@Required()
	@Mapper(CashierEnvironment)
	@Table()
	@Default(CashierEnvironment.stagingV2)
	environment!: CashierEnvironment;

	@Default([])
	searchTerms!: string[];

	@Default(null)
	jiraIssueKey!: string | null;

	@Label('Test Credit Card ')
	@Table()
	@Default(null)
	creditCardId!: string | null;

	readonly url: string | null;

	// #region SECTION ICashierConfiguration

	// #region SECTION Cashier Config Parameters Public to Merchants

	@Required()
	@Table({ ellipsis: true })
	@Mapper((v: string) => v.toLowerCase())
	cashierKey!: string;

	@Label('Cashier Token')
	@Required()
	@Table({ ellipsis: true })
	@Default(null)
	cashierSessionId!: string | null;

	@Control(FieldControlType.number)
	@View(FieldViewType.currency)
	@Table()
	@Default(null)
	amount!: number | null;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	amountLock!: boolean;

	@Control(FieldControlType.autocomplete, {
		list: Currency.list,
	})
	@Required()
	@Mapper(Currency)
	@View(FieldViewType.currencyCode)
	@Default(null)
	currency!: Currency | null;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	currencyLock!: boolean;

	// #region SECTION ICashierConfiguration

	@Control(FieldControlType.country)
	@Mapper(countryMapper)
	@Required()
	@View(FieldViewType.country)
	@Table()
	@Default(null)
	country!: Country | null;

	@Mapper(stateMapper)
	@Default(null)
	state!: State | null;

	@Default(null)
	firstName!: string | null;

	@Default(null)
	lastName!: string | null;

	@Default(null)
	phone!: string | null;

	@Default(null)
	address!: string | null;

	@View(FieldViewType.email)
	@Default(null)
	email!: string | null;

	@Default(null)
	city!: string | null;

	@Default(null)
	zipCode!: string | null;

	@Default(null)
	cardHolderName!: string | null;

	// #endregion !SECTION

	@Control(FieldControlType.select, {
		list: PaymentOptionType.getList(),
	})
	@Mapper(PaymentOptionType)
	@Default(null)
	directPaymentMethod!: PaymentOptionType | null;

	@Control(FieldControlType.select, {
		list: PaymentOptionType.getList(),
	})
	@Mapper(PaymentOptionType)
	@Default(null)
	singlePaymentMethod!: PaymentOptionType | null;

	@Default(null)
	singlePaymentProvider!: string | null;

	@Table()
	@Label('Payment Provider')
	paymentProvider!: string | null;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	hideHeader!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	showFooter!: boolean;

	@Label('Show The Redirect Message on The Deposit Result Page')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	showRedirectMessage!: boolean;

	@Label('Show a single payment box')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	dontSkipSinglePaymentBox!: boolean;

	@Default(null)
	orderId!: string | null;

	@Default(null)
	trackingId!: string | null;

	@Default(null)
	affiliateId!: string | null;

	@Default(null)
	platformId!: string | null;

	@Validator(Validators.ip)
	@Default(null)
	ip!: string | null;

	@Control(FieldControlType.country, {
		list: CashierLanguages.list.map(v => v.country),
	})
	@Mapper(cashierLanguageMapper)
	@View(FieldViewType.country)
	@Default(null)
	language!: CashierLanguage | null;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	hideLanguagesDropdown!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Default(false)
	languagesDropdownOnLeftSide!: boolean;

	@Default(null)
	depositButtonText!: string | null;

	@Mapper(uriComponentObjectMapper)
	@Default({})
	alwaysVisibleInputsForProviders!: AlwaysVisibleInputsForProvidersScheme;

	@Label('Disable Processing Overlays')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Mapper(booleanMapper)
	@Default(false)
	disableProcessingMessages!: boolean;

	@Label('Validate Card\'s Brand')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Mapper(booleanMapper)
	@Default(false)
	enableFilterByCardBrand!: boolean;

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Mapper(booleanMapper)
	@Default(false)
	validateInputsOnFocusOut!: boolean;

	@Label('The Save Payment Card Is Checked By Default')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Mapper(booleanMapper)
	@Default(false)
	saveCreditCardFlagCheckedByDefault!: boolean;

	@Label('Use \'Pay\' word instead of \'Deposit\'')
	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Mapper(booleanMapper)
	@Default(false)
	payMode!: boolean;

	// #endregion !SECTION

	// #region SECTION Appearance on the cashier form controlled by the back

	@Control(FieldControlType.date, {
		validator: Validators.afterDate(OUT_OF_AGE_DATE),
		typeControlOptions: {
			startAt: moment([ 1990, 1, 1 ]),
			max: OUT_OF_AGE_DATE,
			startView: 'multi-year',
		},
	})
	@View(FieldViewType.moment, () => 'LL')
	@Mapper(momentMapper)
	@Default(null)
	birthDate!: Moment | null;

	@Control(FieldControlType.select, {
		list: [ 'female', 'male' ],
	})
	@Default(null)
	gender?: 'female' | 'male' | null;

	@Default(null)
	personalId?: string | null;

	@Default(null)
	bankAccountName?: string | null;

	// #endregion !SECTION

	// #region SECTION Cashier Loader Parameters Public To Merchants

	@Control(FieldControlType.buttonToggle, {
		list: CashierLaunchButtonMode.getList(),
	})
	@Mapper(CashierLaunchButtonMode)
	@Default(null)
	buttonMode!: CashierLaunchButtonMode | null;

	@Default(null)
	buttonText!: string | null;

	// #endregion !SECTION

	// #region SECTION Theming

	@Control(FieldControlType.buttonToggle, {
		list: CashierTheme.getList(),
	})
	@Mapper(CashierTheme)
	@Default(CashierTheme.transparent)
	theme!: CashierTheme | null;

	@Default(null)
	darkThemeBackgroundColor!: string | null;

	@Default(null)
	splashScreenBackgroundColor!: string | null;

	@View(FieldViewType.link)
	@Default(null)
	splashScreenImageUrl!: string | null;

	// #endregion !SECTION

	// #region SECTION Internal Cashier Config Parameters Not Public To Merchants Or The Backend

	@Control(FieldControlType.switch)
	@View(FieldViewType.boolean)
	@Label('Use Local Backend')
	@Default(false)
	$$useLocalBackend!: boolean;

	readonly $$presetId: string | null = null;

	// #endregion !SECTION

	// #endregion !SECTION

	constructor(data?: DTO<Preset>) {
		super(data);

		this.url = this.id ? `${ location.origin }/demostand;preset=${ this.id }` : null;

		if (!this.id)
			this.isShared = false;

		if (this.jiraIssueKey)
			this.isShared = true;

		this.searchTerms = this.name
			.toLowerCase()
			.split(/[\s/:-]/u)
			.filter(v => !!v);

		this.$$presetId = this.id;
	}
}
