import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IdentityFacade } from '@bp/backoffice/domains/identity';
import { BackofficeFeature } from '@bp/backoffice/domains/users';

@Component({
	selector: 'bp-menu',
	templateUrl: './menu.component.html',
	styleUrls: [ './menu.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	BackofficeFeature = BackofficeFeature;

	constructor(public identityFacade: IdentityFacade) { }

}
