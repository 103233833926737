import { createSelector } from '@ngrx/store';

import { composeEntitiesListSelectors } from '@bp/admins-shared/features/entity';

import type { CreditCard } from '../../models';
import { getListState } from '../feature.selectors';

import type { IState } from './credit-cards.reducer';

export const CREDIT_CARDS_LIST_SELECTORS = composeEntitiesListSelectors<CreditCard, IState>(getListState);

export const getAllCreditCards = createSelector(
	getListState,
	s => s.all,
);
