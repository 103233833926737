import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { filterPresent } from '@bp/shared/rxjs';
import { EnvironmentService } from '@bp/shared/services';
import type { FirebasePsp } from '@bp/shared/models/firebase-psps';
import { FirebasePspEnvironment } from '@bp/shared/models/firebase-psps';

import { load, update } from './firebase-psps-shared.actions';
import {
	getPubliclyVisibleFirebasePsps, getAllFirebasePsps, getAllFirebasePspsByPspNameMap, getError, getPending, getAllFirebasePspsInternalNames, getForEnvironmentByPspNameMap, getForEnvironmentByPaymentOptionTypeMap, getForEnvironment
} from './firebase-psps-shared.selectors';

@Injectable({
	providedIn: 'root',
})
export class FirebasePspsSharedFacade {

	currentFirebasePspEnvironment = this._environment.isLocalServer
		? FirebasePspEnvironment.staging
		: FirebasePspEnvironment.parseStrict(this._environment.name);

	all$ = this._store$
		.select(getAllFirebasePsps)
		.pipe(filterPresent);

	allByPspNameMap$ = this._store$
		.select(getAllFirebasePspsByPspNameMap)
		.pipe(filterPresent);

	allInternalNames$ = this._store$
		.select(getAllFirebasePspsInternalNames)
		.pipe(filterPresent);

	publiclyVisible$ = this._store$
		.select(getPubliclyVisibleFirebasePsps)
		.pipe(filterPresent);

	forCurrentEnvironment$ = this._store$
		.select(getForEnvironment(this.currentFirebasePspEnvironment))
		.pipe(filterPresent);

	forCurrentEnvironmentByPspNameMap$ = this._store$
		.select(getForEnvironmentByPspNameMap(this.currentFirebasePspEnvironment))
		.pipe(filterPresent);

	forCurrentEnvironmentByPaymentOptionTypeMap$ = this._store$
		.select(getForEnvironmentByPaymentOptionTypeMap(this.currentFirebasePspEnvironment))
		.pipe(filterPresent);

	pending$ = this._store$.select(getPending);

	error$ = this._store$.select(getError);

	constructor(
		private readonly _store$: Store,
		private readonly _environment: EnvironmentService,
	) { }

	load(): void {
		this._store$.dispatch(load());
	}

	update(psps: FirebasePsp[]): void {
		this._store$.dispatch(update({ psps }));
	}

}
