<bp-right-drawer-entity
	#drawer
	titlePlaceholder="Test Credit Card Title"
	[facade]="creditCardFacade"
	[entity]="creditCardFacade.entity$ | async"
	[pending]="creditCardFacade.pending$ | async"
	[firstLoading]="creditCardFacade.isFirstLoading$ | async"
	[factory]="creditCardFacade.factory"
	[drawerType]="creditCardFacade.drawerType$ | async"
>
	<bp-right-drawer-header-buttons
		[entity]="creditCardFacade.entity$ | async"
		[canSave]="drawer.formDirtyAndValid$ | async"
		(delete)="creditCardFacade.delete(drawer.entity!)"
	>
	</bp-right-drawer-header-buttons>

	<bp-credit-card-edit-form
		*ngIf="creditCardFacade.isEditLikeDrawer$ | async"
		class="card card-edit"
		[error]="creditCardFacade.error$ | async"
		[pending]="creditCardFacade.pending$ | async"
		[entity]="creditCardFacade.entity$ | async"
		(entityChange)="drawer.updateDrawerEntityAndSaveDraftToStorage($event)"
		(submitted)="drawer.submit()"
	>
	</bp-credit-card-edit-form>

	<bp-credit-card-view
		*ngIf="(creditCardFacade.drawerType$ | async) === DrawerType.view"
		class="card card-view"
		[class.can-edit]="identityFacade.user!.canEdit((creditCardFacade.entity$ | async)?.authorUid!)"
		[entity]="creditCardFacade.entity$ | async"
		(click)="identityFacade.user!.canEdit(drawer.entity!.authorUid!) && drawer.setDrawerRouteParamsToEdit()"
	>
	</bp-credit-card-view>
</bp-right-drawer-entity>
