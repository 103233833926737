import { createAction, props } from '@ngrx/store';

import { TELEMETRY_HIDDEN_MARK } from '@bp/shared/models/core';

import type { IIdentity, Permission } from '../models';

import { IDENTITY_STATE_SCOPE as SCOPE } from './compose-identity-api-actions';
import type { IdentityActions } from './identity.actions';

// eslint-disable-next-line @typescript-eslint/ban-types
export function composeIdentityActions<TIidentity extends IIdentity, TLoginPayload>(): Omit<
IdentityActions<TIidentity, TLoginPayload>,
'api'
> {
	return {
		setIdentity: createAction(`${ SCOPE } Set Identity`, props<{ identity: TIidentity }>()),

		removeIdentity: createAction(`${ SCOPE } Remove Identity`),

		addOrganizationPermissions: createAction(`${ SCOPE } Add Organization Permissions`, props<{ permissions: Permission[] }>()),

		login: createAction(`${ SCOPE } Login ${ TELEMETRY_HIDDEN_MARK }`, props<{ payload: TLoginPayload }>()),

		confirmLogout: createAction(`${ SCOPE } Confirm Logout`),

		dismissLogoutConfirmation: createAction(`${ SCOPE } Dismiss Logout Confirmation`),

		logout: createAction(`${ SCOPE } Logout`),

		logoutComplete: createAction(`${ SCOPE } Logout Complete`),

		saveUrlForRedirectionAfterLogin: createAction(
			`${ SCOPE } Save Url For Redirection After Login`,
			props<{ url: string }>(),
		),

		navigateToApp: createAction(
			`${ SCOPE } Navigate To App`,
			props<{ urlForRedirectionAfterLogin?: string | null }>(),
		),

		resetState: createAction(`${ SCOPE } Reset State`),

		resetError: createAction(`${ SCOPE } Reset Error`),
	};
}
