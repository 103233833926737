import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PspPaymentOptionType } from '@bp/shared/models/business';
import { isEmpty } from '@bp/shared/utilities';
import type { FirebasePspByPspNameMap, FirebasePspEnvironment, FirebasePspsByPaymentOptionTypeMap } from '@bp/shared/models/firebase-psps';

import type { IState } from './firebase-psps-shared.reducer';
import { FEATURE_KEY } from './firebase-psps-shared.reducer';

export const selectFeatureState = createFeatureSelector<IState>(FEATURE_KEY);

export const getAllFirebasePsps = createSelector(
	selectFeatureState,
	state => state.all?.filter(bridgerPsp => !!bridgerPsp.internalName),
);

export const getAllFirebasePspsByPspNameMap = createSelector(
	getAllFirebasePsps,
	firebasePsps => firebasePsps && new Map(firebasePsps.map(v => [ v.internalName!, v ])),
);

export const getAllFirebasePspsInternalNames = createSelector(
	getAllFirebasePsps,
	firebasePsps => firebasePsps?.map(bridgerPsp => bridgerPsp.internalName) ?? null,
);

export const getPubliclyVisibleFirebasePsps = createSelector(
	getAllFirebasePsps,
	firebasePsps => firebasePsps?.filter(bridgerPsp => bridgerPsp.publiclyVisible) ?? null,
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const getForEnvironment = (pspEnvironment: FirebasePspEnvironment) => createSelector(
	getAllFirebasePsps,
	firebasePsps => firebasePsps?.filter(bridgerPsp => bridgerPsp.environments.includes(pspEnvironment)) ?? null,
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const getForEnvironmentByPspNameMap = (pspEnvironment: FirebasePspEnvironment) => createSelector(
	getForEnvironment(pspEnvironment),
	(environmentPsps): FirebasePspByPspNameMap | null => environmentPsps && new Map(environmentPsps.map(v => [ v.internalName!, v ])),
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const getForEnvironmentByPaymentOptionTypeMap = (pspEnvironment: FirebasePspEnvironment) => createSelector(
	getForEnvironment(pspEnvironment),
	(environmentPsps): FirebasePspsByPaymentOptionTypeMap | null => environmentPsps && new Map(PspPaymentOptionType
		.getList()
		.map(optionType => <const>[
			optionType,
			environmentPsps.filter(psp => psp.paymentOptionsTypes.includes(optionType)),
		])
		.filter(([ , psps ]) => !isEmpty(psps))),
);

export const getPending = createSelector(
	selectFeatureState,
	s => s.pending,
);

export const getError = createSelector(
	selectFeatureState,
	s => s.error,
);
