import { get, isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import { BehaviorSubject, combineLatest, defer, switchMap } from 'rxjs';
import { distinctUntilChanged, first, map, repeatWhen, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Memoize } from 'typescript-memoize';

import { Injectable } from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { Enumeration } from '@bp/shared/models/core/enum';
import { DTO, Entity, FirebaseEntity } from '@bp/shared/models/metadata';
import { filterPresent } from '@bp/shared/rxjs';
import { bpQueueMicrotask, ensureType } from '@bp/shared/utilities';
import { StorageService } from '@bp/shared/services';

import { IRightDrawerActiveTab } from '@bp/admins-shared/features/layout';

import { DrawerType, EntityAsyncActionPayload, SaveEntityAsyncActionPayload } from '../../models';

import { EntityDrawerSelectors } from './compose-entity-drawer-selectors';
import { EntityDrawerActions } from './entity.actions';
import { AsyncActionOverEntityState } from './compose-entity-drawer-reducer';

@Injectable({ providedIn: 'root' })
export abstract class EntityFacade<TEntity extends Entity | FirebaseEntity>
implements IRightDrawerActiveTab {

	readonly abstract actions: EntityDrawerActions<TEntity>;

	readonly abstract selectors: EntityDrawerSelectors<TEntity>;

	readonly abstract draftEntityStorageKey: string | null;

	readonly entity$ = defer(() => this._store$.select(this.selectors.entity));

	readonly entityId$ = defer(() => this._store$.select(this.selectors.entityId));

	entity!: TEntity | null;

	readonly presentEntity$ = this.entity$.pipe(
		filterPresent,
	);

	readonly drawerType$ = defer(() => this._store$.select(this.selectors.drawerType));

	drawerType!: DrawerType | null;

	readonly formId$ = defer(() => this._store$.select(this.selectors.formId));

	readonly drawerTypeAndFormId$ = <Observable<[ drawerType: DrawerType, activeFormId: string ]>> combineLatest([
		this.drawerType$,
		this.formId$,
	]);

	readonly loading$ = defer(() => this._store$.select(this.selectors.loading));

	readonly pending$ = defer(() => this._store$.select(this.selectors.pending));

	readonly activatedRouteOutlet$ = defer(() => this._store$.select(this.selectors.activatedRouteOutlet));

	activatedRouteOutlet: string | null = null;

	readonly reset$ = defer(() => this._actions$.pipe(
		ofType(this.actions.resetState),
	));

	/**
	 * Any entity update happened during lifecycle of the entity, that is since it was loaded
	 */
	readonly updateLocalEntity$ = defer(() => this._actions$.pipe(
		ofType(this.actions.updateLocalEntity),
	));

	readonly refresh$ = defer(() => this._actions$.pipe(
		ofType(this.actions.refresh),
	));

	readonly error$ = defer(() => this._store$.select(this.selectors.error));

	readonly isViewDrawer$ = this.drawerType$.pipe(
		map(v => v === DrawerType.view),
	);

	readonly isAddDrawer$ = this.drawerType$.pipe(
		map(v => v === DrawerType.new),
	);

	readonly isNotAddDrawer$ = this.isAddDrawer$.pipe(map(v => !v));

	readonly isEditDrawer$ = this.drawerType$.pipe(
		map(v => v === DrawerType.edit),
	);

	readonly isEditLikeDrawer$ = this.drawerType$.pipe(
		map(v => DrawerType.editLike.includes(v!)),
	);

	readonly onRefreshSuccess$ = defer(() => this._actions$.pipe(
		ofType(this.actions.refresh),
		switchMap(() => this._actions$.pipe(
			ofType(this.actions.api.loadSuccess),
			// eslint-disable-next-line rxjs/no-unsafe-first
			first(),
			takeUntil(this._actions$.pipe(ofType(this.actions.api.loadFailure))),
		)),
	));

	readonly onSaveSuccess$ = defer(() => this._actions$.pipe(
		ofType(this.actions.api.saveSuccess),
	));

	readonly onSaveFailure$ = defer(() => this._actions$.pipe(
		ofType(this.actions.api.saveFailure),
	));

	readonly onDeleteSuccess$ = defer(() => this._actions$.pipe(
		ofType(this.actions.api.deleteSuccess),
	));

	readonly onDeleteFailure$ = defer(() => this._actions$.pipe(
		ofType(this.actions.api.deleteFailure),
	));

	private readonly _isFirstLoading$ = new BehaviorSubject(false);

	readonly isFirstLoading$ = this._isFirstLoading$.asObservable();

	private readonly _isOpenedDrawer$ = new BehaviorSubject(false);

	readonly isOpenedDrawer$ = this._isOpenedDrawer$.asObservable();

	get isOpenedDrawer(): boolean {
		return this._isOpenedDrawer$.value;
	}

	private readonly _isActiveDrawer$ = new BehaviorSubject(false);

	readonly isActiveDrawer$ = this._isActiveDrawer$.asObservable();

	get isActiveDrawer(): boolean {
		return this._isActiveDrawer$.value;
	}

	readonly isViewAndActiveDrawer$ = combineLatest([
		this.isViewDrawer$,
		this._isActiveDrawer$,
	])
		.pipe(
			map(([ isView, isActive ]) => isView && isActive),
			distinctUntilChanged(),
		);

	private readonly _activeTab$ = new BehaviorSubject<Enumeration | null>(null);

	readonly activeTab$ = this._activeTab$.asObservable();

	get activeTab(): Enumeration | null {
		return this._activeTab$.value;
	}

	private readonly _asyncActionsOverEntity$ = defer(
		() => this._store$.select(this.selectors.asyncActionsOverEntity),
	);

	constructor(
		protected readonly _store$: Store,
		protected readonly _storageService: StorageService,
		protected readonly _actions$: Actions,
	) {
		// at the end of the event loop to be sure the selectors and actions are set
		bpQueueMicrotask(() => {
			this._updateEntityPropertyOnStateChange();

			this._updateActiveRouteOutletPropertyOnStateChange();

			this._updateFirstLoadingOnStateChange();

			this._updateDrawerTypePropertyOnStateChange();
		});
	}

	abstract factory(v?: DTO<TEntity>): TEntity;

	protected _draftEntityStorageKeyBuilder = async (): Promise<string | null> => Promise.resolve(this.draftEntityStorageKey);

	private async _getEntityStorageKey(): Promise<string> {
		const draftEntityStorageKey = await this._draftEntityStorageKeyBuilder();

		this._assertStorageKeyPresence(draftEntityStorageKey);

		return draftEntityStorageKey;
	}

	async getDraftEntityFromStorage(): Promise<TEntity | null> {
		const dto = this._storageService.get(await this._getEntityStorageKey());

		return dto ? this.factory(<any>dto) : null;
	}

	async saveDraftEntityInStorage(entity: TEntity): Promise<void> {
		this._storageService.set(await this._getEntityStorageKey(), entity);
	}

	async deleteDraftEntityFromStorage(): Promise<void> {
		this._storageService.remove(await this._getEntityStorageKey());
	}

	updateLocalEntity(entity: TEntity): void {
		if (!entity.id)
			void this.saveDraftEntityInStorage(entity);

		this._store$.dispatch(this.actions.updateLocalEntity({ entity }));
	}

	load(entityId: string): void {
		this._store$.dispatch(this.actions.load({
			id: entityId,
			drawerType: DrawerType.view,
		}));
	}

	/**
	 * Load entity from the back with the same query params used to load the entity
	 */
	refresh(): void {
		this._store$.dispatch(this.actions.refresh());
	}

	resetState(): void {
		this._store$.dispatch(this.actions.resetState());
	}

	markAsPending(): void {
		this._store$.dispatch(this.actions.markAsPending());
	}

	save(entity: TEntity, suppressSavedToast = false): void {
		this._store$.dispatch(this.actions.save({
			entity,
			suppressSavedToast,
		}));
	}

	optimisticSave(payload: SaveEntityAsyncActionPayload<TEntity>): void {
		this._store$.dispatch(this.actions.save({
			...payload,
			optimistic: true,
			suppressSavedToast: true,
		}));
	}

	delete(entity: TEntity = this.entity!): void {
		this._store$.dispatch(this.actions.deleteConfirm({ entity }));
	}

	toggleActivityStatus(entity: TEntity): void {
		const updatedEntityDto = {
			...entity,
			isEnabled: !entity.isEnabled,
		};

		const payload: EntityAsyncActionPayload<TEntity> = {
			entity: this.factory(<any>updatedEntityDto),
			rollbackEntity: entity,
		};

		this._store$.dispatch(this.shouldConfirmDisabling(entity)
			? this.actions.disableConfirm(payload)
			: this.actions.toggleActivityStatus(payload));
	}

	shouldConfirmDisabling(entity: TEntity): boolean {
		return !!entity.isEnabled;
	}

	openedDrawer(open: boolean): void {
		this._isOpenedDrawer$.next(open);
	}

	/**
	 * The drawer on top of another drawer
	 */
	activatedDrawer(activated: boolean): void {
		this._isActiveDrawer$.next(activated);
	}

	/**
	 * Returns a page title for the loading and error state or applies the given expression when the entity is ready
	 */
	getPageTitle$(entityPageTitleExpression: (entity: TEntity) => string): Observable<string> {
		return combineLatest([
			this.entity$,
			this.pending$,
			this.drawerType$,
		])
			.pipe(map(([ entity, pending, drawerType ]) => {
				if (pending)
				  return 'Loading...';

				if (drawerType === DrawerType.new)
					return 'New';

				if (entity)
					return entityPageTitleExpression(entity);

				return 'Error Loading';
			}));
	}

	getIsSubFormShowed$(formId: string): Observable<boolean> {
		return this.drawerTypeAndFormId$.pipe(
			distinctUntilChanged(isEqual),
			map(([ drawerType, activeFormId ]) => drawerType === DrawerType.edit
				 && activeFormId === formId
				|| drawerType === DrawerType.new),
			shareReplay({
				bufferSize: 1,
				refCount: false,
			}),
		);
	}

	setActiveTab(tab: Enumeration | null): void {
		this._activeTab$.next(tab);
	}

	getSaveActionState$(entity: TEntity): Observable<AsyncActionOverEntityState> {
		return this._getAsyncActionState$(this.actions.save, entity);
	}

	getDeleteActionState$(entity: TEntity): Observable<AsyncActionOverEntityState> {
		return this._getAsyncActionState$(this.actions.delete, entity);
	}

	@Memoize((action: Action, entity: TEntity) => `${ action.type }_${ entity.id! }`)
	protected _getAsyncActionState$(action: Action, entity: TEntity): Observable<AsyncActionOverEntityState> {
		return this._asyncActionsOverEntity$.pipe(
			map(asyncActionsOverEntity => get(
				asyncActionsOverEntity,
				[ action.type, entity.id! ],
				ensureType<AsyncActionOverEntityState>({
					pending: false,
					error: null,
				}),
			)),
			distinctUntilChanged(isEqual),
			shareReplay({ refCount: true, bufferSize: 1 }),
		);
	}

	private _assertStorageKeyPresence(key: string | null): asserts key is string {
		if (key === null)
			throw new Error('To use this method the storage key must be provided');
	}

	private _updateEntityPropertyOnStateChange(): void {
		this.entity$
			.subscribe(entity => (this.entity = entity));
	}

	private _updateDrawerTypePropertyOnStateChange(): void {
		this.drawerType$
			.subscribe(drawerType => (this.drawerType = drawerType));
	}

	private _updateActiveRouteOutletPropertyOnStateChange(): void {
		this.activatedRouteOutlet$
			.subscribe(activatedRouteOutlet => (this.activatedRouteOutlet = activatedRouteOutlet));
	}

	private _updateFirstLoadingOnStateChange(): void {
		this.loading$
			.pipe(
				filterPresent,
				take(2),
				repeatWhen(() => this.reset$),
			)
			.subscribe(v => void this._isFirstLoading$.next(v));
	}
}
