import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { IState } from './demostand.reducer';
import { FEATURE_KEY } from './demostand.reducer';

export const selectDemostandState = createFeatureSelector<IState>(FEATURE_KEY);

export const getPreset = createSelector(
	selectDemostandState,
	s => s.preset,
);
