<mat-card #cardRef="bpElementRef" bpElementRef [class.has-error]="facade.error$ | async">
	<mat-card-content class="mat-card-content-table">
		<mat-progress-bar *ngIf="facade.pending$ | async" @fade color="primary" mode="indeterminate">
		</mat-progress-bar>

		<ng-content *ngIf="(facade.error$ | async) === null"></ng-content>

		<bp-alert
			*ngIf="facade.error$ | async as error; else defaultTpl"
			@fade
			[errors]="error!.messages"
			[closable]="false"
			type="danger"
		>
		</bp-alert>

		<ng-template #defaultTpl>
			<h3
				*ngIf="
					((facade.pending$ | async) && (facade.isEmptyRecords$ | async)) || (facade.isEmptyRecords$ | async)
				"
				@slideIn
				class="text-center my-4"
			>
				<span *ngIf="facade.pending$ | async; else noresultsTpl"> Loading... </span>

				<ng-template #noresultsTpl>
					<span [ngSwitch]="!!noResultsTpl">
						<span *ngSwitchDefault>No results</span>
						<ng-content *ngSwitchCase="true" select="bp-entities-table-card-no-results"> </ng-content>
					</span>
				</ng-template>
			</h3>
		</ng-template>
	</mat-card-content>
</mat-card>

<div *ngIf="showPaginator" class="text-right mt-3">
	<bp-paginator
		[scrollTarget]="cardRef.nativeElement"
		[recordsPage]="facade.recordsPage$ | async"
		[pageLength]="(facade.records$ | async)?.length"
		[totalLength]="totalItemsCount$ | async"
		(page)="facade?.changePage($event)"
	>
	</bp-paginator>
</div>
