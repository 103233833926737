<bp-input
	#origin="matAutocompleteOrigin"
	matAutocompleteOrigin
	inputmode="tel"
	[label]="label"
	[hint]="hint"
	[nativeAutocomplete]="nativeAutocomplete"
	[color]="color"
	[formControl]="_internalControl"
	[placeholder]="placeholder"
	[floatLabel]="floatLabel"
	[name]="name"
	[pending]="pending"
	[required]="!!required"
	[appearance]="appearance"
	[mask]="phoneNumberMask"
>
	<button
		bpInputPrefix
		class="reset-default-decoration"
		type="button"
		(click)="
			$event.stopPropagation();
			dialCodeCountrySearchControl.setValue('');
			autocompleteTrigger.openPanel();
			dialCodeCountryInput.focus()
		"
	>
		<input
			#dialCodeCountryInput
			type="text"
			[autocomplete]="valueToDisableNativeAutocomplete"
			[value]="dialCodeCountrySearchControl.value"
			[matAutocomplete]="countriesAutocomplete"
			[matAutocompleteConnectedTo]="origin"
		/>
		<bp-country hideTooltip compact [src]="dialCodeCountry"></bp-country>
	</button>
</bp-input>
<mat-autocomplete
	#countriesAutocomplete="matAutocomplete"
	class="dial-code-countries-autocomplete-panel"
	(opened)="virtualScrollViewport.checkViewportSize()"
>
	<bp-input
		class="dial-code-countries-autocomplete-panel-search-input"
		appearance="standard"
		[formControl]="dialCodeCountrySearchControl"
		[placeholder]="('Start typing to find your country' | bpTranslate | async)!"
	>
	</bp-input>

	<cdk-virtual-scroll-viewport
		#virtualScrollViewport
		itemSize="48"
		minBufferPx="200"
		[@fadeInListStaggered]="filteredCountries.length"
	>
		<mat-option
			*cdkVirtualFor="let filteredCountry of filteredCountries"
			(onSelectionChange)="onSelectionChange($event, filteredCountry)"
		>
			<bp-country class="mr-2" compact hideTooltip [src]="filteredCountry"></bp-country>
			{{ filteredCountry.displayName }} +{{ filteredCountry.dialCode }}
		</mat-option>

		<mat-option *ngIf="filteredCountries.length === 0" disabled>
			<div class="text-center">
				{{ 'No matching countries ' | bpTranslate | async }}
			</div>
		</mat-option>
	</cdk-virtual-scroll-viewport>
</mat-autocomplete>
