import { intersectionBy, isNil, isArray } from 'lodash-es';

import { ChangeDetectionStrategy, Component, Directive, EventEmitter, Input, TemplateRef, ContentChild, ViewChild, Output, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { FormFieldControlComponent } from '@bp/shared/components/core';
import { attrBoolValue, bpQueueMicrotask, valueOf } from '@bp/shared/utilities';

@Directive({
	selector: '[bpSelectOption]',
})
export class SelectOptionDirective {

	constructor(public tpl: TemplateRef<any>) { }

}

const resetOption = <const>'reset';

@Component({
	selector: 'bp-select-field[items]',
	templateUrl: './select-field.component.html',
	styleUrls: [ './select-field.component.scss' ],
	host: {
		'(focusin)': 'onTouched()',
	},
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SelectComponent,
		multi: true,
	}],
})
export class SelectComponent<
	TSelectItem,
	TMultiple extends boolean | '' | null | undefined,
	TRequired extends boolean | '' | null | undefined,
	TControlValue extends (TMultiple extends '' | true ? TSelectItem[] : TSelectItem),
	TControlValueRequireness extends (TRequired extends '' | true ? never : null)
>
	extends FormFieldControlComponent<TControlValue | TControlValueRequireness> implements OnInit {

	@Input() items!: Set<TSelectItem> | TSelectItem[] | null | undefined;

	@Input() multiple?: TMultiple;

	@Input() override required?: TRequired;

	@Input() optionClass?: string;

	@Input() resetOptionText = 'None';

	@Input() useItemValueOf?: boolean | '';

	@Input() disableOptionCentering?: boolean | '';

	@Input() itemDisplayValuePropertyName?: string;

	@Input() panelClass = 'mat-select-panel';

	@Input() itemKind = 'item';

	@Output() readonly selectionChange = new EventEmitter<MatSelectChange>();

	@ViewChild(MatSelect, { static: true })
	protected _select!: MatSelect;

	@ContentChild(SelectOptionDirective)
	protected _customSelectOption?: SelectOptionDirective;

	protected _resetOption = resetOption;

	override ngOnInit(): void {
		super.ngOnInit();

		this.multiple = <TMultiple>attrBoolValue(this.multiple);

		this.useItemValueOf = attrBoolValue(this.useItemValueOf);

		this.disableOptionCentering = attrBoolValue(this.disableOptionCentering);
	}

	override writeValue(value: TControlValue | TControlValueRequireness): void {
		bpQueueMicrotask(() => void super.writeValue(
			this._parseIncomingValue(value),
		));
	}

	focus(): void {
		this._select.focus();
	}

	protected override _onInternalControlValueChange(internalControlValue: unknown): void {
		const controlValue = this.__inferControlValueFromInternalControlValue(internalControlValue);

		if (controlValue === null)
			this._eraseInternalControlValue();

		this.setValue(controlValue);
	}

	private _parseIncomingValue(incomingValue: any): TControlValue | null {
		if (isNil(incomingValue))
			return null;

		if (this.multiple) {
			incomingValue = isArray(incomingValue) ? incomingValue : [ incomingValue ];

			if (this.items)
				incomingValue = intersectionBy([ ...this.items ], incomingValue, valueOf);
		} else {
			if (isArray(incomingValue))
				throw new Error('Single-select field cannot accept an array of values.');

			const parsedValue = <TControlValue | undefined>(this.items
				? [ ...this.items ].find(item => valueOf(item) === valueOf(incomingValue))
				: incomingValue
			);

			incomingValue = parsedValue ?? null;
		}

		return <TControlValue>incomingValue;

	}

	private __inferControlValueFromInternalControlValue(internalControlValue: unknown): TControlValue | TControlValueRequireness {
		const shouldReset = !this.required && internalControlValue === resetOption || isArray(internalControlValue) && internalControlValue.includes(resetOption);

		return <TControlValue | TControlValueRequireness> (shouldReset ? null : internalControlValue);
	}

}
