import { createAction, props } from '@ngrx/store';

import type { ErrorActionPayload, ResultActionPayload } from '@bp/shared/models/common';

import { composeEntitiesListApiActions } from '@bp/admins-shared/features/entity';

import type { CreditCard } from '../../models';

export const ACTIONS_SCOPE = '[Page][Credit Cards]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const CREDIT_CARDS_LIST_API_ACTIONS = composeEntitiesListApiActions<CreditCard>(scope);

export const firebaseCollectionUpdate = createAction(
	`${ scope } Firestore Collection Update`,
	props<ResultActionPayload<CreditCard[]>>(),
);

export const listeningToFirestoreCollectionUpdatesFailure = createAction(
	`${ scope } Listening To Firestore Collection Updates Failure`,
	props<ErrorActionPayload>(),
);
