import type { User as FirebaseUser } from 'firebase/auth';

import type { DTO } from '@bp/shared/models/metadata';
import { Unserializable } from '@bp/shared/models/metadata';

import type { FeatureAction, IIdentity } from '@bp/shared-domains-identity';

import type { BackofficeFeature } from '@bp/backoffice/domains/users';
import { BackofficeUserRole, User } from '@bp/backoffice/domains/users';

export class Identity extends User implements IIdentity {
	static fromFirebase(firebaseUser: FirebaseUser): DTO<Identity> {
		const providerData = firebaseUser.providerData[0] ?? {};

		return {
			id: firebaseUser.uid,
			email: providerData.email ?? firebaseUser.email!,
			displayName: providerData.displayName ?? firebaseUser.displayName,
			avatarUrl: providerData.photoURL ?? firebaseUser.photoURL,
		};
	}

	@Unserializable()
	readonly featurePermissions: Set<BackofficeFeature | FeatureAction>;

	@Unserializable()
	readonly isAlmighty: boolean;

	constructor(dto?: DTO<Identity>) {
		super(dto);

		this.featurePermissions = new Set(this.roles.flatMap(role => role.permissions));

		this.isAlmighty = this.roles.includes(BackofficeUserRole.almighty);
	}

	canEdit(authorId?: string | null): boolean {
		return this.isAlmighty || this.id === authorId;
	}
}
