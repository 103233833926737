import { defer, EMPTY } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { inject, Type } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Entity, FirebaseEntity, IEntitiesApiBaseService } from '@bp/shared/models/metadata';
import { filterPresent } from '@bp/shared/rxjs';
import { RouterService } from '@bp/shared/services';

import { EntitiesListFacade } from './entities-list.facade';
import { EntitiesListActions } from './entities.actions';

export abstract class EntitiesListBaseEffects<
	TEntity extends Entity | FirebaseEntity,
	TLoadQueryParams,
	TEntitiesFacade extends EntitiesListFacade<TEntity, TLoadQueryParams>,
	TApiService extends IEntitiesApiBaseService<TEntity>
> {

	abstract readonly routeComponentType: Type<any> | null;

	readonly debounceDueTime = 500;

	protected readonly _actions$ = inject<Actions>(Actions);

	protected readonly _routerService = inject(RouterService);

	get actions(): EntitiesListActions<TEntity, TLoadQueryParams> {
		return this._entitiesFacade.actions;
	}

	routeComponentActivation$ = defer(
		() => this.routeComponentType
			? this._routerService.onNavigationEndToRouteComponent(this.routeComponentType)
			: EMPTY,
	);

	routeComponentDeactivation$ = defer(
		() => this.routeComponentType
			? this._routerService.onNavigationEndFromRouteComponent(this.routeComponentType)
			: EMPTY,
	);

	createApiQueryParamsOnActivatedRouteComponentParamsChange$ = createEffect(() => defer(() => this.routeComponentActivation$
		.pipe(
			switchMap(v => v.params),
			map(params => this.actions.queryParamsChanged({
				query: this._entitiesFacade.queryParamsFactory(params),
			})),
		)));

	apiQueryParamsWithoutPage$ = defer(() => this._actions$.pipe(
		ofType(this.actions.queryParamsChanged),
		map(v => v.query),
		filterPresent,
	));

	onRefreshChangePageToFirstOrJustReload$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.refresh),
		map(() => this.actions.changePage({ page: undefined })),
	));

	pageChange$ = defer(() => this._actions$.pipe(
		ofType(this.actions.changePage),
		startWith({ page: null }),
	));

	apiQueryParamsWithPage$ = this.apiQueryParamsWithoutPage$.pipe(
		switchMap(params => this.pageChange$.pipe(
			map(({ page }) => this._entitiesFacade.queryParamsFactory({ ...params, page })),
			takeUntil(this.routeComponentDeactivation$),
		)),
	);

	constructor(
		protected _apiService: TApiService,
		protected _entitiesFacade: TEntitiesFacade,
	) { }

}
