import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ChangeDetectorRef, Directive } from '@angular/core';

import { Destroyable, takeUntilDestroyed } from '@bp/shared/models/common';

import { FeaturePermissionsService } from '../services';
import { Feature, Permission } from '../models';

@Directive()
export abstract class HasAccessBaseDirective extends Destroyable {
	get hasAccess(): boolean {
		return this._hasAccess$.value;
	}

	protected _hasAccess$ = new BehaviorSubject<boolean>(false);

	trackedPermission: Permission | null = null;

	private _updateHasAccessSubscription = Subscription.EMPTY;

	constructor(
		private readonly _featurePermissionsService: FeaturePermissionsService,
		private readonly _cdr: ChangeDetectorRef,
	) {
		super();
	}

	protected _observeUserAccessToPermission(permission: Permission): void {
		this._assertPermissionType(permission);

		if (permission === this.trackedPermission)
			return;

		this.trackedPermission = permission;

		this._emitHasAccessOnFeaturePermissionServiceChange(permission);
	}

	private _emitHasAccessOnFeaturePermissionServiceChange(permission: Permission): void {
		this._updateHasAccessSubscription.unsubscribe();

		this._updateHasAccessSubscription = this._featurePermissionsService
			.hasAccess$(permission)
			.pipe(
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(hasAccess => {
				this._hasAccess$.next(hasAccess);

				setTimeout(() => void this._cdr.detectChanges());
			});
	}

	private _assertPermissionType(value: any): asserts value is Permission {
		if (Feature.isValid(value))
			return;

		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		throw new Error(
			`Directive accepts only enumerations \`BaseFeature\` or \`FeatureAction\` but got \`${ value }\``,
		);
	}
}
