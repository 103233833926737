import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseDialogComponent } from '../base-dialog';

@Component({
	selector: 'bp-discard-changes-confirm-dialog',
	templateUrl: './discard-changes-confirm-dialog.component.html',
	styleUrls: [ './discard-changes-confirm-dialog.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscardChangesConfirmDialogComponent extends BaseDialogComponent {

	canCloseWithTrueOnEnter(): boolean {
		return true;
	}

}
