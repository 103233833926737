import { createAction, props } from '@ngrx/store';

import type { FirebasePsp } from '@bp/shared/models/firebase-psps';

export const ACTIONS_SCOPE = '[Domain][Shared Firebase Bridger Psps]';

export const load = createAction(
	`${ ACTIONS_SCOPE } Load`,
);

export const update = createAction(
	`${ ACTIONS_SCOPE } Update`,
	props<{ psps: FirebasePsp[] }>(),
);
