import type { EntityDrawerActions } from '@bp/admins-shared/features/entity';
import { composeEntityDrawerActions } from '@bp/admins-shared/features/entity';

import type { CreditCard } from '../../models';

import { ACTIONS_SCOPE, CREDIT_CARD_DRAWER_API_ACTIONS } from './credit-card-api.actions';

export const CREDIT_CARD_DRAWER_ACTIONS: EntityDrawerActions<CreditCard> = {

	...composeEntityDrawerActions(ACTIONS_SCOPE),

	api: CREDIT_CARD_DRAWER_API_ACTIONS,
};
