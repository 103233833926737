import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { listenToUsersChanges } from './app.actions';
import { getUsers } from './app.selectors';

@Injectable({ providedIn: 'root' })
export class AppFacade {

	users$ = this._store$.select(getUsers);

	constructor(private readonly _store$: Store) { }

	listenToUsers(): void {
		this._store$.dispatch(listenToUsersChanges());
	}

}
