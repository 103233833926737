import type { IPageQueryParamsDTO, StringifyQuery } from '@bp/shared/models/common';
import { mixinPageQueryParams, QueryParamsBase } from '@bp/shared/models/common';
import { booleanMapper, ISortQueryParamsDTO, mixinSortQueryParams } from '@bp/shared/models/metadata';

import { User } from './user';

interface IUsersQueryParams {

	fired?: boolean;

}

type UsersQueryParamsDTO = IPageQueryParamsDTO
& ISortQueryParamsDTO
& StringifyQuery<IUsersQueryParams>;

class UsersQueryParamsBase extends QueryParamsBase<UsersQueryParamsDTO> { }

export class UsersQueryParams extends mixinPageQueryParams(
	mixinSortQueryParams(UsersQueryParamsBase, User),
) implements IUsersQueryParams {

	fired?: boolean;

	constructor(dto: UsersQueryParamsDTO) {
		super(dto);

		if (dto.fired !== undefined)
			this.fired = booleanMapper(dto.fired);
	}
}
