import { Injectable } from '@angular/core';

import type { DTO } from '@bp/shared/models/metadata';

import { IdentityFacade as IdentityBaseFacade } from '@bp/shared-domains-identity';

import { Identity } from '../models';

import { IDENTITY_ACTIONS } from './identity.actions';
import { IDENTITY_SELECTORS } from './identity.selectors';

@Injectable({ providedIn: 'root' })
export class IdentityFacade extends IdentityBaseFacade<Identity> {
	readonly actions = IDENTITY_ACTIONS;

	readonly selectors = IDENTITY_SELECTORS;

	readonly factory = (dto: DTO<Identity>): Identity => new Identity(dto);

	override login(): void {
		// eslint-disable-next-line unicorn/no-useless-undefined
		super.login(undefined);
	}
}
