import type { EntityDrawerActions } from '@bp/admins-shared/features/entity';
import { composeEntityDrawerActions } from '@bp/admins-shared/features/entity';

import type { Preset } from '../../models';

import { ACTIONS_SCOPE, PRESET_DRAWER_API_ACTIONS } from './preset-api.actions';

export const PRESET_DRAWER_ACTIONS: EntityDrawerActions<Preset> = {

	...composeEntityDrawerActions(ACTIONS_SCOPE),

	api: PRESET_DRAWER_API_ACTIONS,
};
