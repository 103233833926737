import { IPageQueryParamsDTO, mixinPageQueryParams, QueryParamsBase, StringifyQuery } from '@bp/shared/models/common';

interface ICommonQueryParams {

	authorUid?: string;

}

type CommonQueryParamsDTO = IPageQueryParamsDTO
& StringifyQuery<ICommonQueryParams>;

class CommonQueryParamsBase extends QueryParamsBase<CommonQueryParamsDTO> { }

export class CommonQueryParams extends mixinPageQueryParams(CommonQueryParamsBase) implements ICommonQueryParams {

	authorUid?: string;

	constructor(dto: CommonQueryParamsDTO) {
		super(dto);

		this.authorUid = dto.authorUid;
	}

}
