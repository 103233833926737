import { isObject, isString, isNil } from 'lodash-es';

import { Countries, CountryCode, Country } from '@bp/shared/models/countries';
import { PrimitiveConstructable } from '@bp/shared/models/core';
import { NonFunctionProperties } from '@bp/shared/typings';

export class CashierLanguage extends PrimitiveConstructable {

	readonly iso!: string;

	readonly country!: Country;

	readonly name!: string;

	readonly lowerCaseName!: string;

	constructor(dtoOrIso: Partial<NonFunctionProperties<CashierLanguage>> | string) {
		super(isString(dtoOrIso) ? dtoOrIso : dtoOrIso.iso!);

		if (this._isCached())
			return this;

		if (isObject(dtoOrIso))
			Object.assign(this, dtoOrIso);

		if (CashierLanguage._cache.has(this.iso))
			return <CashierLanguage> CashierLanguage._cache.get(this.iso);

		this.lowerCaseName = this.name.toLowerCase();

		if (isNil(this.country))
			this.country = Countries.get(<CountryCode> this.iso.toUpperCase())!;

		this._cacheAndFreezeInstance();
	}

	protected _getScope(): string {
		return 'cashier_language';
	}

}

export class CashierLanguages {
	static list = [
		new CashierLanguage({ iso: 'en', name: 'English', country: Countries.get('GB') }),
		new CashierLanguage({ iso: 'fr', name: 'French (Français)' }),
		new CashierLanguage({ iso: 'de', name: 'German (Deutsche)' }),
		new CashierLanguage({ iso: 'es', name: 'Spanish (Español)' }),
		new CashierLanguage({ iso: 'ru', name: 'Russian (Русский)' }),
		new CashierLanguage({ iso: 'pt', name: 'Portuguese (Português)' }),
		new CashierLanguage({ iso: 'zh', name: 'Chinese Simplified (汉语)', country: Countries.get('CN') }),
		new CashierLanguage({ iso: 'zh-TW', name: 'Chinese Traditional (漢語)', country: Countries.get('CN') }),
		new CashierLanguage({ iso: 'ar', name: 'Arabic (العَرَبِيَّة)', country: Countries.get('AE') }),
		new CashierLanguage({ iso: 'ms', name: 'Malay (بهاس ملايو)', country: Countries.get('MY') }),
		new CashierLanguage({ iso: 'ja', name: 'Japanese (日本語)', country: Countries.get('JP') }),
	];

	private static readonly _langByIsoCode = new Map<string, CashierLanguage>(CashierLanguages.list
		.map(it => <[ string, CashierLanguage ]>[ it.iso, it ]));

	private static readonly _langNames = CashierLanguages.list.map(v => v.lowerCaseName);

	static findByLangName(langName: string): CashierLanguage | null {
		const lowerCaseLangName = langName.toLowerCase();

		return CashierLanguages.list.find(v => v.lowerCaseName === lowerCaseLangName) ?? null;
	}

	static findByIso(iso: string): CashierLanguage | null {
		return CashierLanguages._langByIsoCode.get(iso) ?? null;
	}

	static includes(langName: string): boolean {
		return CashierLanguages._langNames.includes(langName.toLowerCase());
	}

	static includesIso(iso: string): boolean {
		return CashierLanguages.list.some(v => v.iso === iso);
	}
}
