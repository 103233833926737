import { Countries } from './countries';
import { Country } from './country';
import { State } from './state';

export function countryMapper(v: Country | string): Country | null {
	return v instanceof Country ? v : Countries.findByCodeString(v);
}

export function stateMapper(
	stateOrStateCode: State | string,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	_instanceDTO: any,
	thisInstance: { country: Country },
): State | null {
	if (stateOrStateCode instanceof State)
		return stateOrStateCode;

	const stateCode = stateOrStateCode.toUpperCase();

	return stateCode && thisInstance.country.states?.find(it => it.code === stateCode) || null;
}
