import { Injectable } from '@angular/core';

import { CloudflareAccessService, MomentTimezoneService, TelemetryService } from '@bp/shared/services';

import { AdminSharedAppStartupService } from '@bp/admins-shared/core/services';
import { FirebasePspsSharedFacade } from '@bp/admins-shared/domains/firebase-psps';

import { CreditCardsFacade } from '@bp/backoffice/cashier-demostand/credit-cards';
import type { Identity } from '@bp/backoffice/domains/identity';
import { IdentityFacade } from '@bp/backoffice/domains/identity';

@Injectable({
	providedIn: 'root',
})
export class AppStartupService {

	constructor(
		private readonly _adminSharedAppStartup: AdminSharedAppStartupService,
		private readonly _telemetry: TelemetryService,
		private readonly _firebasePspsSharedFacade: FirebasePspsSharedFacade,
		private readonly _creditCardsFacade: CreditCardsFacade,
		private readonly _momentTimezoneService: MomentTimezoneService,
		private readonly _identityFacade: IdentityFacade,
		private readonly _cloudflareAccessService: CloudflareAccessService,
	) { }

	init(): void {
		this._adminSharedAppStartup.init();

		this._cloudflareAccessService.whenUserUnauthorizedByCloudflareRedirectToCloudflareLoginPage();

		this._momentTimezoneService.setGuessedTimezone();

		this._identityFacade.onIdentityFirstSet(identity => {
			this._firebasePspsSharedFacade.load();

			this._creditCardsFacade.listenToAllRecordsUpdates();

			this._associateIdentityWithTelemetry(identity);
		});

	}

	private _associateIdentityWithTelemetry(identity: Identity): void {
		this._telemetry.identifyUser(
			identity.email,
			{ displayName: identity.displayName },
		);
	}

}
