import { isEmpty, kebabCase, isNil } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, lastValueFrom, Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import type { OnDestroy, OnInit } from '@angular/core';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Directive, ElementRef, HostBinding,
	HostListener, Input, isDevMode, EventEmitter, Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import type { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';

import { FADE_IN, SLIDE, SLIDE_IN } from '@bp/shared/animations';
import { DiscardChangesConfirmDialogComponent } from '@bp/shared/components/dialogs';
import { FormMetadataEntityBaseComponent } from '@bp/shared/components/metadata';
import { MAT_DRAWER_ANIMATION_DURATION, OnChanges, SimpleChanges } from '@bp/shared/models/core';
import type { DTO, Entity, FirebaseEntity } from '@bp/shared/models/metadata';
import { OptionalBehaviorSubject } from '@bp/shared/rxjs';
import { attrBoolValue, UrlHelper } from '@bp/shared/utilities';
import { takeUntilDestroyed } from '@bp/shared/models/common';

import { RightDrawerComponent } from '@bp/admins-shared/features/layout';

import type { DrawerRouteParams as DrawerRouteParameters } from '../../models';
import { DrawerType } from '../../models';
import { EntityFacade } from '../../state';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'bp-right-drawer-entity-header, .right-drawer-header',
	host: {
		class: 'right-drawer-header',
	},
})
export class RightDrawerEntityHeaderDirective {}

@Component({
	selector: 'bp-right-drawer-entity',
	templateUrl: './right-drawer-entity.component.html',
	styleUrls: [ './right-drawer-entity.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, FADE_IN, SLIDE_IN ],
	providers: [
		{
			provide: FormMetadataEntityBaseComponent,
			useExisting: RightDrawerEntityComponent,
		},
	],
})
export class RightDrawerEntityComponent<TEntity extends Entity | FirebaseEntity>
	extends FormMetadataEntityBaseComponent<TEntity>
	implements OnChanges, OnInit, OnDestroy {

	// eslint-disable-next-line @typescript-eslint/naming-convention
	MAT_DRAWER_ANIMATION_DURATION = MAT_DRAWER_ANIMATION_DURATION;

	@Input() facade!: EntityFacade<TEntity>;

	@Input() drawerType!: DrawerType | null;

	@Input() titlePlaceholder!: string;

	@Input() resetEntityStateOnDestroy = true;

	@Input() dontChangeRightDrawerToViewTypeOnSaveSuccess = false;

	@Input() hasHeader = true;

	@Input() firstLoading!: boolean | null;

	@Input() showGeneralErrorsAtTop!: boolean | '';

	@Output() readonly ctrlEnterKeydown = new EventEmitter<void>();

	@HostBinding('attr.tabindex') tabindex = -1;

	@ContentChild(RightDrawerEntityHeaderDirective) header?: RightDrawerEntityHeaderDirective;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	DrawerType = DrawerType;

	$host = this._host.nativeElement;

	animationEnd$ = this.rightDrawerComponent.animationEnd$.asObservable();

	get isEdit(): boolean {
		return this.drawerType === DrawerType.edit;
	}

	get isAdd(): boolean {
		return this.drawerType === DrawerType.new;
	}

	get isView(): boolean {
		return this.drawerType === DrawerType.view;
	}

	get isClone(): boolean {
		return this.drawerType === DrawerType.clone;
	}

	get isEditLike(): boolean {
		return DrawerType.editLike.includes(this.drawerType!);
	}

	override formScheme = null;

	override omitFromGlobalErrorsNotFoundControls = true;

	private _onSaveSuccessSubscription = Subscription.EMPTY;

	private _onTabChangeSubscription = Subscription.EMPTY;

	private readonly _discardChanges$ = new OptionalBehaviorSubject<void>();

	constructor(
		public rightDrawerComponent: RightDrawerComponent,
		private readonly _host: ElementRef<HTMLElement>,
		private readonly _router: Router,
		private readonly _route: ActivatedRoute,
		private readonly _dialog: MatDialog,
		fb: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(fb, cdr, toaster);

		this.form = new UntypedFormGroup({});

		this._submitOnCntrlPlusEnter();

		void this._onRightDrawerCanCloseConfirmChangesDiscardIfAny();

		this._onRightDrawerCloseMarkFormPristine();

		this._onDiscardChangesResetFormState();
	}

	@Input() getEntityId: (entity: TEntity | null | undefined) => string | null | undefined = entity => entity?.id;

	private _getEntityId(): string | null | undefined {
		return this.entity && this.getEntityId(this.entity);
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		const { drawerType, entity, facade, firstLoading } = changes;

		if (entity || drawerType && this.isView)
			this.markFormAsPristineAndUntouched();

		if (facade) {
			this._onEntitySaveSuccess(() => {
				this.markFormAsPristineAndUntouched();

				!this.dontChangeRightDrawerToViewTypeOnSaveSuccess && this.changeDrawerRouteParams(DrawerType.view);
			});

			this._onTabChangeResetDrawerToView();
		}

		if (drawerType || firstLoading)
			this._updateRightDrawerClass();
	}

	override ngOnInit(): void {
		this.showGeneralErrorsAtTop = attrBoolValue(this.showGeneralErrorsAtTop);

		this.facade.openedDrawer(true);

		this._onRightDrawerFullscreenMarkEntityFacadeAsInactive();

		this._whenSubmittedSaveEntity();
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();

		this.facade.openedDrawer(false);

		this.resetEntityStateOnDestroy && this.facade.resetState();
	}

	override submit(): void {
		if (this.isAdd && this.canCreate)
			this.markAsDirty();

		if (this.isEditLike || this.canSave)
			super.submit();
	}

	updateDrawerEntityAndSaveDraftToStorage<T extends Object>(partialEntity: T | null): void {
		this.updateDrawerEntity(partialEntity);

		if (this.entity && isNil(this._getEntityId()))
			void this.facade.saveDraftEntityInStorage(this.entity);
	}

	updateDrawerEntity<T extends Object>(partialEntity: T | null): void {
		this._setEntity(this.factory({
			...<DTO<TEntity> | null> this.entity,
			...<DTO<TEntity> | null> partialEntity,
		}));
	}

	async confirmRouteDeactivation(): Promise<boolean> {
		if (!this.$host.isConnected || this._canDeactivate())
			return true;

		return this._confirmChangesDiscard();
	}

	setDrawerRouteParamsToEdit(formId?: string): void {
		this.changeDrawerRouteParams(DrawerType.edit, formId);
	}

	changeDrawerRouteParams(drawerType: DrawerType, formId?: string): void {
		const nextUrl = this._createDrawerUrlTree(this._route.snapshot, drawerType, formId)
			.toString();

		const currentNavigationFinalUrl = this._router.getCurrentNavigation()
			?.finalUrl
			?.toString();

		const currentRouterStateUrl = this._router.routerState.snapshot.url;

		if (nextUrl === currentNavigationFinalUrl || nextUrl === currentRouterStateUrl)
			return;

		void this._router.navigateByUrl(nextUrl);
	}

	private _createDrawerUrlTree(
		drawerRouteSnapshot: ActivatedRouteSnapshot,
		drawerType: DrawerType,
		formId?: string,
	): UrlTree {
		const parameters = UrlHelper.mergeRouteSnapshotParamsWithSourceParams(
			drawerRouteSnapshot,
			<DrawerRouteParameters>{
				drawerType,
				...isNil(this._getEntityId()) ? {} : { id: this._getEntityId() },
				...isNil(formId) ? {} : { formId },
			},
		);

		return this._router.createUrlTree(
			[
				parameters,

				/*
				 * To take into account a possible router outlet inside the right drawer entity content
				 * since the activated route of the right drawer entity component points to the right drawer dynamic
				 * router outlet
				 */
				...drawerRouteSnapshot.children.flatMap(v => v.url),
			],
			{ relativeTo: this._route },
		);
	}

	private _whenSubmittedSaveEntity(): void {
		this.submittedValidFormValue$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => this.entity && void this.facade.save(this.entity));
	}

	private _submitOnCntrlPlusEnter(): void {
		fromEvent<KeyboardEvent>(window, 'keydown')
			.pipe(
				filter(it => it.ctrlKey && it.key === 'Enter' && this.$host.contains(<Node>it.target)),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				this.ctrlEnterKeydown.emit();

				void this.submit();
			});
	}

	private async _onRightDrawerCanCloseConfirmChangesDiscardIfAny(): Promise<void> {
		const canCloseHandlerId = this.rightDrawerComponent.registerCanCloseHandler(async () => this._canDeactivate()
			? true
			: this._confirmChangesDiscard());

		await lastValueFrom(this.destroyed$);

		this.rightDrawerComponent.unregisterCanCloseHandler(canCloseHandlerId);
	}

	private _onRightDrawerCloseMarkFormPristine(): void {
		this.rightDrawerComponent.closedStart$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.form!.markAsPristine());
	}

	private _onRightDrawerFullscreenMarkEntityFacadeAsInactive(): void {
		this.rightDrawerComponent.fullscreen$
			.pipe(takeUntilDestroyed(this))
			.subscribe(fullscreen => void this.facade.activatedDrawer(!fullscreen));
	}

	private _onDiscardChangesResetFormState(): void {
		this._discardChanges$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this.markFormAsPristineAndUntouched());
	}

	private _canDeactivate(): boolean {
		return this.isAdd
			|| !this.isEditLike
			|| this.form!.pristine;
	}

	private async _confirmChangesDiscard(): Promise<boolean> {
		const discard = !!(await lastValueFrom(
			this._dialog.open<DiscardChangesConfirmDialogComponent, null, boolean>(
				DiscardChangesConfirmDialogComponent,
			)
				.afterClosed(),
		));

		discard && this._discardChanges$.next();

		return discard;
	}

	private _onEntitySaveSuccess(callback: () => void): void {
		this._onSaveSuccessSubscription.unsubscribe();

		this._onSaveSuccessSubscription = this.facade.onSaveSuccess$
			.pipe(
				// what till the right drawer component entity gets updated and then run on save success callback for
				// the callback to have actual state
				switchMap(({ result }) => this._entity$.pipe(first(entity => entity?.id === result.id))),
				takeUntilDestroyed(this),
			)
			.subscribe(callback);
	}

	private _updateRightDrawerClass(): void {
		this.rightDrawerComponent.setClass({
			...Object.fromEntries(DrawerType
				.getList()
				.map((value: DrawerType) => [
					`drawer-type-${ kebabCase(value.name) }`,
					this.drawerType === value,
				])),
			'first-loading': !!this.firstLoading,
		});
	}

	changeToViewOnClickOutsideCards({ target }: MouseEvent): void {
		const $target = <Element | null> target;

		if (!$target?.isConnected
			|| !this.isEdit
			|| !!this._router.getCurrentNavigation()
			|| $target.closest('.card-edit-button'))
			return;

		// eslint-disable-next-line unicorn/prefer-spread
		const $cards = Array.from(this.$host.querySelectorAll('.card.can-edit, .card-edit'));

		if (isEmpty($cards) || $cards.some($card => $card.contains($target)))
			return;

		this.changeDrawerRouteParams(DrawerType.view);
	}

	@HostListener('window:beforeunload', [ '$event' ])
	confirmDeactivationOnWindowBeforeUnload(event: BeforeUnloadEvent): string | undefined {
		if (this._canDeactivate() || isDevMode())
			return;

		const message = 'You are about to loose unsaved information, continue?';

		/*
		 * Browser's prompt on the event doesn't support custom text
		 * text below just fallback for some behind the times browsers
		 */
		// eslint-disable-next-line no-param-reassign
		event.returnValue = message;

		// eslint-disable-next-line consistent-return
		return message;
	}

	private _onTabChangeResetDrawerToView(): void {
		this._onTabChangeSubscription.unsubscribe();

		this._onTabChangeSubscription = this.facade.activeTab$
			.pipe(
				filter(() => this.drawerType === DrawerType.edit),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this.changeDrawerRouteParams(DrawerType.view));
	}

}
