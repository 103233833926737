import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FADE, INSTANT_HIDE_ON_VOID, SLIDE_IN } from '@bp/shared/animations';

import { TableHostComponent } from '@bp/admins-shared/core/components';

import { IdentityFacade } from '@bp/backoffice/domains/identity';

import type { PresetKeys } from '../../models';
import { Preset } from '../../models';
import { PresetFacade, PresetsFacade } from '../../state';

@Component({
	selector: 'bp-presets-table',
	templateUrl: './presets-table.component.html',
	styleUrls: [ './presets-table.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, SLIDE_IN, INSTANT_HIDE_ON_VOID ],
})
export class PresetsTableComponent extends TableHostComponent<Preset, 'mountBtn'> implements OnInit {

	@Input() private!: boolean;

	constructor(
		public presetsFacade: PresetsFacade,
		public presetFacade: PresetFacade,
		public identityFacade: IdentityFacade,
	) {
		super();

		this.setConfig({
			metadata: Preset.getClassMetadata(),
			columnsOrder: [
				'menu',
				'mountBtn',
				'authorUid',
				'name',
				'updatedAt',
				'cashierKey',
				'cashierSessionId',
				'creditCardId',
				'paymentProvider',
				'country',
				'amount',
				'environment',
				'isShared',
			],
			customColumns: [ 'mountBtn' ],
		});
	}

	ngOnInit() {
		if (!this.private)
			this._hideIsSharedColumnIfNotPrivatePresets();
	}

	private _hideIsSharedColumnIfNotPrivatePresets() {
		this.displayedPropertiesMetadata = this.metadata.values
			.filter(v => v.table && <PresetKeys> v.property !== 'isShared');
	}
}
