<h2 mat-dialog-title>Unsaved Changes</h2>

<mat-dialog-content>
	Are you sure that you want to leave? There are unsaved changes. If you leave, your changes will be lost.
</mat-dialog-content>

<mat-dialog-actions>
	<button class="cancel mr-auto" mat-stroked-button color="primary" [mat-dialog-close]="false">Stay Here</button>

	<button mat-stroked-button color="primary" [mat-dialog-close]="true">Discard Changes</button>
</mat-dialog-actions>
