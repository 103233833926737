import { isEmpty, isString } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';

import {
	ChangeDetectionStrategy, Component, ContentChild, Input,
	Output, TemplateRef, ViewChild
} from '@angular/core';
import type {
	AbstractControl, ValidationErrors,
	ValidatorFn
} from '@angular/forms';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FADE, FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import { FormFieldControlComponent } from '@bp/shared/components/core';
import { bpQueueMicrotask, matchIgnoringCase, searchIgnoringCase } from '@bp/shared/utilities';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

import { InputComponent } from '../input';

@Component({
	selector: 'bp-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: [ './autocomplete.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(focusin)': 'onTouched()',
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: AutocompleteComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: AutocompleteComponent,
			multi: true,
		},
	],
	animations: [ FADE_IN_LIST_STAGGERED, FADE ],
})
export class AutocompleteComponent extends FormFieldControlComponent<any | null> implements OnChanges {

	@Input() items?: any[] | null;

	@Input() itemDisplayPropertyName?: string;

	@Input() suggestedItem?: any;

	@Input() suggestedItemTooltip?: string;

	@Input() suggestedItemButtonTextPrefix?: string;

	@Input() panelClass?: string;

	@Input() filterListFn?: (item: any, search: string) => boolean;

	@Input() hasSearchIcon?: boolean;

	@Input() override nativeAutocomplete?: boolean | string = false;

	@Output('inputChange') readonly inputChange$ = new Subject<string>();

	@ContentChild(TemplateRef) optionTpl?: TemplateRef<any>;

	@ViewChild(InputComponent) private readonly _input?: InputComponent;

	override throttle = 0;

	filtered$ = new BehaviorSubject<any[]>([]);

	constructor() {
		super();

		this._internalControl.valueChanges
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => this._internalControl.dirty && void this.inputChange$.next(v));
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		super.ngOnChanges(changes);

		const { items } = changes;

		if (items)
			this.filtered$.next(this.items ?? []);
	}

	focus(): void {
		this._input?.focus();
	}

	private _getDisplayValue(item: any): string {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return (this.itemDisplayPropertyName && item[this.itemDisplayPropertyName])
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			?? item?.displayName
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			?? item?.name
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			?? item.toString();
	}

	// #region Implementation of the ControlValueAccessor interface
	override writeValue(value: any): void {
		bpQueueMicrotask(() => {
			this._setIncomingValue(value);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			this._setIncomingValueToInternalControl(this.value?.toString() ?? '');
		});
	}
	// #endregion Implementation of the ControlValueAccessor interface

	// #region Implementation of the Validator interface
	protected override _validator: ValidatorFn | null = ({ value }: AbstractControl): ValidationErrors | null => !value && this._internalControl.value
		? { autocompleteNotFound: true }
		: null;
	// #endregion Implementation of the Validator interface

	/**
	 * The value of the internal control could
	 * be as string as an item of the autocomplete list which is any
	 */
	protected override _onInternalControlValueChange(searchTermOrSelectedValue: any | string | null): void {
		if (isEmpty(this.items))
			return;

		let found: any;

		if (isString(searchTermOrSelectedValue)) {
			const searchTerm = searchTermOrSelectedValue.trim();

			if (searchTerm.length > 1) {
				this.filtered$.next(
					this.items!.filter(item => this._filterItem(item, searchTerm)),
				);

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				found = this.items!.find(item => this._matchItem(item, searchTerm));
			} else
				this.filtered$.next(this.items!);

		} else {
			this.filtered$.next(this.items!);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			found = searchTermOrSelectedValue;
		}

		this._cdr.markForCheck();

		this.setValue(found);
	}

	private _matchItem(item: any, searchTerm: string): boolean {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		return matchIgnoringCase(item.toString(), searchTerm)
			|| matchIgnoringCase(this._getDisplayValue(item), searchTerm);
	}

	private _filterItem(item: any, searchTerm: string): boolean {
		return this.filterListFn
			? this.filterListFn(item, searchTerm)
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
			: searchIgnoringCase(item.toString(), searchTerm)
			|| searchIgnoringCase(this._getDisplayValue(item), searchTerm);
	}

}
