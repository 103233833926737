<bp-layout [@routeHitAnimations]="shouldAnimateOutlet" (@routeHitAnimations.done)="shouldAnimateOutlet = false">
	<bp-svg-payment-card-brands-icons-definitions></bp-svg-payment-card-brands-icons-definitions>

	<bp-svg-icons-definitions></bp-svg-icons-definitions>

	<bp-right-drawers-orchestrator></bp-right-drawers-orchestrator>

	<bp-notifications-hub></bp-notifications-hub>

	<bp-modal-outlet>
		<router-outlet name="modal"></router-outlet>
	</bp-modal-outlet>

	<bp-menu></bp-menu>

	<bp-backoffice-toolbar *ngIf="identityFacade.user$ | async">
		<div class="ng-content-toolbar-right-section">
			<bp-notifications-hub-bell-btn class="mr-3"></bp-notifications-hub-bell-btn>
		</div>
	</bp-backoffice-toolbar>

	<mat-progress-bar
		*ngIf="(presetFacade.isOpenedDrawer$ | async) === false && (presetFacade.pending$ | async)"
		@fade
		color="primary"
		mode="indeterminate"
	>
	</mat-progress-bar>

	<router-outlet (activate)="onRouterOutletActivate()"> </router-outlet>

	<bp-footer></bp-footer>
</bp-layout>
