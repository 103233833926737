<div [@fadeInListStaggered]="items?.length">
	<div
		*ngFor="let user of items; let i = index"
		[class.active]="isActive(user)"
		[style.z-index]="isActive(user) ? items!.length + 1 : items!.length - i"
		(click)="setValue(isActive(user) ? null : user.id)"
	>
		<bp-avatar [user]="user"></bp-avatar>
	</div>
</div>
