import { concatMap, distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { combineLatest, tap } from 'rxjs';
import { omit } from 'lodash-es';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { FirebaseService, RouterService, StorageService } from '@bp/shared/services';
import { filterPresent, filterTruthy, takeFirstTruthy } from '@bp/shared/rxjs';
import type { DTO } from '@bp/shared/models/metadata';

import { IdentityEffects as IdentityBaseEffects, IDENTITY_STATE_KEY } from '@bp/shared-domains-identity';

import { User, UsersApiService } from '@bp/backoffice/domains/users';

import { IdentityApiService } from '../services';
import { Identity } from '../models';

import { IdentityFacade } from './identity.facade';
import { FEATURE_STATE_KEY } from './identity.reducer';
import {
	createIdentityUserDocument,
	firebaseUserStateChanged,
	identityCreatedOrUpdatedInitially,
	syncIdentityWithRespectiveUserDocument,
	updateIdentityIfUserDocumentChanged
} from './identity.actions';

const IDENTITY_PATH_IN_STATE = `${ FEATURE_STATE_KEY }.${ IDENTITY_STATE_KEY }`;

@Injectable()
export class IdentityEffects extends IdentityBaseEffects<Identity> {
	logout$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.logout),
			concatMap(async () => this._firebaseService.auth()),
			tap(auth => void auth.signOut()),
		),
		{ dispatch: false },
	);

	setRedirectUrlOnLogout$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.logout),
		map(() => this.actions.saveUrlForRedirectionAfterLogin({ url: this._router.url })),
	));

	onIdentityCreatedOrUpdatedInitiallyEmitAction$ = createEffect(() => this._identityFacade.userIsLoggedIn$.pipe(
		filterTruthy,
		switchMap(() => this._actions$.pipe(
			ofType(createIdentityUserDocument, updateIdentityIfUserDocumentChanged),
			// eslint-disable-next-line rxjs/no-unsafe-first
			take(1),
		)),
		map(() => identityCreatedOrUpdatedInitially()),
	));

	firebaseUserStateChanged$ = createEffect(() => this._identityFacade.userIsLoggedIn$.pipe(
		filterTruthy,
		switchMap(() => combineLatest([
			this._firebaseService.onAuthStateChange(),
			this._actions$.pipe(ofType(identityCreatedOrUpdatedInitially)),
		]).pipe(takeUntil(this._identityFacade.userIsLoggedOut$.pipe(takeFirstTruthy)))),
		map(([ firebaseUser ]) => firebaseUserStateChanged({
			firebaseUser: firebaseUser && Identity.fromFirebase(firebaseUser),
		})),
	));

	onFirebaseUserStateChangeUpdateIdentity$ = createEffect(
		() => this._actions$.pipe(
			ofType(firebaseUserStateChanged),
			tap(({ firebaseUser }) => firebaseUser
				? void this._updateIdentityUserDocumentIfFirebaseUserChanged(firebaseUser)
				: this._identityFacade.user && void this._identityFacade.removeIdentity()),
		),
		{ dispatch: false },
	);

	redirectToLoginIfNoUser$ = createEffect(
		() => this._actions$.pipe(
			ofType(this.actions.removeIdentity),
			tap(() => void this._router.navigateByUrl('/login')),
		),
		{ dispatch: false },
	);

	syncIdentityWithRespectiveUserDocument$ = createEffect(() => this._actions$.pipe(
		ofType(syncIdentityWithRespectiveUserDocument),
		switchMap(({ identity }) => this._usersApiService
			.listenToUserChanges(identity.id!)
			.pipe(takeUntil(this._identityFacade.userLoggedOut$))),
		map(user => (user ? updateIdentityIfUserDocumentChanged({ user }) : createIdentityUserDocument())),
	));

	onIdentityChangeStartSyncingWithRespectiveUserDocument$ = createEffect(() => this._identityFacade.user$.pipe(
		distinctUntilChanged((a, b) => a?.id === b?.id),
		filterPresent,
		map(identity => syncIdentityWithRespectiveUserDocument({ identity })),
	));

	updateIdentityIfUserDocumentChanged$ = createEffect(
		() => this._actions$.pipe(
			ofType(updateIdentityIfUserDocumentChanged),
			tap(({ user }) => void this._updateIdentityIfUserDocumentChanged(user)),
		),
		{ dispatch: false },
	);

	createIdentityUserDocument$ = createEffect(
		() => this._actions$.pipe(
			ofType(createIdentityUserDocument),
			tap(() => void this._createIdentityUserDocument()),
		),
		{ dispatch: false },
	);

	storeUserInLocalStorageOnChange = this._identityFacade.user$.subscribe(
		user => void this._storageService.setIfDifferentFromStored(user, IDENTITY_PATH_IN_STATE),
	);

	constructor(
		protected override readonly _identityFacade: IdentityFacade,
		protected override readonly _identityApiService: IdentityApiService,
		private readonly _usersApiService: UsersApiService,
		private readonly _firebaseService: FirebaseService,
		private readonly _storageService: StorageService,
		router: Router,
		routerService: RouterService,
		dialog: MatDialog,
		actions$: Actions,
	) {
		super(_identityFacade, _identityApiService, actions$, dialog, router, routerService);
	}

	private _updateIdentityUserDocumentIfFirebaseUserChanged(firebaseUser: DTO<Identity>): void {
		const updatedIdentity = new Identity({
			...this._identityFacade.user,
			...firebaseUser,
		});

		void this._usersApiService.markVisit(updatedIdentity);

		if (JSON.stringify(updatedIdentity) === JSON.stringify(this._identityFacade.user))
			return;

		this._saveIdentityUserDocument(updatedIdentity);
	}

	private _updateIdentityIfUserDocumentChanged(user: User): void {
		const serializedUser = JSON.stringify(omit(user, 'visitedAt'));
		const serializedIdentityUser = JSON.stringify(omit(new User(this._identityFacade.user!), 'visitedAt'));

		if (serializedUser === serializedIdentityUser)
			return;

		void this._identityFacade.setIdentity(
			new Identity({
				...this._identityFacade.user!,
				...user,
			}),
		);
	}

	private _createIdentityUserDocument(): void {
		this._saveIdentityUserDocument(this._identityFacade.user!);
	}

	private _saveIdentityUserDocument(identity: Identity): void {
		void this._usersApiService.save(new User(identity)).subscribe();
	}
}
