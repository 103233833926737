import { Injectable } from '@angular/core';

import type { DTO, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { FirebaseService } from '@bp/shared/services';
import { assertIsQueryParamsBased } from '@bp/shared/models/common';

import type { CommonQueryParams } from '@bp/backoffice/cashier-demostand/models';

import { CreditCard } from '../models';

@Injectable({
	providedIn: 'root',
})
export class CreditCardsApiService implements IEntitiesFirebaseApiService<CreditCard, CommonQueryParams> {

	readonly collectionPath = 'demostand-credit-cards';

	constructor(private readonly _firebaseService: FirebaseService) { }

	readonly factory = (v: DTO<CreditCard>) => new CreditCard(v);

	listenToQueriedRecordsPageChanges(query?: CommonQueryParams) {
		assertIsQueryParamsBased(query);

		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
		);
	}

	listenToCollectionChanges() {
		return this._firebaseService.listenToCollectionChanges(this.collectionPath, this.factory);
	}

	get(id: string) {
		return this._firebaseService.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	save(model: CreditCard) {
		return this._firebaseService.save(this.collectionPath, model, this.factory);
	}

	delete(model: CreditCard) {
		return this._firebaseService.delete(`${ this.collectionPath }/${ model.id! }`);
	}

}
