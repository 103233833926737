<section *ngFor="let section of sections; let i = index">
	<bp-property-metadata-views-section
		[heading]="section.title"
		[entity]="entity"
		[metadata]="metadata"
		[hasSeparator]="i !== 0"
		[sectionScheme]="section.scheme"
	>
	</bp-property-metadata-views-section>
</section>
