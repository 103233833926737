import * as firestore from 'firebase/firestore';
import type { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import type { DTO, IEntitiesFirebaseApiService } from '@bp/shared/models/metadata';
import { FirebaseService } from '@bp/shared/services';
import type { RecordsPage } from '@bp/shared/models/common';
import { assertIsQueryParamsBased } from '@bp/shared/models/common';

import type { PresetsQueryParams } from '../models';
import { Preset } from '../models';

@Injectable({
	providedIn: 'root',
})
export class PresetsApiService implements IEntitiesFirebaseApiService<Preset, PresetsQueryParams> {

	readonly collectionPath = 'demostand-presets';

	constructor(
		private readonly _firebaseService: FirebaseService,
	) { }

	readonly factory = (dto: DTO<Preset>): Preset => new Preset(dto);

	listenToQueriedRecordsPageChanges(query?: PresetsQueryParams): Observable<RecordsPage<Preset>> {
		assertIsQueryParamsBased(query);

		return this._firebaseService.listenToQueriedRecordsPageChanges(
			this.collectionPath,
			query,
			this.factory,
			firestoreQuery => {
				if (query.country) {
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where('country', '==', query.country),
					);
				}

				if (query.isShared) {
					firestoreQuery = firestore.query(
						firestoreQuery,
						firestore.where('isShared', '==', query.isShared),
					);
				}

				return firestoreQuery;
			},
		);
	}

	get(id: string): Observable<Preset | null> {
		return this._firebaseService
			.getDocument(`${ this.collectionPath }/${ id }`, this.factory);
	}

	save(model: Preset): Observable<Preset> {
		return this._firebaseService.save(
			this.collectionPath,
			model,
			this.factory,
		);
	}

	delete(model: Preset): Observable<void> {
		return this._firebaseService.delete(`${ this.collectionPath }/${ model.id }`);
	}

	/*
	 * Async actualize() {
	 * 	const presets = await this._firebaseService.getCollection<Preset>(this.collectionPath, v => new Preset({
	 * 		...v,
	 * 		searchTerms: v.name
	 * 			?.toLowerCase()
	 * 			.split(/\s|\/|:|-/)
	 * 			.filter(it => !!it),
	 * 		isShared: !!v.jiraIssueKey
	 * 	}));
	 */

	/*
	 * 	Const batch = await this._firebaseService.batch();
	 * 	presets.forEach(async v => batch.set(
	 * 		await this._firebaseService.getDocumentRef(`${ this.collectionPath }/${ v.id }`),
	 * 		JSON.parse(JSON.stringify(v))
	 * 	));
	 */

	/*
	 * 	Batch.commit();
	 * }
	 */

}
