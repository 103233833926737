import { get, isEmpty, mapValues } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import type { OnDestroy } from '@angular/core';
import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import type { UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';

import { FormMetadataEntityBaseComponent } from '@bp/shared/components/metadata';
import type { DTO, MetadataEntity } from '@bp/shared/models/metadata';
import { uuid } from '@bp/shared/utilities';
import { takeUntilDestroyed } from '@bp/shared/models/common';
import { OnChanges, SimpleChanges } from '@bp/shared/models/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class RightDrawerEntityChildFormBaseComponent<T extends MetadataEntity>
	extends FormMetadataEntityBaseComponent<T>
	implements OnDestroy, OnChanges {

	@Input() heading?: string | null;

	private readonly _formGroupId = uuid({ short: true });

	constructor(
		private readonly _parentFormEntity: FormMetadataEntityBaseComponent<T>,
		formBuilder: UntypedFormBuilder,
		cdr: ChangeDetectorRef,
		toaster: ToastrService,
	) {
		super(formBuilder, cdr, toaster);

		this.form$
			.pipe(takeUntilDestroyed(this))
			.subscribe(v => void this._connectFormGroupToParentForm(v!));
	}

	override ngOnChanges(changes: SimpleChanges<this>): void {
		if (isEmpty(this.getFormScheme()))
			throw new Error('The form scheme is empty, please define the scheme of the child form');

		super.ngOnChanges(changes);
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();

		this._parentFormEntity.form!.removeControl(this._formGroupId);
	}

	override factory: (v?: DTO<T>) => T
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		= v => <T> mapValues(this.getFormScheme(), (value, propertyName) => get(v, propertyName));

	private _connectFormGroupToParentForm(form: UntypedFormGroup): void {
		this._parentFormEntity.form!.setControl(this._formGroupId, form);
	}

}
