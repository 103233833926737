import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE, FADE_IN, ROUTE_HIT_ANIMATIONS } from '@bp/shared/animations';

import { IdentityFacade } from '@bp/backoffice/domains/identity';
import { PresetFacade } from '@bp/backoffice/cashier-demostand/presets';

@Component({
	selector: 'bp-root',
	templateUrl: './root.component.html',
	styleUrls: [ './root.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ ROUTE_HIT_ANIMATIONS, FADE, FADE_IN ],
})
export class RootComponent {

	shouldAnimateOutlet!: boolean;

	constructor(
		public presetFacade: PresetFacade,
		public identityFacade: IdentityFacade,
	) { }

	onRouterOutletActivate(): void {
		this.shouldAnimateOutlet = true;
	}

}
