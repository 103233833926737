import LogRocket from 'logrocket';

import type { Dictionary } from '@bp/shared/typings';
import { getLocationHostDomain } from '@bp/shared/utilities';
import { BpError } from '@bp/shared/models/core';

import type { IReporter } from './reporter.interface';
import { createLogrocketMetaReducer } from './ngrx';

export class LogRocketReporter implements IReporter {

	logMetaReducer = createLogrocketMetaReducer();

	constructor(private readonly _options: {
		appId: string;
		release: string;
		onSessionURLChange: (sessionUrl: string) => void;
	}) {
		this._init();
	}

	private _init(): void {
		LogRocket.init(this._options.appId, {
			release: this._options.release,
			console: {
				shouldAggregateConsoleErrors: true,
			},
			rootHostname: getLocationHostDomain(),
			browser: {
				urlSanitizer: url => {
					let sanitizedUrl = url;

					// redact the value of the query parameter secret_key
					sanitizedUrl = sanitizedUrl.replace(/jwt=([^&]*)/u, 'jwt=**redacted**');

					sanitizedUrl = sanitizedUrl.replace(/refreshToken=([^&]*)/u, 'refreshToken=**redacted**');

					// make sure you return the sanitized URL string
					return sanitizedUrl;
				},
			},
			network: {
				responseSanitizer(response) {
					response.body = undefined;

					return response;
				},
				requestSanitizer(request) {
					request.body = undefined;

					request.headers['Authorization'] = undefined;

					return request;
				},
			},
		});

		LogRocket.getSessionURL(sessionUrl => void this._options.onSessionURLChange(sessionUrl));
	}

	 identifyUser(
		userId: string,
		userTraits?: Dictionary<boolean | number | string | null | undefined> | undefined,
	): void {
		LogRocket.identify(userId, <any> userTraits);
	}

	 captureError(error: any, source: string): void {
		LogRocket.captureException(
			new BpError(error),
			{ tags: { source } },
		);
	}

	 captureMessage(message: string): void {
		LogRocket.captureMessage(message, { tags: { source: 'code' } });
	}

	 warn(message?: any, ...optionalParams: any[]): void {
		LogRocket.warn(message, ...optionalParams);
	}

	 log(message?: any, ...optionalParams: any[]): void {
		LogRocket.log(message, ...optionalParams);
	}

	 track(key: string, value: any): void {
		LogRocket.track(key, value);
	}

}
