import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import { BpError } from '@bp/shared/models/core';

import type { User } from '@bp/backoffice/domains/users';

import { usersUpdateFailure, usersUpdateSuccess } from './app-api.actions';

export const APP_FEATURE_KEY = '[feature]:app';

export interface IState {

	users: User[] | null;

	error: BpError | null;

}

export const initialState: IState = {
	users: [],
	error: null,
};

const appReducer = createReducer(

	initialState,

	on(usersUpdateSuccess, (state, { result }): IState => ({
		...state,
		users: result,
	})),

	on(usersUpdateFailure, (state, { error }): IState => ({
		...state,
		error,
	})),

);

export function reducer(state: IState | undefined, action: Action): IState {
	return appReducer(state, action);
}
