import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE, INSTANT_HIDE_ON_VOID, SLIDE_IN } from '@bp/shared/animations';

import { TableHostComponent } from '@bp/admins-shared/core/components';

import { IdentityFacade } from '@bp/backoffice/domains/identity';
import { AppFacade } from '@bp/backoffice/app-state';

import { CreditCard } from '../../models';
import { CreditCardFacade, CreditCardsFacade } from '../../state';

@Component({
	selector: 'bp-credit-cards-table',
	templateUrl: './credit-cards-table.component.html',
	styleUrls: [ './credit-cards-table.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE, SLIDE_IN, INSTANT_HIDE_ON_VOID ],
})
export class CreditCardsTableComponent extends TableHostComponent<CreditCard> {

	constructor(
		public creditCardsFacade: CreditCardsFacade,
		public creditCardFacade: CreditCardFacade,
		public appFacade: AppFacade,
		public identityFacade: IdentityFacade,
	) {
		super();

		this.setConfig({
			metadata: CreditCard.getClassMetadata(),
			columnsOrder: [
				'menu',
				'authorUid',
				'name',
				'updatedAt',
				'paymentProvider',
				'number',
				'expireMonth',
				'cvv',
			],
		});
	}
}
