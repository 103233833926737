import * as auth from 'firebase/auth';
import type { Observable } from 'rxjs';
import { from, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { isObject } from 'lodash-es';
import type { FirebaseError } from 'firebase/app';

import { Injectable } from '@angular/core';

import { BpError, ResponseStatusCode } from '@bp/shared/models/core';
import { FirebaseService } from '@bp/shared/services';

import type { IIdentityApiService } from '@bp/shared-domains-identity';

import { UsersApiService } from '@bp/backoffice/domains/users';

import { Identity } from '../models';

@Injectable({
	providedIn: 'root',
})
export class IdentityApiService implements IIdentityApiService<Identity> {
	constructor(private readonly _firebaseService: FirebaseService, private readonly _usersApi: UsersApiService) {
		void this._init();
	}

	private async _init(): Promise<void> {
		(await this._firebaseService.auth())!.useDeviceLanguage();
	}

	login(): Observable<Identity> {
		return from(this._firebaseService.auth()).pipe(
			concatMap(async authInstance => auth.signInWithPopup(authInstance, this._getGoogleProvider())),
			concatMap(({ user: firebaseUser }) => this._usersApi.get(firebaseUser.uid).pipe(
				map(
					user => new Identity({
						...user,
						...Identity.fromFirebase(firebaseUser),
					}),
				),
			)),
			catchError((error: unknown) => this._mapFirebaseError(error)),
		);
	}

	private _mapFirebaseError(error: unknown): Observable<never> {
		if (this._isFirebaseError(error)) {
			return throwError(
				() => new BpError({
					status: ResponseStatusCode.BadRequest,
					messages: [
						{
							type: error.code,
							message: error.message,
						},
					],
				}),
			);
		}

		// eslint-disable-next-line rxjs/throw-error
		return throwError(() => error);
	}

	private _isFirebaseError(error: unknown): error is FirebaseError {
		return isObject(error) && 'code' in error;
	}

	private _getGoogleProvider(): auth.AuthProvider {
		const provider = new auth.GoogleAuthProvider();

		provider.addScope('profile');

		provider.addScope('email');

		return provider;
	}
}
