import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FADE_IN_LIST_STAGGERED } from '@bp/shared/animations';
import type { ViewsSectionScheme } from '@bp/shared/components/metadata';
import { ViewEntityBaseComponent } from '@bp/shared/components/metadata';

import { CreditCard } from '../../models';

@Component({
	selector: 'bp-credit-card-view',
	templateUrl: './credit-card-view.component.html',
	styleUrls: [ './credit-card-view.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN_LIST_STAGGERED ],
})
export class CreditCardViewComponent extends ViewEntityBaseComponent<CreditCard> {

	override metadata = CreditCard.getClassMetadata();

	sections: {
		title: string;
		scheme: ViewsSectionScheme<CreditCard>;
	}[] = [
			{
				title: 'Test Credit Card Details',
				scheme: [
					[ 'number', 'expireMonth' ],
					[ 'cvv', 'expireYear' ],
				],
			},
			{
				title: 'Service Details',
				scheme: [
					[ 'paymentProvider' ],
				],
			},
		];

}
