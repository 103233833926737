<mat-progress-bar *ngIf="pending" @slide color="primary" mode="indeterminate"> </mat-progress-bar>

<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
	<ng-container *ngIf="hasHeader">
		<ng-container *ngIf="!firstLoading && drawerType !== DrawerType.loadError">
			<div *ngIf="header; else defaultHeaderTpl" @fadeIn>
				<ng-content select="bp-right-drawer-entity-header, .right-drawer-header"> </ng-content>
			</div>

			<ng-template #defaultHeaderTpl>
				<div @fadeIn class="page-header right-drawer-header">
					<bp-right-drawer-entity-name-control
						[namePlaceholder]="titlePlaceholder"
						[name]="entitySetExternally?.name"
						(nameChange)="updateDrawerEntityAndSaveDraftToStorage({ name: $event })"
					>
					</bp-right-drawer-entity-name-control>

					<div class="right-drawer-header-content-wrapper">
						<ng-content select="bp-right-drawer-header-content, .right-drawer-header-content"></ng-content>
					</div>

					<div>
						<ng-content select="bp-right-drawer-header-buttons"></ng-content>
					</div>
				</div>
			</ng-template>
		</ng-container>

		<div
			*ngIf="firstLoading || drawerType === DrawerType.loadError"
			@fadeIn
			class="page-header right-drawer-header"
		>
			<h1>
				{{ drawerType === DrawerType.loadError ? 'An error has happened while loading' : 'Loading...' }}
			</h1>
		</div>
	</ng-container>

	<section class="right-drawer-body gray" (mousedown)="changeToViewOnClickOutsideCards($event)">
		<ng-container *bpDelayedRender="MAT_DRAWER_ANIMATION_DURATION">
			<bp-alert
				*ngIf="showGeneralErrorsAtTop || isAdd || drawerType === DrawerType.loadError"
				class="right-drawer-alert"
				type="danger"
				withBorder
				[errors]="errors"
				[closable]="drawerType !== DrawerType.loadError"
			>
			</bp-alert>

			<div *ngIf="drawerType !== DrawerType.loadError">
				<ng-content></ng-content>
			</div>
		</ng-container>
	</section>
</form>
