<bp-sidenav>
	<bp-nav-group *bpHasAccess="BackofficeFeature.cashierDemostand" label="Cashier Demostand">
		<bp-nav-item [path]="['/cashier-demostand/presets']"> Presets </bp-nav-item>

		<bp-nav-item [path]="['/cashier-demostand/presets', (identityFacade.user$ | async)?.id]">
			My Presets
		</bp-nav-item>

		<bp-nav-item [path]="['/cashier-demostand/credit-cards']"> Test Credit Cards </bp-nav-item>
	</bp-nav-group>

	<bp-nav-item *bpHasAccess="BackofficeFeature.bridgerPsps" path="/bridger-psps"> Bridger PSPs </bp-nav-item>

	<bp-nav-item *bpHasAccess="BackofficeFeature.careers" path="/careers"> Careers </bp-nav-item>

	<bp-nav-item *bpHasAccess="BackofficeFeature.newsroom" path="/newsroom"> Newsroom </bp-nav-item>

	<bp-nav-item *bpHasAccess="BackofficeFeature.users" path="/users"> Users </bp-nav-item>

	<bp-nav-item *bpHasAccess="BackofficeFeature.bridgerEvents" path="/bridger-events"> Events </bp-nav-item>
</bp-sidenav>
